import { ReactNode } from 'react'
import Head from '../../../../layout/head.tsx'
import { aptranetUIMerge } from 'aptranet-ui/lib/utils.ts'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'

interface ContentProps {
	isBeta?: boolean,
	head?: string,
	title: string,
	subtitle: string,
	actionItems?: ReactNode,
	containerClassName?: string,
	children?: ReactNode
}

const Content = (props: ContentProps) => (
	<>
		<Head title={props.head || props.title} />

		<div className={aptranetUIMerge('mt-3 p-2 w-full', props.containerClassName)}>
			<div className="flex flex-col lg:flex-row lg:justify-between mb-5 w-full items-center">
				<div>
					<div className="flex items-center">
						<h1 className="font-semibold text-2xl">{props.title}</h1>
						{props.isBeta && (<><Badge className="h-5 ms-1 mt-1" variant="primary">New Experience</Badge><Badge className="h-5 ms-1 mt-1">Beta</Badge></>)}
					</div>
					<h4 className="font-normal text-md">{props.subtitle}
					</h4>
				</div>
				<div className="mt-4 lg:me-4 lg:mt-0">
					{props.actionItems}
				</div>
			</div>
			<div className="lg:me-4">
				{props.children}
			</div>
		</div>
	</>
)

export default Content