import { DataTable } from 'aptranet-ui/components/ui/data-table.tsx'
import { type OrganizationMembershipResource } from '@clerk/types'

import { aptranetProjectMembersTableColumns } from 'aptranet-ui/components/management-console/home/project-members/project-members-table/columns.tsx'

interface ProjectMembersTableProps {
	organizationMembers: OrganizationMembershipResource[],
}

const ProjectMembersTable = (props: ProjectMembersTableProps) => {
	return <DataTable columns={aptranetProjectMembersTableColumns} enableRowSelection={false} data={props.organizationMembers} />
}

export default ProjectMembersTable