import { type Dispatch, type SetStateAction, useState } from 'react'
import { LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { Alert, AlertDescription, AlertTitle } from 'aptranet-ui/components/ui/alert.tsx'
import { ResponsiveDialog } from 'aptranet-ui/components/ui/responsive-dialog.tsx'

import { type APIKey } from '../../../../../../api/types/central.ts'
import { InfoIcon } from 'lucide-react'

import { deleteAPIKey } from '../../../../../../api/central/developers.tsx'
import { toast } from 'sonner'

interface DeleteAPIKeyModalProps {
	open: boolean,
	setOpen: Dispatch<SetStateAction<boolean>>,
	apiKey: APIKey
}

const DeleteAPIKeyModal = (props: DeleteAPIKeyModalProps) => {
	const [deleteAPIKeyLoading, setDeleteAPIKeyLoading] = useState<boolean>(false)

	const doDeleteAPIKey = () => {
		setDeleteAPIKeyLoading(true)
		deleteAPIKey(props.apiKey.id).then(() => {
			setDeleteAPIKeyLoading(false)
			toast.success("API Key was deleted successfully.")
			props.setOpen(false)
		}).catch(() => setDeleteAPIKeyLoading(false))
	}

	return <ResponsiveDialog title="Delete API Key" open={props.open} setOpen={props.setOpen}
													 closeButtonText="Cancel"
													 closeButtonDisabled={deleteAPIKeyLoading}
													 footerChildren={<LoadingButton variant="destructive" text="Delete API Key"
																													loading={deleteAPIKeyLoading}
																													onClick={() => doDeleteAPIKey()} />}>
		<p>Are you sure you would like to delete <span className="font-semibold">{props.apiKey.name}</span>?</p>
		<Alert>
			<InfoIcon className="h-4 w-4" />
			<AlertTitle>Heads up!</AlertTitle>
			<AlertDescription>
				This will invalidate the API Key and can cause systems to get Forbidden errors.
			</AlertDescription>
		</Alert>
	</ResponsiveDialog>
}

export default DeleteAPIKeyModal