import { type ColumnDef } from '@tanstack/react-table'
import { type OrganizationMembershipResource } from '@clerk/types'

import { Avatar, AvatarFallback, AvatarImage } from 'aptranet-ui/components/ui/avatar.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'
import { getRouteList } from '../../../../../../routes/routes.tsx'
import { useNavigate } from 'react-router-dom'

export const aptranetProjectMembersTableColumns: ColumnDef<OrganizationMembershipResource>[] = [
	{
		accessorKey: 'user',
		header: 'User',
		cell: ({ row }) => (
			<div className="flex items-center">
				<Avatar>
					<AvatarImage src={row.original.publicUserData.imageUrl} />
					<AvatarFallback>{row.original.publicUserData.firstName!.substring(0, 1).toUpperCase() + row.original.publicUserData.lastName!.substring(0, 1).toUpperCase()}</AvatarFallback>
				</Avatar>

				<span
					className="mx-2">{row.original.publicUserData.firstName + ' ' + row.original.publicUserData.lastName}
				</span>
			</div>
		),
	},
	{
		accessorKey: 'organization_role',
		header: 'Organization Role',
		cell: ({ row }) => ({
			'org:admin': <Badge>Admin</Badge>,
			'org:member': <Badge variant="secondary">Member</Badge>,
			'org:billing_manager': <Badge variant="secondary">Billing Manager</Badge>,
			'org:member_plus_billing_manager': <Badge variant="secondary">Member + Billing Manager</Badge>,
		}[row.original.role]),
	},
	{
		accessorKey: 'joined_at',
		header: 'Joined At',
		cell: ({ row }) => ((new Date(row.original.createdAt).toLocaleDateString('en-GB', {
			day: '2-digit',
			month: 'short',
			year: 'numeric',
		}))),
	},
	{
		id: 'actions',
		enableHiding: false,
		cell: ({ row }) => {
			const navigate = useNavigate()
			return <Button variant="outline" size="sm" disabled={row.original.role === "org:admin"}
										 onClick={() => navigate(getRouteList().home.members_edit_member_permissions.return_dynamic_path(row.original.publicUserData.userId!),
											 {
												 state: {
													 member_name: row.original.publicUserData.firstName + ' ' + row.original.publicUserData.lastName,
													 member_image_url: row.original.publicUserData.imageUrl,
													 member_role: row.original.role,
												 },
											 })}>Edit Project Permissions</Button>
		},
	},
]
