import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { z } from 'zod'

import { type Dispatch, type ReactNode, type SetStateAction, forwardRef, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { ActionButton, Button } from 'aptranet-ui/components/ui/button.tsx'
import { Card, CardContent, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { Checkbox } from 'aptranet-ui/components/ui/checkbox.tsx'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'aptranet-ui/components/ui/form.tsx'
import { Input } from 'aptranet-ui/components/ui/input.tsx'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'aptranet-ui/components/ui/select.tsx'
import { Separator } from 'aptranet-ui/components/ui/separator.tsx'
import { Switch } from 'aptranet-ui/components/ui/switch.tsx'
import { ToggleGroup, ToggleGroupItemCard } from 'aptranet-ui/components/ui/toggle-group.tsx'
import { validateNetworkAddress } from 'aptranet-ui/lib/utils.ts'

import { Link2Icon, PlusIcon, Trash2Icon } from 'lucide-react'

import { createOriginGroup, updateOriginGroup } from '../../../api/cdn/origin-groups.tsx'
import { type CDNOriginGroupOrigin, type OriginGroup } from '../../../api/types/cdn.tsx'
import { getRouteList } from '../../../routes/routes.tsx'

interface createOriginGroupProps {
	originGroup?: OriginGroup
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setCreateOriginGroupLoading: Dispatch<SetStateAction<boolean>>
}

const formSchema = z.object({
	name: z.string().min(1).max(255),
	type: z.enum(['external', 'storage', 'intelliedge', 'aws_s3', 'google_cloud_storage', 'digitalocean_spaces', 's3_compatible']),
	use_next_upstream: z.boolean().default(false),
	next_upstream_cases: z.object({
		on_error: z.boolean().optional(),
		on_timeout: z.boolean().optional(),
		on_invalid_header: z.boolean().optional(),
		on_http_403: z.boolean().optional(),
		on_http_404: z.boolean().optional(),
		on_http_429: z.boolean().optional(),
		on_http_500: z.boolean().optional(),
		on_http_502: z.boolean().optional(),
		on_http_503: z.boolean().optional(),
		on_http_504: z.boolean().optional(),
	}),
	origins: z.array(
		z.object({
			source: z.string().default(''),
			enabled: z.boolean().default(true),
			backup_only: z.boolean().default(false),

			s3_region: z.string(),
			s3_bucket_name: z.string(),
			s3_access_key: z.string(),
			s3_access_secret: z.string(),
		})
	),
})

const OriginGroupForm = forwardRef<HTMLFormElement, createOriginGroupProps>((props, ref): ReactNode => {
	const theme = useTheme()
	const navigate = useNavigate()

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: props.originGroup
			? {
					name: props.originGroup.name,
					type: props.originGroup.aws_v4_auth
						? props.originGroup.origins[0].source.endsWith('.amazonaws.com')
							? 'aws_s3'
							: props.originGroup.origins[0].source.endsWith('.storage.googleapis.com')
								? 'google_cloud_storage'
								: props.originGroup.origins[0].source.endsWith('.digitaloceanspaces.com')
									? 'digitalocean_spaces'
									: 's3_compatible'
						: 'external',
					use_next_upstream: props.originGroup.use_next_upstream,
					next_upstream_cases: {
						on_error: props.originGroup.next_upstream_cases.on_error,
						on_timeout: props.originGroup.next_upstream_cases.on_timeout,
						on_invalid_header: props.originGroup.next_upstream_cases.on_invalid_header,
						on_http_403: props.originGroup.next_upstream_cases.on_http_403,
						on_http_404: props.originGroup.next_upstream_cases.on_http_404,
						on_http_429: props.originGroup.next_upstream_cases.on_http_429,
						on_http_500: props.originGroup.next_upstream_cases.on_http_500,
						on_http_502: props.originGroup.next_upstream_cases.on_http_502,
						on_http_503: props.originGroup.next_upstream_cases.on_http_503,
						on_http_504: props.originGroup.next_upstream_cases.on_http_504,
					},
					origins: props.originGroup.aws_v4_auth
						? [
								{
									enabled: true,
									backup_only: false,
									source: props.originGroup.origins[0].source,
									s3_bucket_name: props.originGroup.aws_v4_auth.bucket_name,
									s3_region: props.originGroup.aws_v4_auth.region,
									// s3_access_key: '******************************',
									// s3_access_secret: '******************************',
								},
							]
						: props.originGroup.origins.map(
								(
									origin: CDNOriginGroupOrigin & {
										s3_region?: string
										s3_bucket_name?: string
										s3_access_key?: string
										s3_access_secret?: string
									}
								) => {
									origin.s3_region = ''
									origin.s3_bucket_name = ''
									origin.s3_access_key = ''
									origin.s3_access_secret = ''
									return origin
								}
							),
				}
			: {
					name: '',
					type: 'external',
					use_next_upstream: false,
					next_upstream_cases: {
						on_error: false,
						on_timeout: false,
						on_invalid_header: false,
						on_http_403: false,
						on_http_404: false,
						on_http_429: false,
						on_http_500: false,
						on_http_502: false,
						on_http_503: false,
						on_http_504: false,
					},
				},
	})

	useEffect(() => props.setUnsavedChangesShown(Object.keys(form.formState.dirtyFields).length > 0), [form.formState.dirtyFields])

	const selectedOriginGroupType = form.watch('type', 'external')
	const useNextUpstream = form.watch('use_next_upstream', false)

	const { fields, append, remove } = useFieldArray({
		name: 'origins',
		control: form.control,
	})

	useEffect(() => {
		if (form.formState.isDirty) {
			form.resetField('use_next_upstream')
			form.resetField('next_upstream_cases')

			for (let i = 0; i < fields.length; i++) {
				form.resetField(`origins.${i}.source`)
				form.resetField(`origins.${i}.enabled`)
				form.resetField(`origins.${i}.backup_only`)

				form.resetField(`origins.${i}.s3_region`)
				form.resetField(`origins.${i}.s3_bucket_name`)
				form.resetField(`origins.${i}.s3_access_key`)
				form.resetField(`origins.${i}.s3_access_secret`)

				remove(i)
			}
		}
	}, [selectedOriginGroupType])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		let networkAddressValid = true

		if (values.type === 'external' || values.type === 's3_compatible') {
			for (let i = 0; i < values.origins.length; i++) {
				const source = values.origins[i].source

				if (!source || !validateNetworkAddress(source, true)) {
					form.setError(`origins.${i}.source`, {
						message: 'Invalid origin source.',
					})

					networkAddressValid = false
				}
			}
		}

		if (!networkAddressValid) {
			return false
		}

		props.setCreateOriginGroupLoading(true)

		if (props.originGroup) {
			updateOriginGroup(props.originGroup.id, {
				name: values.name,
				use_next_upstream: values.use_next_upstream,
				next_upstream_cases: {
					on_error: values.next_upstream_cases.on_error!,
					on_timeout: values.next_upstream_cases.on_timeout!,
					on_invalid_header: values.next_upstream_cases.on_invalid_header!,
					on_http_403: values.next_upstream_cases.on_http_403!,
					on_http_404: values.next_upstream_cases.on_http_404!,
					on_http_429: values.next_upstream_cases.on_http_429!,
					on_http_500: values.next_upstream_cases.on_http_500!,
					on_http_502: values.next_upstream_cases.on_http_502!,
					on_http_503: values.next_upstream_cases.on_http_503!,
					on_http_504: values.next_upstream_cases.on_http_504!,
				},
				origins: values.origins.map((origin) => ({
					enabled: origin.enabled,
					source:
						/* @ts-ignore */
						{
							aws_s3: `${values.origins[0].s3_bucket_name}.${values.origins[0].s3_region}.amazonaws.com`,
							google_cloud_storage: `${values.origins[0].s3_bucket_name}.storage.googleapis.com`,
							digitalocean_spaces: `${values.origins[0].s3_bucket_name}.${values.origins[0].s3_region.toLowerCase()}.digitaloceanspaces.com`,
						}[values.type] || origin.source,
					backup_only: origin.backup_only,
				})),
				aws_v4_auth:
					values.type !== 'external'
						? {
								bucket_name: values.origins[0].s3_bucket_name,
								region: values.origins[0].s3_region,
								access_key_id: values.origins[0].s3_access_key,
								secret_access_key: values.origins[0].s3_access_secret,
							}
						: undefined,
			})
				.then(() => {
					props.setCreateOriginGroupLoading(false)
					toast.success('Origin Group Updated.')
					navigate(getRouteList().cdn.origin_groups.path)
				})
				.catch(() => props.setCreateOriginGroupLoading(false))
		} else {
			createOriginGroup({
				name: values.name,
				use_next_upstream: values.use_next_upstream,
				next_upstream_cases: {
					on_error: values.next_upstream_cases.on_error!,
					on_timeout: values.next_upstream_cases.on_timeout!,
					on_invalid_header: values.next_upstream_cases.on_invalid_header!,
					on_http_403: values.next_upstream_cases.on_http_403!,
					on_http_404: values.next_upstream_cases.on_http_404!,
					on_http_429: values.next_upstream_cases.on_http_429!,
					on_http_500: values.next_upstream_cases.on_http_500!,
					on_http_502: values.next_upstream_cases.on_http_502!,
					on_http_503: values.next_upstream_cases.on_http_503!,
					on_http_504: values.next_upstream_cases.on_http_504!,
				},
				origins: values.origins.map((origin) => ({
					enabled: origin.enabled,
					source:
						/* @ts-ignore */
						{
							aws_s3: `${values.origins[0].s3_bucket_name}.${values.origins[0].s3_region}.amazonaws.com`,
							google_cloud_storage: `${values.origins[0].s3_bucket_name}.storage.googleapis.com`,
							digitalocean_spaces: `${values.origins[0].s3_bucket_name}.${values.origins[0].s3_region.toLowerCase()}.digitaloceanspaces.com`,
						}[values.type] || origin.source,
					backup_only: origin.backup_only,
				})),
				aws_v4_auth:
					values.type !== 'external'
						? {
								bucket_name: values.origins[0].s3_bucket_name,
								region: values.origins[0].s3_region,
								access_key_id: values.origins[0].s3_access_key,
								secret_access_key: values.origins[0].s3_access_secret,
							}
						: undefined,
			})
				.then(() => {
					props.setCreateOriginGroupLoading(false)
					toast.success('Origin Group Created.')
					navigate(getRouteList().cdn.origin_groups.path)
				})
				.catch(() => props.setCreateOriginGroupLoading(false))
		}
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Origin Group Name</FormLabel>
							<FormControl>
								<Input placeholder="My Awesome Origin Group" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="type"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Origin Group Type</FormLabel>
							<FormControl>
								<ToggleGroup
									type="single"
									onValueChange={(e) => {
										if (fields.length === 0) {
											append({
												enabled: true,
												source: '',
												backup_only: false,
												s3_bucket_name: '',
												s3_access_key: '',
												s3_access_secret: '',
												s3_region: '',
											})
										}

										if (e) {
											field.onChange(e)
										}
									}}
									defaultValue={field.value}
									value={selectedOriginGroupType}
									className="flex justify-start"
								>
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard value="external" title="Hostname / IP" icon={Link2Icon} />
										</FormControl>
									</FormItem>
									{/*<FormItem>*/}
									{/*	<FormControl>*/}
									{/*		<ToggleGroupItemCard value="storage" title="Aptranet Storage" icon={HardDriveIcon} />*/}
									{/*	</FormControl>*/}
									{/*</FormItem>*/}
									{/*<FormItem>*/}
									{/*	<FormControl>*/}
									{/*		<ToggleGroupItemCard value="intelliedge" title="Aptranet IntelliEdge Worker" icon={ZapIcon} />*/}
									{/*	</FormControl>*/}
									{/*</FormItem>*/}
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard
												value="aws_s3"
												title="AWS S3"
												customIcon={
													'https://assets.aptranetedge.com/management-console/images/icons/' + (theme.actualTheme === 'light' ? 'aws.svg' : 'aws-white.svg')
												}
											/>
										</FormControl>
									</FormItem>
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard
												value="google_cloud_storage"
												title="Google Cloud Storage"
												customIcon="https://assets.aptranetedge.com/management-console/images/icons/google-cloud.svg"
											/>
										</FormControl>
									</FormItem>
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard
												value="digitalocean_spaces"
												title="DigitalOcean Spaces"
												customIcon="https://assets.aptranetedge.com/management-console/images/icons/digitalocean.svg"
											/>
										</FormControl>
									</FormItem>
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard
												value="s3_compatible"
												title="Other S3 Compatible"
												customIcon="https://assets.aptranetedge.com/management-console/images/icons/s3.svg"
											/>
										</FormControl>
									</FormItem>
								</ToggleGroup>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				{selectedOriginGroupType === 'external' && (
					<FormField
						control={form.control}
						name="use_next_upstream"
						render={({ field }) => (
							<FormControl>
								<FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
									<div className="space-y-0.5">
										<FormLabel className="text-base">Use Next Upstream</FormLabel>
										<FormDescription>Fallback to the next origin on error.</FormDescription>
									</div>
									<FormControl>
										<Switch checked={field.value} onCheckedChange={field.onChange} />
									</FormControl>
								</FormItem>
							</FormControl>
						)}
					/>
				)}
				{useNextUpstream && (
					<div className="grid xl:grid-cols-5 gap-2">
						<FormField
							control={form.control}
							name="next_upstream_cases.on_error"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On Error</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_timeout"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On Timeout</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_invalid_header"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On Invalid Header</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_http_403"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On HTTP 403</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_http_404"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On HTTP 404</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_http_429"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On HTTP 429</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_http_500"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On HTTP 500</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_http_502"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On HTTP 502</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_http_503"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On HTTP 503</FormLabel>
									</div>
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="next_upstream_cases.on_http_504"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={(e) => field.onChange({ target: { value: e } })} />
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>On HTTP 504</FormLabel>
									</div>
								</FormItem>
							)}
						/>
					</div>
				)}
				{selectedOriginGroupType === 'external' ? (
					fields.map((field, index) => (
						<Card key={field.id}>
							<CardHeader className="pb-4">
								<CardTitle className="text-xl flex items-center">
									Origin {index + 1}
									<Separator orientation="vertical" className="mx-3 w-0.5 h-7" />
									<FormControl>
										<FormField
											control={form.control}
											name={`origins.${index}.enabled`}
											render={({ field }) => (
												<FormItem className="flex items-center space-x-2 space-y-0">
													<FormControl>
														<Switch checked={field.value} onCheckedChange={field.onChange} />
													</FormControl>
													<FormLabel className="text-base">Enabled</FormLabel>
												</FormItem>
											)}
										/>
									</FormControl>
									<Separator orientation="vertical" className="mx-3 w-0.5 h-7" />
									<FormControl>
										<FormField
											control={form.control}
											name={`origins.${index}.backup_only`}
											render={({ field }) => (
												<FormItem className="flex items-center space-x-2 space-y-0">
													<FormControl>
														<Switch checked={field.value} onCheckedChange={field.onChange} />
													</FormControl>
													<FormLabel className="text-base">Use as backup</FormLabel>
												</FormItem>
											)}
										/>
									</FormControl>
								</CardTitle>
							</CardHeader>
							<CardContent>
								<FormField
									control={form.control}
									name={`origins.${index}.source`}
									render={({ field }) => (
										<FormControl>
											<FormItem>
												<FormLabel>Source</FormLabel>
												<div className="flex space-x-3">
													<div className="w-full space-y-2">
														<FormControl>
															<Input placeholder={(index !== 0 ? 'origin' + (index + 1) : 'origin') + '.example.com'} {...field} />
														</FormControl>
														<FormDescription>IP address or URL without "http://" or "https://".</FormDescription>
													</div>
													<Button variant="ghost" size="icon" onClick={() => remove(index)}>
														<Trash2Icon />
													</Button>
												</div>
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
							</CardContent>
						</Card>
					))
				) : selectedOriginGroupType === 's3_compatible' ? (
					<Card>
						<CardHeader className="pb-4">
							<CardTitle className="text-xl flex items-center">S3-Compatible Object Storage Bucket</CardTitle>
						</CardHeader>
						<CardContent>
							<FormField
								control={form.control}
								name="origins.0.source"
								render={({ field }) => (
									<FormControl>
										<FormItem className="w-full">
											<FormLabel>Source</FormLabel> <Input placeholder="s3_compatible_origin.example.com" {...field} />
											<FormMessage />
										</FormItem>
									</FormControl>
								)}
							/>
							<div className="flex justify-between space-x-4 mt-4">
								<FormField
									control={form.control}
									name="origins.0.s3_region"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>Bucket Region (Optional)</FormLabel> <Input placeholder="Bucket Region" {...field} />
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
								<FormField
									control={form.control}
									name="origins.0.s3_bucket_name"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>Bucket Name</FormLabel>
												<FormControl>
													<Input placeholder="example-bucket" {...field} />
												</FormControl>
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
							</div>
							<div className="flex justify-between space-x-4 mt-4">
								<FormField
									control={form.control}
									name="origins.0.s3_access_key"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>Access Key</FormLabel> <Input placeholder="Access Key for S3" {...field} />
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
								<FormField
									control={form.control}
									name="origins.0.s3_access_secret"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>Secret Access Key</FormLabel>
												<div className="flex">
													<FormControl>
														<Input placeholder="Secret Access Key for S3" {...field} />
													</FormControl>
												</div>
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
							</div>
						</CardContent>
					</Card>
				) : (
					<Card>
						<CardHeader className="pb-4">
							<CardTitle className="text-xl flex items-center">
								{
									/* @ts-ignore */
									{
										aws_s3: 'AWS S3',
										google_cloud_storage: 'Google Cloud Storage',
										digitalocean_spaces: 'DigitalOcean Spaces',
										backblaze_b2: 'Backblaze B2',
									}[selectedOriginGroupType]
								}{' '}
								Bucket
							</CardTitle>
						</CardHeader>
						<CardContent>
							<div className="flex justify-between space-x-4">
								<FormField
									control={form.control}
									name="origins.0.s3_region"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>
													{
														/* @ts-ignore */
														{
															aws_s3: 'AWS',
															google_cloud_storage: 'Google Cloud',
															digitalocean_spaces: 'DigitalOcean',
															backblaze_b2: 'Backblaze',
														}[selectedOriginGroupType]
													}{' '}
													Region
												</FormLabel>{' '}
												<Select onValueChange={field.onChange} defaultValue={field.value}>
													<FormControl>
														<SelectTrigger>
															<SelectValue placeholder="Select Region" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														{
															/* @ts-ignore */
															{
																aws_s3: [
																	{
																		id: 'us-east-1',
																		name: 'US East (N. Virginia)',
																	},
																	{
																		id: 'us-east-2',
																		name: 'US East (Ohio)',
																	},
																	{
																		id: 'us-west-1',
																		name: 'US West (N. California)',
																	},
																	{
																		id: 'us-west-2',
																		name: 'US West (Oregon)',
																	},
																	{
																		id: 'af-south-1',
																		name: 'Africa (Cape Town)',
																	},
																	{
																		id: 'ap-east-1',
																		name: 'Asia Pacific (Hong Kong)',
																	},
																	{
																		id: 'ap-south-2',
																		name: 'Asia Pacific (Hyderabad)',
																	},
																	{
																		id: 'ap-southeast-3',
																		name: 'Asia Pacific (Jakarta)',
																	},
																	{
																		id: 'ap-southeast-4',
																		name: 'Asia Pacific (Melbourne)',
																	},
																	{
																		id: 'ap-south-1',
																		name: 'Asia Pacific (Mumbai)',
																	},
																	{
																		id: 'ap-northeast-3',
																		name: 'Asia Pacific (Osaka)',
																	},
																	{
																		id: 'ap-northeast-2',
																		name: 'Asia Pacific (Seoul)',
																	},
																	{
																		id: 'ap-southeast-1',
																		name: 'Asia Pacific (Singapore)',
																	},
																	{
																		id: 'ap-southeast-2',
																		name: 'Asia Pacific (Sydney)',
																	},
																	{
																		id: 'ap-northeast-1',
																		name: 'Asia Pacific (Tokyo)',
																	},
																	{
																		id: 'ca-central-1',
																		name: 'Canada (Central)',
																	},
																	{
																		id: 'ca-west-1',
																		name: 'Canada West (Calgary)',
																	},
																	{
																		id: 'eu-central-1',
																		name: 'Europe (Frankfurt)',
																	},
																	{
																		id: 'eu-west-1',
																		name: 'Europe (Ireland)',
																	},
																	{
																		id: 'eu-west-2',
																		name: 'Europe (London)',
																	},
																	{
																		id: 'eu-south-1',
																		name: 'Europe (Milan)',
																	},
																	{
																		id: 'eu-west-3',
																		name: 'Europe (Paris)',
																	},
																	{
																		id: 'eu-south-2',
																		name: 'Europe (Spain)',
																	},
																	{
																		id: 'eu-north-1',
																		name: 'Europe (Stockholm)',
																	},
																	{
																		id: 'eu-central-2',
																		name: 'Europe (Zurich)',
																	},
																	{
																		id: 'il-central-1',
																		name: 'Israel (Tel Aviv)',
																	},
																	{
																		id: 'me-south-1',
																		name: 'Middle East (Bahrain)',
																	},
																	{
																		id: 'me-central-1',
																		name: 'Middle East (UAE)',
																	},
																	{
																		id: 'sa-east-1',
																		name: 'South America (São Paulo)',
																	},
																	{
																		id: 'us-gov-east-1',
																		name: 'AWS GovCloud (US-East)',
																	},
																	{
																		id: 'us-gov-west-1',
																		name: 'AWS GovCloud (US-West)',
																	},
																],
																google_cloud_storage: [
																	{
																		id: 'us',
																		name: 'Multi-region US',
																	},
																	{
																		id: 'eu',
																		name: 'Multi-region Europe',
																	},
																	{
																		id: 'asia',
																		name: 'Multi-region Asia',
																	},
																	{
																		id: 'northamerica-northeast1',
																		name: 'North America (Montréal)',
																	},
																	{
																		id: 'northamerica-northeast2',
																		name: 'North America (Toronto)',
																	},
																	{
																		id: 'us-central1',
																		name: 'North America (Iowa)',
																	},
																	{
																		id: 'us-east1',
																		name: 'North America (South Carolina)',
																	},
																	{
																		id: 'us-east4',
																		name: 'North America (Northern Virginia)',
																	},
																	{
																		id: 'us-east5',
																		name: 'North America (Columbus)',
																	},
																	{
																		id: 'us-south1',
																		name: 'North America (Dallas)',
																	},
																	{
																		id: 'us-west1',
																		name: 'North America (Oregon)',
																	},
																	{
																		id: 'us-west2',
																		name: 'North America (Los Angeles)',
																	},
																	{
																		id: 'us-west3',
																		name: 'North America (Salt Lake City)',
																	},
																	{
																		id: 'us-west4',
																		name: 'North America (Las Vegas)',
																	},
																	{
																		id: 'southamerica-east1',
																		name: 'South America (São Paulo)',
																	},
																	{
																		id: 'southamerica-west1',
																		name: 'South America (Santiago)',
																	},
																	{
																		id: 'europe-central2',
																		name: 'Europe (Warsaw)',
																	},
																	{
																		id: 'europe-north1',
																		name: 'Europe (Finland)',
																	},
																	{
																		id: 'europe-southwest1',
																		name: 'Europe (Madrid)',
																	},
																	{
																		id: 'europe-west1',
																		name: 'Europe (Belgium)',
																	},
																	{
																		id: 'europe-west2',
																		name: 'Europe (London)',
																	},
																	{
																		id: 'europe-west3',
																		name: 'Europe (Frankfurt)',
																	},
																	{
																		id: 'europe-west4',
																		name: 'Europe (Netherlands)',
																	},
																	{
																		id: 'europe-west6',
																		name: 'Europe (Zürich)',
																	},
																	{
																		id: 'europe-west8',
																		name: 'Europe (Milan)',
																	},
																	{
																		id: 'europe-west9',
																		name: 'Europe (Paris)',
																	},
																	{
																		id: 'europe-west10',
																		name: 'Europe (Berlin)',
																	},
																	{
																		id: 'europe-west12',
																		name: 'Europe (Turin)',
																	},
																	{
																		id: 'asia-east1',
																		name: 'Asia (Taiwan)',
																	},
																	{
																		id: 'asia-east2',
																		name: 'Asia (Hong Kong)',
																	},
																	{
																		id: 'asia-northeast1',
																		name: 'Asia (Tokyo)',
																	},
																	{
																		id: 'asia-northeast2',
																		name: 'Asia (Osaka)',
																	},
																	{
																		id: 'asia-northeast3',
																		name: 'Asia (Seoul)',
																	},
																	{
																		id: 'asia-southeast1',
																		name: 'Asia (Singapore)',
																	},
																	{
																		id: 'asia-south1',
																		name: 'India (Mumbai)',
																	},
																	{
																		id: 'asia-south2',
																		name: 'India (Delhi)',
																	},
																	{
																		id: 'asia-southeast2',
																		name: 'Indonesia (Jakarta)',
																	},
																	{
																		id: 'me-central1',
																		name: 'Middle East (Doha)',
																	},
																	{
																		id: 'me-central2',
																		name: 'Middle East (Dammam, Saudi Arabia)',
																	},
																	{
																		id: 'me-west1',
																		name: 'Middle East (Tel Aviv)',
																	},
																	{
																		id: 'australia-southeast1',
																		name: 'Australia (Sydney)',
																	},
																	{
																		id: 'australia-southeast2',
																		name: 'Australia (Melbourne)',
																	},
																	{
																		id: 'africa-south1',
																		name: 'Africa (Johannesburg)',
																	},
																],
																digitalocean_spaces: [
																	{
																		id: 'NYC3',
																		name: 'New York (Datacenter 3)',
																	},
																	{
																		id: 'SFO2',
																		name: 'San Francisco (Datacenter 2)',
																	},
																	{
																		id: 'SFO3',
																		name: 'San Francisco (Datacenter 3)',
																	},
																	{
																		id: 'AMS3',
																		name: 'Amsterdam (Datacenter 3)',
																	},
																	{
																		id: 'FRA1',
																		name: 'Frankfurt (Datacenter 1)',
																	},
																	{
																		id: 'SGP1',
																		name: 'Singapore (Datacenter 1)',
																	},
																	{
																		id: 'BLR1',
																		name: 'Bangalore (Datacenter 1)',
																	},
																	{
																		id: 'SYD1',
																		name: 'Sydney (Datacenter 1)',
																	},
																],
															}[selectedOriginGroupType].map((region) => (
																<SelectItem value={region.id}>{region.name}</SelectItem>
															))
														}
													</SelectContent>
												</Select>
												<FormDescription>
													The{' '}
													{
														/* @ts-ignore */
														{
															aws_s3: 'AWS',
															google_cloud_storage: 'Google Cloud',
															digitalocean_spaces: 'DigitalOcean',
															backblaze_b2: 'Backblaze',
														}[selectedOriginGroupType]
													}{' '}
													Region the bucket is created in.
												</FormDescription>
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
								<FormField
									control={form.control}
									name="origins.0.s3_bucket_name"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>Bucket Name</FormLabel>
												<div className="flex space-x-3">
													<FormControl>
														<Input placeholder="example-bucket" {...field} />
													</FormControl>
												</div>
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
							</div>
							<div className="flex justify-between space-x-4 mt-4">
								<FormField
									control={form.control}
									name="origins.0.s3_access_key"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>
													{
														/* @ts-ignore */
														{
															aws_s3: 'AWS IAM Access Key',
															google_cloud_storage: 'Google Cloud HMAC Access Key',
															digitalocean_spaces: 'DigitalOcean Spaces Access Key',
															backblaze_b2: 'Backblaze B2 Access Key',
														}[selectedOriginGroupType]
													}
												</FormLabel>{' '}
												<Input placeholder="Access Key" {...field} />
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
								<FormField
									control={form.control}
									name="origins.0.s3_access_secret"
									render={({ field }) => (
										<FormControl>
											<FormItem className="w-full">
												<FormLabel>
													{
														/* @ts-ignore */
														{
															aws_s3: 'AWS IAM Secret Access Key',
															google_cloud_storage: 'Google Cloud HMAC Secret Access Key',
															digitalocean_spaces: 'DigitalOcean Spaces Secret Key',
															backblaze_b2: 'Backblaze B2 Secret Access Key',
														}[selectedOriginGroupType]
													}
												</FormLabel>
												<div className="flex">
													<FormControl>
														<Input placeholder="Secret Access Key" {...field} />
													</FormControl>
												</div>
												<FormMessage />
											</FormItem>
										</FormControl>
									)}
								/>
							</div>
						</CardContent>
					</Card>
				)}

				{selectedOriginGroupType === 'external' && (
					<ActionButton
						icon={PlusIcon}
						text="Add Origin"
						onClick={() =>
							append({
								source: '',
								enabled: true,
								backup_only: false,
								s3_bucket_name: '',
								s3_access_key: '',
								s3_access_secret: '',
								s3_region: '',
							})
						}
					/>
				)}
			</form>
		</Form>
	)
})

export default OriginGroupForm
