import './global.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'

import { HelmetProvider } from 'react-helmet-async'
import { AptranetThemeProvider } from 'aptranet-ui/components/theme/theme-provider.tsx'

import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://1279353002355f5f0a5622435a747283@o4508425476964352.ingest.de.sentry.io/4508425478864976",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
		Sentry.feedbackIntegration({
			colorScheme: "system",
			showBranding: false,
			isNameRequired: true,
			isEmailRequired: true,
			autoInject: false,
		}),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/api\.aptranet\.com/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<HelmetProvider>
			<AptranetThemeProvider>
				<App />
			</AptranetThemeProvider>
		</HelmetProvider>
	</React.StrictMode>,
)
