import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Card, CardContent } from 'aptranet-ui/components/ui/card.tsx'

import { ArrowLeftIcon } from 'lucide-react'

import cdnRoutes from '../../routes/cdn.tsx'
import { type NavItem, billing, cdn, cdnDistribution, home } from './data/nav.tsx'

const NavBar = () => {
	const path = useLocation()
	const params = useParams()
	const [data, setData] = useState<NavItem[]>(home)

	if (path.pathname.endsWith('/service-not-active')) {
		return <></>
	}

	const cdnDistributionRegex = /\/cdn\/distributions\/[0-9]+\/[A-Za-z]+/i

	useEffect(() => {
		switch (path.pathname.split('/')[1]) {
			case 'cdn':
				if (cdnDistributionRegex.test(path.pathname)) {
					setData(cdnDistribution(Number(params.distributionID)))
				} else {
					setData(cdn)
				}
				break
			case 'billing':
				setData(billing)
				break
			default:
				setData(home)
				break
		}
	}, [path.pathname])

	const GoBack = (props: { name: string; path: string }) => (
		<Card>
			<CardContent className="p-0 flex justify-center w-full">
				<Button variant="ghost" size="sm" className="w-full ps-2 py-5" asChild>
					<Link to={props.path}>
						<ArrowLeftIcon className="w-4 me-0.5" /> {props.name}
					</Link>
				</Button>
			</CardContent>
		</Card>
	)

	return !path.pathname.startsWith('/shield') &&	 (
		<div className="hidden md:flex bg-primary-foreground border-e-2 px-3 pb-4 flex-col justify-between sticky top-[57px] bottom-0 h-[94vh]">
			<nav className="flex flex-col h-max mt-2">
				{cdnDistributionRegex.test(path.pathname) && <GoBack name="CDN Distributions" path={cdnRoutes.distributions.path} />}
				{data.map((navItem) => (
					<Button
						key={navItem.link}
						aria-label={navItem.name}
						className={'rounded-lg w-[14rem] ps-2.5 justify-start mt-1.5' + (path.pathname === navItem.link ? ' bg-muted' : '')}
						size="sm"
						variant="outline"
						asChild
					>
						<Link to={navItem.link}>
							<navItem.icon className="size-4 me-1" />
							{navItem.name}
						</Link>
					</Button>
				))}
			</nav>
			<div className="text-center">
				<small className="text-gray-400">Management Console v2.0.0</small>
			</div>
		</div>
	)
}

export default NavBar
