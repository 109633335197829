import { type Dispatch, forwardRef, type ReactNode, type SetStateAction, useEffect } from 'react'
import { type HeadersConfiguration } from '../../../../api/types/cdn.tsx'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from 'aptranet-ui/components/ui/form.tsx'
import { Switch } from 'aptranet-ui/components/ui/switch.tsx'
import { Accordion, AccordionContent, AccordionItem } from 'aptranet-ui/components/ui/accordion.tsx'
import { RadioGroup, RadioGroupItem } from 'aptranet-ui/components/ui/radio-group.tsx'
import { Input } from 'aptranet-ui/components/ui/input.tsx'
import { CircleHelpIcon } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'aptranet-ui/components/ui/tooltip.tsx'
import { updateDistributionHeadersConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { toast } from 'sonner'

interface HeadersConfigurationProps {
	distributionID: number
	headersConfigurationData: HeadersConfiguration
	refreshHeadersConfigurationData: () => void
	setResetForm: Dispatch<SetStateAction<{ run: () => void }>>
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setUpdateHeadersConfigurationLoading: Dispatch<SetStateAction<boolean>>
}

const formSchema = z.object({
	cors_headers: z.object({
		enabled: z.boolean().optional(),
		mode: z.enum(['all_origins', 'http_origin_for_listed_origins', 'http_origin_for_all_origins']),
		value: z.string().optional(),
		add_always: z.boolean().optional(),
	}),
	custom_host_header: z.object({
		enabled: z.boolean().optional(),
		mode: z.enum(['custom', 'forward']),
		value: z.string().optional(),
	}),
	// request_headers_add: z.array(z.object({
	// 	name: z.string(),
	// 	value: z.string(),
	// })),
	// response_headers_add: z.array(z.object({
	// 	name: z.string(),
	// 	value: z.string(),
	// })),
})

const HeadersConfigurationForm = forwardRef<HTMLFormElement, HeadersConfigurationProps>((props, ref): ReactNode => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			cors_headers: {
				enabled: props.headersConfigurationData.cors_headers.enabled,
				mode: props.headersConfigurationData.cors_headers.mode,
				value: props.headersConfigurationData.cors_headers.origins ? props.headersConfigurationData.cors_headers.origins.join(",") : "",
				add_always: props.headersConfigurationData.cors_headers.add_always,
			},
			custom_host_header: {
				enabled: props.headersConfigurationData.custom_host_header.enabled,
				mode: props.headersConfigurationData.custom_host_header.mode,
				value: props.headersConfigurationData.custom_host_header.value,
			},
			// request_headers_add: [{
			// 	name: "testkey",
			// 	value: "testval"
			// }],
			// response_headers_add: [{
			// 	name: "testkey",
			// 	value: "testval"
			// }]
		},
	})

	const corsHeadersEnabled = form.watch('cors_headers.enabled')
	const corsHeadersMode = form.watch('cors_headers.mode')

	const customHostHeaderEnabled = form.watch('custom_host_header.enabled')
	const customHostHeaderMode = form.watch('custom_host_header.mode')

	useEffect(() => props.setResetForm({ run: form.reset }), [])
	useEffect(() => props.setUnsavedChangesShown(form.formState.isDirty), [form.formState.isDirty])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdateHeadersConfigurationLoading(true)

		// TODO IMPORTANT: CHECK IF CORS HEADERS VALUE IS EMPTY BEFORE DOING THIS.

		updateDistributionHeadersConfiguration(props.distributionID, {
			cors_headers: {
				enabled: values.cors_headers.enabled || false,
				mode: values.cors_headers.mode,
				origins: values.cors_headers.mode === 'http_origin_for_listed_origins' ? values.cors_headers.value!.replaceAll(/ /g,'').split(",") : undefined,
				add_always: values.cors_headers.add_always || false
			},
			custom_host_header: {
				enabled: values.custom_host_header.enabled || false,
				mode: values.custom_host_header.mode,
				value: values.custom_host_header.mode === "custom" ? values.custom_host_header.value : undefined
			}
		})
			.then(() => {
				props.setUpdateHeadersConfigurationLoading(false)
				toast.success('Headers configuration updated successfully. Please allow up to 15 minutes for the changes to fully propagate to our edge.')
				props.refreshHeadersConfigurationData()
			})
			.catch(() => props.setUpdateHeadersConfigurationLoading(false))
	}

	return <Form {...form}>
		<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
			<div className="flex flex-col items-center gap-4 w-full">
				<h2 className="w-full max-w-4xl text-muted-foreground text-2xl text-start font-semibold">CORS Settings</h2>
				<Card className={'w-full max-w-4xl' + (corsHeadersEnabled ? ' border-primary' : '')}>
					<CardHeader className="pb-0 flex flex-row justify-between items-center">
						<div>
							<CardTitle>CORS Headers</CardTitle>
							<CardDescription className="mt-2 w-3/4">
								This option enables the inclusion of the 'Access-Control-Allow-Origin' header on a CDN, allowing you to
								specify one of the following values.
							</CardDescription>
						</div>
						<FormField
							control={form.control}
							name="cors_headers.enabled"
							render={({ field }) => (
								<FormControl>
									<FormItem>
										<Switch checked={field.value} onCheckedChange={field.onChange} />
									</FormItem>
								</FormControl>
							)}
						/>
					</CardHeader>
					<CardContent>
						<Accordion type="single" className="mx-1" value={corsHeadersEnabled ? 'corsHeadersEnabled' : 'none'}
											 collapsible>
							<AccordionItem value="corsHeadersEnabled" className="border-b-0">
								<AccordionContent>
									<div className="mt-4 mx-1">
										<FormField
											control={form.control}
											name="cors_headers.mode"
											render={({ field }) => (
												<FormItem>
													<FormControl>
														<RadioGroup onValueChange={field.onChange} defaultValue={field.value}
																				className="flex space-x-2">
															<FormItem className="flex items-center space-x-1 space-y-0">
																<FormControl>
																	<RadioGroupItem value="all_origins" />
																</FormControl>
																<FormLabel className="font-normal">All Domains (*)</FormLabel>
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger>
																			<div className="pt-0.5">
																				<CircleHelpIcon size={16} />
																			</div>
																		</TooltipTrigger>
																		<TooltipContent>
																			<p className="max-w-md">Adds * as the Access-Control-Allow-Origin header value -
																				Content will be
																				uploaded for requests from any domain.</p>
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															</FormItem>
															<FormItem className="flex items-center space-x-1 space-y-0">
																<FormControl>
																	<RadioGroupItem value="http_origin_for_listed_origins" />
																</FormControl>
																<FormLabel className="font-normal">$http_origin for the origins listed</FormLabel>
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger>
																			<div className="pt-0.5">
																				<CircleHelpIcon size={16} />
																			</div>
																		</TooltipTrigger>
																		<TooltipContent>
																			<p className="max-w-md">Adds "$http_origin" as the Access-Control-Allow-Origin
																				header value if the
																				origin matches one of the listed domains - Content will be uploaded only for
																				requests from the domains specified in the field.</p>
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															</FormItem>
															<FormItem className="flex items-center space-x-1 space-y-0">
																<FormControl>
																	<RadioGroupItem value="http_origin_for_all_origins" />
																</FormControl>
																<FormLabel className="font-normal">$http_origin for all origins</FormLabel>
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger>
																			<div className="pt-0.5">
																				<CircleHelpIcon size={16} />
																			</div>
																		</TooltipTrigger>
																		<TooltipContent>
																			<p className="max-w-md">Adds "$http_origin" as the Access-Control-Allow-Origin
																				header value - Content
																				will be uploaded for requests from any domain, and the domain from which the
																				request was sent will be added to the "Access-Control-Allow-Origin" header in
																				the response.</p>
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															</FormItem>
														</RadioGroup>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>

										{corsHeadersMode === 'http_origin_for_listed_origins' && (
											<div className="mt-4">
												<FormField
													control={form.control}
													name="cors_headers.value"
													render={({ field }) => (
														<FormControl>
															<FormItem>
																<FormLabel>Origins</FormLabel>
																<FormControl>
																	<Input {...field} placeholder="origin.com, origin1.net" />
																</FormControl>
																<FormDescription>Origins should be separated by commas.</FormDescription>
																<FormMessage />
															</FormItem>
														</FormControl>
													)}
												/>
											</div>
										)}
									</div>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</CardContent>
				</Card>

				{/*======================= Static Headers is a feature that will come out later. =======================*/}

				{/*<Separator className="w-full max-w-4xl my-3" />*/}
				{/*<h2 className="w-full max-w-4xl text-muted-foreground text-2xl text-start font-semibold">Static Headers</h2>*/}

				<h2 className="w-full max-w-4xl text-muted-foreground text-2xl text-start font-semibold">Host Header</h2>
				<Card className={'w-full max-w-4xl' + (customHostHeaderEnabled ? ' border-primary' : '')}>
					<CardHeader className="pb-0 flex flex-row justify-between items-center">
						<div>
							<CardTitle>Custom Host Header</CardTitle>
							<CardDescription className="mt-2 w-3/4">
								If disabled, the Host header will match the Distribution hostname. If your web server manages multiple
								websites on a single IP address, enable this option to explicitly define the virtual host (website) you
								want to connect with.
							</CardDescription>
						</div>
						<FormField
							control={form.control}
							name="custom_host_header.enabled"
							render={({ field }) => (
								<FormControl>
									<FormItem>
										<Switch checked={field.value} onCheckedChange={field.onChange} />
									</FormItem>
								</FormControl>
							)}
						/>
					</CardHeader>
					<CardContent>
						<Accordion type="single" className="mx-1"
											 value={customHostHeaderEnabled ? 'customHostHeaderEnabled' : 'none'}
											 collapsible>
							<AccordionItem value="customHostHeaderEnabled" className="border-b-0">
								<AccordionContent>
									<div className="mt-4 mx-1">
										<FormField
											control={form.control}
											name="custom_host_header.mode"
											render={({ field }) => (
												<FormItem>
													<FormControl>
														<RadioGroup onValueChange={field.onChange} defaultValue={field.value}
																				className="flex space-x-2">
															<FormItem className="flex items-center space-x-1 space-y-0">
																<FormControl>
																	<RadioGroupItem value="custom" />
																</FormControl>
																<FormLabel className="font-normal">Custom Host Header</FormLabel>
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger>
																			<div className="pt-0.5">
																				<CircleHelpIcon size={16} />
																			</div>
																		</TooltipTrigger>
																		<TooltipContent>
																			<p className="max-w-md">This allows you to set a custom Host header. This header
																				tells our CDN servers which domain to use when requesting content from your
																				origin server.
																				Important: Make sure your origin server is configured to handle requests with
																				this custom header.</p>
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															</FormItem>
															<FormItem className="flex items-center space-x-1 space-y-0">
																<FormControl>
																	<RadioGroupItem value="forward" />
																</FormControl>
																<FormLabel className="font-normal">Forward Host Header</FormLabel>
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger>
																			<div className="pt-0.5">
																				<CircleHelpIcon size={16} />
																			</div>
																		</TooltipTrigger>
																		<TooltipContent>
																			<p className="max-w-md">When requesting content from an origin server, we will
																				include the original Host header used in the request made to the CDN.</p>
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															</FormItem>
														</RadioGroup>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>

										{customHostHeaderMode === 'custom' && (
											<div className="mt-4">
												<FormField
													control={form.control}
													name="custom_host_header.value"
													render={({ field }) => (
														<FormControl>
															<FormItem>
																<FormLabel>Host Header</FormLabel>
																<FormControl>
																	<Input {...field} placeholder="example.com" />
																</FormControl>
																<FormMessage />
															</FormItem>
														</FormControl>
													)}
												/>
											</div>
										)}
									</div>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</CardContent>
				</Card>

			</div>
		</form>
	</Form>
})

export default HeadersConfigurationForm