import {
	CodeIcon,
	FileTextIcon,
	HardDriveIcon,
	HomeIcon,
	LeafIcon,
	LineChartIcon,
	ListIcon,
	LockIcon,
	type LucideIcon,
	MoveIcon,
	NetworkIcon,
	ServerIcon,
	SettingsIcon,
	ShieldCheckIcon,
	ToggleRightIcon,
	UsersRoundIcon,
	WalletIcon,
} from 'lucide-react'

import billingRoutes from '../../../routes/billing.tsx'
import cdnRoutes from '../../../routes/cdn.tsx'
import homeRoutes from '../../../routes/home.tsx'

export interface NavItem {
	name: string
	icon: LucideIcon
	link: string
}

export const home: NavItem[] = [
	{
		name: 'Homepage',
		icon: HomeIcon,
		link: '/',
	},
	{
		name: 'Quotas',
		icon: ListIcon,
		link: homeRoutes.quotas.path,
	},
	{
		name: 'Settings',
		icon: SettingsIcon,
		link: homeRoutes.settings.path,
	},
	{
		name: 'Members',
		icon: UsersRoundIcon,
		link: '/members',
	},
	{
		name: 'Developers',
		icon: CodeIcon,
		link: '/developers',
	},
	{
		name: 'Climate',
		icon: LeafIcon,
		link: '/climate',
	},
]

export const cdn: NavItem[] = [
	{
		name: 'Distributions',
		icon: MoveIcon,
		link: cdnRoutes.distributions.path,
	},
	{
		name: 'Origin Groups',
		icon: ServerIcon,
		link: cdnRoutes.origin_groups.path,
	},
	// {
	// 	name: 'Purge Content',
	// 	icon: Trash2Icon,
	// 	link: cdnRoutes.purge_content.path,
	// },
	// {
	// 	name: 'Prefetch Content',
	// 	icon: HardDriveDownloadIcon,
	// 	link: cdnRoutes.prefetch_content.path,
	// },
]

export const cdnDistribution = (distributionID: number): NavItem[] => [
	{
		name: 'Analytics',
		icon: LineChartIcon,
		link: cdnRoutes.distribution_analytics.return_dynamic_path(distributionID),
	},
	{
		name: 'Cache',
		icon: HardDriveIcon,
		link: cdnRoutes.distribution_cache.return_dynamic_path(distributionID),
	},
	{
		name: 'Network',
		icon: NetworkIcon,
		link: cdnRoutes.distribution_network.return_dynamic_path(distributionID),
	},
	{
		name: 'SSL / TLS',
		icon: LockIcon,
		link: cdnRoutes.distribution_tls.return_dynamic_path(distributionID),
	},
	{
		name: 'Origin',
		icon: ServerIcon,
		link: cdnRoutes.distribution_origin.return_dynamic_path(distributionID),
	},
	{
		name: 'Headers',
		icon: FileTextIcon,
		link: cdnRoutes.distribution_headers.return_dynamic_path(distributionID),
	},
	{
		name: 'Security',
		icon: ShieldCheckIcon,
		link: cdnRoutes.distribution_security.return_dynamic_path(distributionID),
	},
	// {
	// 	name: 'Image Optimization',
	// 	icon: ImagesIcon,
	// 	link: cdnRoutes.distribution_image_optimization.return_dynamic_path(distributionID),
	// },
]

export const billing: NavItem[] = [
	{
		name: 'Services',
		icon: ToggleRightIcon,
		link: billingRoutes.services.path,
	},
	{
		name: 'Billing Information',
		icon: WalletIcon,
		link: billingRoutes.billing_information.path,
	},
]
