import * as React from 'react'

import { useMediaQuery } from 'aptranet-ui/hooks/use-media-query'
import { Button } from 'aptranet-ui/components/ui/button'

import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from 'aptranet-ui/components/ui/dialog'

import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
} from 'aptranet-ui/components/ui/drawer'

interface ResponsiveDialogProps {
	title: string,
	subtitle?: string,
	open: boolean,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>,
	closeButtonText: string,
	closeButtonDisabled?: boolean,
	children: React.ReactNode,
	footerChildren?: React.ReactNode
}

export const ResponsiveDialog = (props: ResponsiveDialogProps): React.ReactNode => {
	const isDesktop = useMediaQuery('(min-width: 768px)')

	if (isDesktop) {
		return (
			<Dialog open={props.open} onOpenChange={props.setOpen}>
				<DialogContent className="sm:max-w-[600px]" closeDisabled={props.closeButtonDisabled} onInteractOutside={props.closeButtonDisabled ? (e) => e.preventDefault() : undefined}>
					<DialogHeader>
						<DialogTitle>{props.title}</DialogTitle>
						{props.subtitle && <DialogDescription>
							{props.subtitle}
						</DialogDescription>}
					</DialogHeader>
					{props.children}
					<DialogFooter>
						<DialogClose asChild>
							<Button variant="outline" disabled={props.closeButtonDisabled}>{props.closeButtonText}</Button>
						</DialogClose>
						{props.footerChildren}
					</DialogFooter>
				</DialogContent>
			</Dialog>
		)
	}

	return (
		<Drawer open={props.open} onOpenChange={props.setOpen} dismissible={!props.closeButtonDisabled}>
			<DrawerContent onInteractOutside={props.closeButtonDisabled ? (e) => e.preventDefault() : undefined}>
				<DrawerHeader className="text-left">
					<DrawerTitle>{props.title}</DrawerTitle>
					{props.subtitle && <DrawerDescription>
						{props.subtitle}
					</DrawerDescription>}
				</DrawerHeader>
				<div className="px-4">
					{props.children}
				</div>
				<DrawerFooter className="pt-2">
					<DrawerClose asChild>
						<Button variant="outline" disabled={props.closeButtonDisabled}>{props.closeButtonText}</Button>
					</DrawerClose>
					{props.footerChildren}
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	)
}