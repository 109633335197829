import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { CacheConfigurationForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getDistributionCacheConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { type CacheConfiguration } from '../../../../api/types/cdn.tsx'

const CacheConfiguration = () => {
	const { distributionID } = useParams()
	const cacheConfigurationRef = useRef<HTMLFormElement>(null)

	const [cacheConfigurationData, setCacheConfigurationData] = useState<CacheConfiguration | null>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateCacheConfigurationLoading, setUpdateCacheConfigurationLoading] = useState<boolean>(false)
	const [resetForm, setResetForm] = useState<{ run: () => void }>({ run: () => undefined })

	const fetchCacheConfiguration = () => {
		setCacheConfigurationData(null)
		getDistributionCacheConfiguration(Number(distributionID)).then((cacheConfiguration) => setCacheConfigurationData(cacheConfiguration))
	}

	useEffect(() => {
		fetchCacheConfiguration()
	}, [])

	return (
		<Content title="Cache Configuration" subtitle="Manage your Distribution's Cache Configuration.">
			{cacheConfigurationData ? (
				<>
					<CacheConfigurationForm
						ref={cacheConfigurationRef}
						distributionID={Number(distributionID)}
						cacheConfigurationData={cacheConfigurationData}
						refreshCacheConfigurationData={fetchCacheConfiguration}
						setResetForm={setResetForm}
						setUnsavedChangesShown={setUnsavedChangesShown}
						setUpdateCacheConfigurationLoading={setUpdateCacheConfigurationLoading}
					/>
					<UnsavedChangesPopup
						shown={unsavedChangesShown}
						saveAction={() => cacheConfigurationRef.current?.requestSubmit()}
						cancelAction={() => resetForm.run()}
						cancelButtonText="Discard Changes"
						saveButtonText="Update Cache Configuration"
						saveButtonLoading={updateCacheConfigurationLoading}
					/>
				</>
			) : (
				<div className="flex flex-col items-center gap-3">
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-36" />
					<Skeleton className="w-full max-w-4xl h-36" />
				</div>
			)}
		</Content>
	)
}

export default CacheConfiguration
