import * as React from 'react'
import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	type RowSelectionState,
	type SortingState,
	useReactTable,
	type VisibilityState,
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'aptranet-ui/components/ui/table'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Input } from 'aptranet-ui/components/ui/input'
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from 'aptranet-ui/components/ui/dropdown-menu'

import { DataTablePagination } from 'aptranet-ui/components/ui/data-table-pagination.tsx'
import { Columns3Icon } from 'lucide-react'
// @ts-ignore
import { type Row } from '@tanstack/table-core/src/types.ts'

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[]
	data: TData[]
	searchBy?: string,
	searchByTitle?: string
	defaultShown?: { [key: string]: boolean }

	rowSelection?: RowSelectionState,
	setRowSelection?: React.Dispatch<React.SetStateAction<object>>
}

export function DataTable<TData, TValue>({
																					 columns,
																					 data,
																					 searchBy,
																					 searchByTitle,
																					 defaultShown,
																					 enableRowSelection,
																					 rowSelection = {},
																					 setRowSelection,
																				 }: DataTableProps<TData, TValue> & { enableRowSelection: boolean | ((row: Row<TData>) => boolean) }) {
	const [sorting, setSorting] = React.useState<SortingState>([])
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
	const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({ ...defaultShown })

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		enableRowSelection: enableRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
	})

	return (
		<div>
			<div className="flex items-center mb-4">
				{searchBy &&
					<Input
						placeholder={'Search by ' + searchByTitle ?? searchBy + '...'}
						value={(table.getColumn(searchBy)?.getFilterValue() as string) ?? ''}
						onChange={(event) =>
							table.getColumn(searchBy)?.setFilterValue(event.target.value)
						}
						className="max-w-sm"
					/>}
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="outline" className="ml-auto">
							<Columns3Icon size="18" className="me-0.5" /> Columns
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						{table
							.getAllColumns()
							.filter(
								(column) => column.getCanHide(),
							)
							.map((column) => {
								return (
									<DropdownMenuCheckboxItem
										key={column.id}
										className="capitalize"
										checked={column.getIsVisible()}
										onCheckedChange={(value) =>
											column.toggleVisibility(value)
										}
									>
										{column.id.replaceAll('_', ' ')}
									</DropdownMenuCheckboxItem>
								)
							})}
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
										</TableHead>
									)
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && 'selected'}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns.length} className="h-24 text-center">
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<DataTablePagination table={table} />
			</div>
		</div>
	)
}
