import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import ContentUnavailable from 'aptranet-ui/components/management-console/generic/content-unavailable.tsx'
import { useState } from 'react'

const SecurityConfiguration = () => {
	const [getNotifiedShown, setGetNotifiedShown] = useState<boolean>(false)
	return (
		<Content title="Security Configuration" subtitle="Manage your distribution's security configuration.">

			<ContentUnavailable title="Secure CDN Distributions with Aptranet Shield"
													description="Aptranet Shield can protect your websites, web applications, and APIs from the latest online attacks. By combining WAF, bot management, DDoS mitigation, and API security, we deliver multi-layered protection that safeguards your data, ensures uninterrupted service, and bolsters your customers' confidence."
													imageURL="https://assets.aptranetedge.com/management-console/images/graphics/cdn-security.svg"
													imageAlt="CDN Security Configuration Image">
				<p className="font-semibold">Coming Soon</p>
				<div className="mt-5">
					{getNotifiedShown ?
						<iframe src="https://dashboard.mailerlite.com/forms/850625/134430147706094867/share" className="min-w-96 h-36"></iframe> :
						<Button onClick={() => setGetNotifiedShown(true)}>Get notified</Button>}
				</div>
			</ContentUnavailable>
		</Content>
	)
}

export default SecurityConfiguration