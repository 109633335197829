import ContentUnavailable from 'aptranet-ui/components/management-console/generic/content-unavailable'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { useState } from 'react'

const ShieldComingSoon = () => {
	const [getNotifiedShown, setGetNotifiedShown] = useState<boolean>(false)
	return (
		<ContentUnavailable title="Secure your infrastructure with Aptranet Shield"
												description="Aptranet Shield can protect your websites, web applications, and APIs from the latest online attacks. By combining WAF, bot management, DDoS mitigation, and API security, we deliver multi-layered protection that safeguards your data, ensures uninterrupted service, and bolsters your customers' confidence."
												imageURL="https://assets.aptranetedge.com/management-console/images/graphics/cdn-security.svg"
												imageAlt="CDN Security Configuration Image">
			<p className="font-semibold">Coming Soon</p>
			<div className="mt-5">
				{getNotifiedShown ?
					<iframe src="https://dashboard.mailerlite.com/forms/850625/134430147706094867/share"
									className="min-w-96 h-36"></iframe> :
					<Button onClick={() => setGetNotifiedShown(true)}>Get notified</Button>}
			</div>
		</ContentUnavailable>
	)
}

export default ShieldComingSoon