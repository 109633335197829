import isValidDomain from '@tahul/is-valid-domain'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function aptranetUIMerge(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export const calcPercentage = (value: number, max: number = 100, min: number = 0): number => ((value - min) * 100) / (max - min)

export const validateNetworkAddress = (address: string, canBeHostname: boolean): boolean => {
	const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/
	const ipv6Regex = /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i

	if (ipv4Regex.test(address)) {
		return address.split('.').every((part) => parseInt(part) <= 255)
	}

	if (ipv6Regex.test(address)) {
		return address.split(':').every((part) => part.length <= 4)
	}

	if (canBeHostname) {
		return isValidDomain(address)
	}

	return false
}

export const formatBytes = (bytes: number, decimals = 2) => {
	if (!+bytes) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
