import { type Dispatch, type SetStateAction } from 'react'

import { originGroupsTableColumns } from 'aptranet-ui/components/management-console/cdn/origin-groups/origin-groups-table/columns.tsx'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { DataTable } from 'aptranet-ui/components/ui/data-table.tsx'

import { type RowSelectionState } from '@tanstack/react-table'

import { type OriginGroupInfo } from '../../../../../../api/types/cdn.tsx'

interface OriginGroupsTableProps {
	originGroups: OriginGroupInfo[]
	openDeleteOriginGroupModal: (originGroup: OriginGroupInfo) => void
	rowSelection: RowSelectionState
	setRowSelection: Dispatch<SetStateAction<object>>
}

const OriginGroupsTable = (props: OriginGroupsTableProps) => {
	const theme = useTheme()

	return (
		<div className="pt-2">
			<DataTable
				columns={originGroupsTableColumns(theme, props.openDeleteOriginGroupModal)}
				data={props.originGroups}
				searchBy="name"
				searchByTitle="Origin Group Name"
				enableRowSelection={true}
				rowSelection={props.rowSelection}
				setRowSelection={props.setRowSelection}
			/>
		</div>
	)
}

export default OriginGroupsTable
