import { useNavigate } from 'react-router-dom'

import { useUser } from '@clerk/clerk-react'

// import TrafficChart from 'aptranet-ui/components/management-console/cdn/charts/distributions/traffic-chart.tsx'
import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card'

import { CreditCardIcon, GaugeIcon } from 'lucide-react'

import cdnRoutes from '../../routes/cdn.tsx'
import billingRoutes from '../../routes/billing.tsx'

const Homepage = () => {
	const hours = new Date().getHours()
	const navigate = useNavigate()

	const { user } = useUser()
	const { actualTheme } = useTheme()

	return (
		<Content
			head="Home"
			title={hours < 12 ? `Good morning, ${user?.firstName}!` : hours < 18 ? `Good afternoon, ${user?.firstName}!` : `Hello ${user?.firstName}, Welcome Back!`}
			subtitle="This is the Aptranet Management Console homepage."
			actionItems={<iframe src={'https://status.aptranet.com/badge?theme=' + actualTheme} width="200" height="30"></iframe>}
		>
			<div className="grid sm:grid-cols-1 xl:grid-cols-1 gap-2">
				{/*<Card className="bg-muted">*/}
				{/*	<CardHeader>*/}
				{/*		<div className="flex justify-between">*/}
				{/*			<CardTitle className="text-xl font-normal">CDN Traffic</CardTitle>*/}
				{/*			<CardTitle className="text-xl font-normal">30TB</CardTitle>*/}
				{/*		</div>*/}
				{/*		<CardDescription>Project CDN traffic the last 30 days.</CardDescription>*/}
				{/*	</CardHeader>*/}
				{/*	<CardContent>*/}
				{/*		/!*<TrafficChart chartOnly={true} />*!/*/}
				{/*	</CardContent>*/}
				{/*</Card>*/}
				{/*<Card className="bg-muted">*/}
				{/*	<CardHeader>*/}
				{/*		<div className="flex justify-between">*/}
				{/*			<CardTitle className="text-xl font-normal">Storage Usage</CardTitle>*/}
				{/*			<CardTitle className="text-xl font-normal">1.5TB</CardTitle>*/}
				{/*		</div>*/}
				{/*		<CardDescription>Project Storage usage the last 30 days.</CardDescription>*/}
				{/*	</CardHeader>*/}
				{/*	<CardContent>*/}
				{/*		chart*/}
				{/*	</CardContent>*/}
				{/*</Card>*/}
				{/*<Card className="bg-muted">*/}
				{/*	<CardHeader>*/}
				{/*		<div className="flex justify-between">*/}
				{/*			<CardTitle className="text-xl font-normal">DNS Queries</CardTitle>*/}
				{/*			<CardTitle className="text-xl font-normal">3.2M</CardTitle>*/}
				{/*		</div>*/}
				{/*		<CardDescription>Project DNS queries the last 30 days.</CardDescription>*/}
				{/*	</CardHeader>*/}
				{/*	<CardContent>*/}
				{/*		chart*/}
				{/*	</CardContent>*/}
				{/*</Card>*/}
			</div>
			<Card className="mt-3">
				<CardHeader>
					<CardTitle>Quickstart</CardTitle>
					<CardDescription>Things you can do to get started with your project.</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2">
						<Button className="h-16 border-2" variant="outline" onClick={() => navigate(cdnRoutes.distributions_create.path)}>
							<GaugeIcon className="me-1" size="20" /> Create a CDN Distribution
						</Button>
						{/*<Button className="h-16 border-2" variant="outline"*/}
						{/*				onClick={() => navigate('/storage/buckets/new')}><ServerIcon className="me-1" size="20" /> Create a*/}
						{/*	Storage Bucket</Button>*/}
						{/*<Button className="h-16 border-2" variant="outline" onClick={() => navigate('/dns/zones/new')}><GlobeIcon*/}
						{/*	className="me-1" size="20" /> Create a*/}
						{/*	DNS Zone</Button>*/}
						{/*<Button className="h-16 border-2" variant="outline"><ShieldIcon className="me-1" size="20" /> Protect a*/}
						{/*	Resource</Button>*/}
						{/*<Button className="h-16 border-2" variant="outline"><PlayIcon className="me-1" size="20" /> Upload*/}
						{/*	a Video</Button>*/}
						{/*<Button className="h-16 border-2" variant="outline">*/}
						{/*	<LockIcon className="me-1" size="20" /> Create a TLS Certificate*/}
						{/*</Button>*/}
						<Button className="h-16 border-2" variant="outline" onClick={() => navigate(billingRoutes.billing_information.path)}>
							<CreditCardIcon className="me-1" size="20" /> Manage your Billing information
						</Button>
					</div>
				</CardContent>
			</Card>
		</Content>
	)
}

export default Homepage
