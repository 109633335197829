import { useRef, useState } from 'react'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { Button, LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card'
import { ProjectSettingsForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

const Settings = () => {
	const projectSettingsFormRef = useRef<HTMLFormElement>(null)

	const [updateProjectSettingsLoading, setUpdateProjectSettingsLoading] = useState(false)

	return (
		<Content title="Settings" subtitle="Your project settings.">
			<div className="grid grid-cols-2 gap-4">
				<Card>
					<CardContent className="flex flex-col justify-center items-center">
						<div className="grid w-full max-w-lg items-center gap-1.5 mt-6">
							<ProjectSettingsForm ref={projectSettingsFormRef} setUpdateLoading={setUpdateProjectSettingsLoading} />
						</div>
						<LoadingButton
							className="w-64 mt-4"
							variant="outline"
							text="Update"
							loading={updateProjectSettingsLoading}
							onClick={() => projectSettingsFormRef.current?.requestSubmit()}
						/>
					</CardContent>
				</Card>
				<Card>
					<CardHeader>
						<CardTitle>Danger Zone</CardTitle>
						<CardDescription>These actions are irreversible.</CardDescription>
					</CardHeader>
					<CardContent className="flex flex-col justify-center items-center">
						<div className="grid grid-cols-2 w-full max-w-lg items-center gap-1.5">
							<Button variant="secondary" disabled>Transfer Project</Button>
							<Button variant="destructive" disabled>Delete Project</Button>
						</div>
					</CardContent>
				</Card>
			</div>
		</Content>
	)
}

export default Settings
