import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { CreateDistributionForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getRouteList } from '../../../routes/routes.tsx'

const CreateDistribution = () => {
	const navigate = useNavigate()
	const createDistributionRef = useRef<HTMLFormElement>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [createDistributionLoading, setCreateDistributionLoading] = useState<boolean>(false)

	return (
		<Content title="Create Distribution" subtitle="Create a new Distribution.">
			<CreateDistributionForm
				ref={createDistributionRef}
				setUnsavedChangesShown={setUnsavedChangesShown}
				setCreateDistributionLoading={setCreateDistributionLoading}
			/>
			<UnsavedChangesPopup
				shown={unsavedChangesShown}
				saveAction={() => createDistributionRef.current?.requestSubmit()}
				cancelAction={() => navigate(getRouteList().cdn.distributions.path)}
				cancelButtonText="Cacnel"
				saveButtonText="Create Distribution"
				saveButtonLoading={createDistributionLoading}
			/>
		</Content>
	)
}

export default CreateDistribution
