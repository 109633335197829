import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { aptranetUIMerge } from 'aptranet-ui/lib/utils'

const badgeVariants = cva(
	'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
	{
		variants: {
			variant: {
				default:
					'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
				primary:
					'border-transparent bg-blue-800 text-destructive-foreground hover:bg-blue-800/80',
				secondary:
					'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
				success:
					'border-transparent bg-green-800 text-destructive-foreground hover:bg-green-800/80',
				warning:
					'border-transparent bg-orange-800 text-destructive-foreground hover:bg-orange-800/80',
				destructive:
					'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
				outline: 'text-foreground',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
)

export interface BadgeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {
}

function Badge({ className, variant, ...props }: BadgeProps) {
	return (
		<div className={aptranetUIMerge(badgeVariants({ variant }), className)} {...props} />
	)
}

export { Badge, badgeVariants }
