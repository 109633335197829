import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from 'aptranet-ui/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'aptranet-ui/components/ui/command'
import { Drawer, DrawerContent, DrawerTrigger } from 'aptranet-ui/components/ui/drawer'
import { Popover, PopoverContent, PopoverTrigger } from 'aptranet-ui/components/ui/popover'
import { Separator } from 'aptranet-ui/components/ui/separator.tsx'
import { useMediaQuery } from 'aptranet-ui/hooks/use-media-query'

import { ChevronDownIcon, FolderIcon } from 'lucide-react'

import { listProjects } from '../../../../api/central/projects.tsx'
import { type Project } from '../../../../api/types/central.ts'

export const ProjectSelector = () => {
	const [open, setOpen] = useState(false)
	const isDesktop = useMediaQuery('(min-width: 768px)')
	const [selectedProject, setSelectedProject] = useState<Project>({
		id: Number(localStorage.getItem('selected_project_id')) || 0,
		name: localStorage.getItem('selected_project_name') || 'Select Project',
	})

	if (isDesktop) {
		return (
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button variant="outline" className="justify-start">
						<FolderIcon className="w-4 me-1" /> {selectedProject.name} <ChevronDownIcon className="w-4 ms-1.5" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[200px] p-0" align="start">
					<ProjectList setOpen={setOpen} setSelectedProject={setSelectedProject} />
				</PopoverContent>
			</Popover>
		)
	}

	return (
		<Drawer open={open} onOpenChange={setOpen}>
			<DrawerTrigger asChild>
				<Button variant="outline" className="w-[150px] justify-start">
					<FolderIcon className="w-4 me-1" /> {selectedProject ? selectedProject.name : 'Select Project'}
					<ChevronDownIcon className="w-4 ms-1.5" />
				</Button>
			</DrawerTrigger>
			<DrawerContent>
				<div className="mt-4 border-t">
					<ProjectList setOpen={setOpen} setSelectedProject={setSelectedProject} />
				</div>
			</DrawerContent>
		</Drawer>
	)
}

const ProjectList = ({ setOpen, setSelectedProject }: { setOpen: (open: boolean) => void; setSelectedProject: (status: Project) => void }) => {
	const { pathname } = useLocation()
	const navigate = useNavigate()

	const [projects, setProjects] = useState<Project[]>([])
	useEffect(() => {
		if (projects.length === 0) {
			listProjects(6)
				.then((projects) => setProjects(projects))
				.catch((err) => console.log(err))
		}
	}, [])

	return (
		<Command>
			<CommandInput placeholder="Filter projects..." />
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				<CommandGroup>
					{projects.map((project: Project) => (
						<CommandItem
							key={project.id}
							value={project.name}
							onSelect={() => {
								setSelectedProject({
									id: project.id,
									name: project.name,
								})

								localStorage.setItem('selected_project_id', project.id.toString())
								localStorage.setItem('selected_project_name', project.name)
								localStorage.setItem('selected_project_description', project.description || '')

								if (pathname === '/') {
									window.location.reload()
								} else {
									navigate('/')
								}

								setOpen(false)
							}}
						>
							{project.name}
						</CommandItem>
					))}

					<Separator className="my-1" />

					<CommandItem key="view_all" value="view_all" onSelect={() => navigate('/projects')}>
						View All
					</CommandItem>
				</CommandGroup>
			</CommandList>
		</Command>
	)
}
