import { zodResolver } from '@hookform/resolvers/zod'
import { ToggleGroup } from '@radix-ui/react-toggle-group'
import { toast } from 'sonner'
import { z } from 'zod'

import { type Dispatch, type ReactNode, type SetStateAction, forwardRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'aptranet-ui/components/ui/form.tsx'
import { Input } from 'aptranet-ui/components/ui/input.tsx'
import { ToggleGroupItemCard } from 'aptranet-ui/components/ui/toggle-group.tsx'

import { HandshakeIcon, LockIcon, LockOpenIcon, PlusIcon, RotateCwIcon } from 'lucide-react'

import { createDistribution } from '../../../api/cdn/distributions.tsx'
import { listOriginGroups } from '../../../api/cdn/origin-groups.tsx'
import { type OriginGroupInfo, OriginGroupInfoMinimal } from '../../../api/types/cdn.tsx'
import { getRouteList } from '../../../routes/routes.tsx'
import {
	OriginGroupSelector
} from 'aptranet-ui/components/management-console/cdn/distributions/origin-group-selector/origin-group-selector.tsx'

interface CreateDistributionProps {
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setCreateDistributionLoading: Dispatch<SetStateAction<boolean>>
}

const formSchema = z.object({
	name: z
		.string()
		.min(4)
		.max(64)
		.regex(/^[\w\-\s]+$/, { message: 'Distribution name can only contain letters, numbers, spaces, dashes and underscores.' }),
	origin_group_id: z.number().min(1, { message: 'Please select an Origin Group' }),
	origin_connection_protocol: z.enum(['http', 'https', 'match']),
})

const CreateDistributionForm = forwardRef<HTMLFormElement, CreateDistributionProps>((props, ref): ReactNode => {
	const navigate = useNavigate()
	const [originGroups, setOriginGroups] = useState<OriginGroupInfo[] | null>(null)
	const [selectedOriginGroup, setSelectedOriginGroup] = useState<OriginGroupInfo | OriginGroupInfoMinimal | null>(null)

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: '',
			origin_group_id: 0,
			origin_connection_protocol: 'http',
		},
	})

	useEffect(() => props.setUnsavedChangesShown(form.formState.isDirty), [form.formState.isDirty])

	const fetchOriginGroups = () => {
		setOriginGroups(null)
		listOriginGroups().then((res) => setOriginGroups(res))
	}

	useEffect(() => {
		fetchOriginGroups()
	}, [])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setCreateDistributionLoading(true)
		createDistribution(values)
			.then(() => {
				props.setCreateDistributionLoading(false)
				toast.promise(() => new Promise((resolve) => setTimeout(() => resolve(true), 6000)), {
					loading: 'Distribution "' + values.name + '" is being created.',
					success: 'Distribution has been created, please allow up to 15 minutes for changes to propagate across our edge.',
					error: 'Error while creating distribution.',
				})
				navigate(getRouteList().cdn.distributions.path)
			})
			.catch(() => props.setCreateDistributionLoading(false))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Distribution Name</FormLabel>
							<FormControl>
								<Input placeholder="Cool Distribution" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="origin_group_id"
					render={({ field }) => (
						<FormItem className="flex flex-col">
							<FormLabel>Origin Group</FormLabel>
							<div className="flex gap-2 w-full">
								<FormControl>
									<OriginGroupSelector
										originGroups={originGroups}
										setOriginGroups={setOriginGroups}
										selectedOriginGroup={selectedOriginGroup}
										setSelectedOriginGroup={setSelectedOriginGroup}
										onValueChange={field.onChange}
									/>
								</FormControl>
								<Button type="button" variant="outline" size="icon" onClick={() => fetchOriginGroups()}>
									<RotateCwIcon />
								</Button>
							</div>
							<FormMessage />
						</FormItem>
					)}
				/>
				<Button variant="outline" asChild>
					<a href={getRouteList().cdn.origin_group_create.path} target="_blank">
						<PlusIcon className="w-5" />
						Create Origin Group
					</a>
				</Button>
				<FormField
					control={form.control}
					name="origin_connection_protocol"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Origin Connection Protocol</FormLabel>
							<FormControl>
								<ToggleGroup type="single" onValueChange={field.onChange} defaultValue={field.value} className="flex justify-start gap-2">
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard value="http" title="HTTP" icon={LockOpenIcon} />
										</FormControl>
									</FormItem>
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard value="https" title="HTTPS" icon={LockIcon} />
										</FormControl>
									</FormItem>
									<FormItem>
										<FormControl>
											<ToggleGroupItemCard value="match" title="HTTP or HTTPS" icon={HandshakeIcon} />
										</FormControl>
									</FormItem>
								</ToggleGroup>
							</FormControl>
						</FormItem>
					)}
				/>
			</form>
		</Form>
	)
})

export default CreateDistributionForm
