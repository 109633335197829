import requestInterceptor from '../interceptor.tsx'
import {
	type CacheConfiguration,
	type HeadersConfiguration,
	type NetworkConfiguration,
	type OriginConfiguration,
	type TLSConfiguration,
	type UpdateOriginConfiguration,
} from '../types/cdn.tsx'

export const getDistributionCacheConfiguration = (distributionID: number): Promise<CacheConfiguration> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/distributions/configurations/cache', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.data))
			.catch((err) => reject(err.response.data)),
	)

export const updateDistributionCacheConfiguration = (distributionID: number, cacheConfiguration: CacheConfiguration): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.put('/cdn/distributions/configurations/cache', cacheConfiguration, {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.status === 200))
			.catch((err) => reject(err.response.data)),
	)

export const getDistributionNetworkConfiguration = (distributionID: number): Promise<NetworkConfiguration> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/distributions/configurations/network', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.data))
			.catch((err) => reject(err.response.data)),
	)

export const updateDistributionNetworkConfiguration = (distributionID: number, networkConfiguration: NetworkConfiguration): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.put('/cdn/distributions/configurations/network', networkConfiguration, {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.status === 200))
			.catch((err) => reject(err.response.data)),
	)

export const getDistributionTLSConfiguration = (distributionID: number): Promise<TLSConfiguration> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/distributions/configurations/tls', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.data))
			.catch((err) => reject(err.response.data)),
	)

export const updateDistributionTLSConfiguration = (distributionID: number, tlsConfiguration: TLSConfiguration): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.put('/cdn/distributions/configurations/tls', tlsConfiguration, {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.status === 200))
			.catch((err) => reject(err.response.data)),
	)

export const getDistributionOriginConfiguration = (distributionID: number): Promise<OriginConfiguration> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/distributions/configurations/origin', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.data))
			.catch((err) => reject(err.response.data)),
	)

export const updateDistributionOriginConfiguration = (distributionID: number, originConfiguration: UpdateOriginConfiguration): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.put('/cdn/distributions/configurations/origin', originConfiguration, {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.status === 200))
			.catch((err) => reject(err.response.data)),
	)

export const getDistributionHeadersConfiguration = (distributionID: number): Promise<HeadersConfiguration> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/distributions/configurations/headers', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.data))
			.catch((err) => reject(err.response.data)),
	)

export const updateDistributionHeadersConfiguration = (distributionID: number, headersConfiguration: HeadersConfiguration): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.put('/cdn/distributions/configurations/headers', headersConfiguration, {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID,
				},
			})
			.then((res) => resolve(res.status === 200))
			.catch((err) => reject(err.response.data)),
	)