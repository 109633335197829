import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts'

import { useMemo, useState } from 'react'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { type ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from 'aptranet-ui/components/ui/chart.tsx'

type ChartData = { [key: string]: { timestamp: string; value: number }[] }

interface AptranetBarChartProps {
	title: string
	description: string

	config: ChartConfig
	data: ChartData
	formatter: (totalValue: number) => string
	totalCalculator: (values: number[]) => number

	chartOnly?: boolean
}

const Chart = (props: { config: ChartConfig; data: ChartData; formatter: (totalValue: number) => string; activeChart: string }) => (
	<ChartContainer config={props.config} className="aspect-auto h-[250px] w-full">
		<BarChart
			accessibilityLayer
			data={props.data[props.activeChart]}
			margin={{
				left: 12,
				right: 12,
			}}
		>
			<CartesianGrid vertical={false} />
			<XAxis
				dataKey="timestamp"
				tickLine={false}
				axisLine={false}
				tickMargin={8}
				minTickGap={32}
				tickFormatter={(value) => {
					const date = new Date(value)
					return date.toLocaleDateString('en-GB', {
						month: 'short',
						day: 'numeric',
					})
				}}
			/>
			<ChartTooltip
				content={
					<ChartTooltipContent
						className="w-[150px]"
						nameKey="requests"
						indicator="dashed"
						labelFormatter={(value) => {
							return new Date(value).toLocaleDateString('en-GB', {
								month: 'short',
								day: 'numeric',
								year: 'numeric',
							})
						}}
					/>
				}
			/>
			<defs>
				<linearGradient id={'fill' + props.activeChart} x1="0" y1="0" x2="0" y2="1">
					<stop offset="5%" stopColor={`var(--color-${props.activeChart})`} stopOpacity={1} />
					<stop offset="95%" stopColor={`var(--color-${props.activeChart})`} stopOpacity={0.6} />
				</linearGradient>
			</defs>
			<Bar dataKey="value" name={props.config[props.activeChart].label?.toString()} fill={`url(#fill${props.activeChart})`} />
		</BarChart>
	</ChartContainer>
)

const AptranetBarChart = (props: AptranetBarChartProps) => {
	const charts = Object.keys(props.config)
	const [activeChart, setActiveChart] = useState<string>(charts[0])

	const total = useMemo(() => props.totalCalculator(props.data[activeChart].map((data) => data.value)), [])

	return props.chartOnly ? (
		<Chart config={props.config} data={props.data} formatter={props.formatter} activeChart={activeChart} />
	) : (
		<Card>
			<CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
				<div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
					<CardTitle>{props.title}</CardTitle>
					<CardDescription>{props.description}</CardDescription>
				</div>
				{charts.length === 1 ? (
					<div className="relative z-30 flex flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
						<span className="text-xs text-muted-foreground">Total {props.config[activeChart].label}</span>
						<span className="text-lg font-bold leading-none sm:text-3xl">{props.formatter(total)}</span>
					</div>
				) : (
					<div className="flex">
						{charts.map((key) => {
							const chart = key as keyof typeof props.config
							return (
								<button
									key={chart}
									data-active={activeChart === chart}
									className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
									onClick={() => setActiveChart(chart)}
								>
									<span className="text-xs text-muted-foreground">{props.config[chart].label}</span>
									<span className="text-lg font-bold leading-none sm:text-3xl">{total.toLocaleString()}</span>
								</button>
							)
						})}
					</div>
				)}
			</CardHeader>
			<CardContent className="pt-4 pb-6">
				<Chart config={props.config} data={props.data} formatter={props.formatter} activeChart={activeChart} />
			</CardContent>
		</Card>
	)
}

export default AptranetBarChart
