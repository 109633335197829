import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { z } from 'zod'
import { zodToJsonSchema } from 'zod-to-json-schema'

import { type Dispatch, type ReactNode, type SetStateAction, forwardRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'aptranet-ui/components/ui/accordion'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'aptranet-ui/components/ui/form'
import { Input } from 'aptranet-ui/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'aptranet-ui/components/ui/select.tsx'
import { Separator } from 'aptranet-ui/components/ui/separator'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { Switch } from 'aptranet-ui/components/ui/switch'
import { permissions } from 'aptranet-ui/forms/home/developers/permissions.tsx'

import { updateAPIKey } from '../../../../api/central/developers.tsx'
import { services } from '../../../../api/services.tsx'
import { getRouteList } from '../../../../routes/routes.tsx'

const formSchema = z.object({
	api_key_name: z.string().min(1).max(64),
	api_key_status: z.string(),
	permissions,
})

interface createAPIKeyProps {
	apiKeyID: number
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setUpdateAPIKeyLoading: Dispatch<SetStateAction<boolean>>
	permissions: { [key: string]: { [key: string]: boolean } } | null
}

const EditApiKeyForm = forwardRef<HTMLFormElement, createAPIKeyProps>((props, ref): ReactNode => {
	const { state } = useLocation()
	const { actualTheme } = useTheme()
	const navigate = useNavigate()

	//This is added for .properties. It exists.
	// @ts-ignore
	const permissionsObj = zodToJsonSchema(permissions, 'permissions').definitions!.permissions!.properties
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			api_key_name: state.api_key_name,
			api_key_status: state.api_key_status.toString(),
		},
	})

	useEffect(() => props.setUnsavedChangesShown(Object.keys(form.formState.dirtyFields).length > 0), [form.formState.dirtyFields])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdateAPIKeyLoading(true)

		const services = Object.keys(values.permissions)
		for (let i = 0; i < services.length; i++) {
			// @ts-ignore This has been type-checked previously.
			const permissions = Object.keys(values.permissions[services[i]])
			for (let j = 0; j < permissions.length; j++) {
				// @ts-ignore This has been type-checked previously.
				values.permissions[services[i]][permissions[j]] = values.permissions[services[i]][permissions[j]].enabled
			}
		}

		Object.keys(values.permissions).forEach((service) =>
			// @ts-ignore
			Object.keys(values.permissions[service]).forEach((permission) => {
				// @ts-ignore
				const permissionValue = values.permissions[service][permission]

				if (permissionValue !== undefined) {
					props.permissions![service][permission] = permissionValue
				}
			})
		)

		// @ts-ignore We have converted the permissions object above to be correct.
		updateAPIKey(props.apiKeyID, values.api_key_name, Number(values.api_key_status), props.permissions)
			.then(() => {
				props.setUpdateAPIKeyLoading(false)
				props.setUnsavedChangesShown(false)
				toast.success('API Key updated successfully.')
				navigate(getRouteList().home.developers.path)
			})
			.catch(() => props.setUpdateAPIKeyLoading(false))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<div className="grid grid-cols-2 space-x-6">
					<FormField
						control={form.control}
						name="api_key_name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Name</FormLabel>
								<FormControl>
									<Input placeholder="Super Cool API Key" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="api_key_status"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Status</FormLabel>
								<FormControl>
									<Select onValueChange={field.onChange} defaultValue={field.value}>
										<SelectTrigger>
											<SelectValue placeholder="Status" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="1">Available</SelectItem>
											<SelectItem value="2">Not Available</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				{props.permissions ? (
					<Card className={actualTheme == 'dark' ? 'bg-black' : 'bg-muted'}>
						<CardHeader className="pb-4">
							<CardTitle>Permissions</CardTitle>
							<CardDescription>API Key Permissions</CardDescription>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1" collapsible>
								{Object.keys(permissionsObj).map((service) => (
									<AccordionItem value={service}>
										{/* @ts-ignore (This is correct) */}
										<AccordionTrigger>{services[service]} Permissions</AccordionTrigger>
										<AccordionContent>
											<Card>
												<CardContent>
													{Object.keys(permissionsObj[service].properties).map((permission) => (
														<>
															{permissionsObj[service]['properties'][permission].properties.add_divider &&
																permissionsObj[service]['properties'][permission].properties.add_divider && <Separator className="mt-4" />}
															<FormField
																control={form.control}
																// @ts-ignore
																name={'permissions.' + service + '.' + permission + '.enabled'}
																render={({ field }) => (
																	<FormItem className="flex flex-row items-center justify-between rounded-lg p-4 pb-0 pe-12">
																		<div className="space-y-0.5">
																			<FormLabel className="text-base">
																				{permissionsObj[service]['properties'][permission].properties.permission.const
																					.split('.')[1]
																					.split(/(?=[A-Z])/)
																					.join(' ')
																					.replace('R R Set', 'RRSet')
																					.replace('A P I', 'API')}
																			</FormLabel>
																			<FormDescription>{permissionsObj[service]['properties'][permission].properties.permission.description}</FormDescription>
																		</div>
																		<FormControl>
																			<Switch
																				// @ts-ignore
																				checked={field.value}
																				onCheckedChange={field.onChange}
																				defaultChecked={props.permissions![service][permission]}
																			/>
																		</FormControl>
																	</FormItem>
																)}
															/>
														</>
													))}
												</CardContent>
											</Card>
										</AccordionContent>
									</AccordionItem>
								))}
							</Accordion>
						</CardContent>
					</Card>
				) : (
					<Skeleton className="h-[948px] " />
				)}
			</form>
		</Form>
	)
})

export default EditApiKeyForm
