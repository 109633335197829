import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import DistributionsTable from 'aptranet-ui/components/management-console/cdn/distributions/distributions-table/table.tsx'
import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { ActionButton } from 'aptranet-ui/components/ui/button.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'

import { PlusIcon } from 'lucide-react'

import { listDistributions } from '../../../api/cdn/distributions.tsx'
import { type CDNDistribution } from '../../../api/types/cdn.tsx'
import cdnRoutes from '../../../routes/cdn.tsx'

const Distributions = () => {
	const navigate = useNavigate()
	const [rowSelection, setRowSelection] = useState({})
	const [distributions, setDistributions] = useState<CDNDistribution[] | null>(null)

	const fetchDistributions = () => {
		setDistributions(null)
		listDistributions().then((distributions) => setDistributions(distributions))
	}

	useEffect(() => {
		fetchDistributions()
	}, [])

	return (
		<Content
			title="CDN Distributions"
			subtitle="View and manage your CDN Distributions."
			actionItems={<ActionButton icon={PlusIcon} text="Create Distribution" onClick={() => navigate(cdnRoutes.distributions_create.path)} />}
		>
			{distributions ? (
				<DistributionsTable distributions={distributions} rowSelection={rowSelection} setRowSelection={setRowSelection} refreshData={fetchDistributions} />
			) : (
				<>
					<div className="flex justify-between mb-4">
						<Skeleton className="w-[370px] h-[40px]" />
						<Skeleton className="w-[112px] h-[40px]" />
					</div>

					<Skeleton className="h-72" />
				</>
			)}
		</Content>
	)
}

export default Distributions
