import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { EditAPIKeyForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { listAPIKeyPermissions } from '../../../api/central/developers.tsx'
import { getRouteList } from '../../../routes/routes.tsx'

const EditAPIKey = () => {
	const { apiKeyID } = useParams()
	const navigate = useNavigate()
	const editAPIKeyRef = useRef<HTMLFormElement>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateAPIKeyLoading, setUpdateAPIKeyLoading] = useState(false)

	const [permissions, setPermissions] = useState<{ [key: string]: { [key: string]: boolean } } | null>(null)

	useEffect(() => {
		listAPIKeyPermissions(Number(apiKeyID)).then((permissions) => setPermissions(permissions))
	}, [])

	return (
		<Content title="Edit API Key" subtitle="Edit API Key name, status and permissions.">
			<EditAPIKeyForm
				ref={editAPIKeyRef}
				apiKeyID={Number(apiKeyID)}
				setUnsavedChangesShown={setUnsavedChangesShown}
				setUpdateAPIKeyLoading={setUpdateAPIKeyLoading}
				permissions={permissions}
			/>
			<UnsavedChangesPopup
				shown={unsavedChangesShown}
				saveAction={() => editAPIKeyRef.current?.requestSubmit()}
				cancelAction={() => navigate(getRouteList().home.developers.path)}
				cancelButtonText="Cacnel"
				saveButtonText="Update API Key"
				saveButtonLoading={updateAPIKeyLoading}
			/>
		</Content>
	)
}

export default EditAPIKey
