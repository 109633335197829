import { type Route } from './routes.tsx'
import ShieldComingSoon from '../pages/shield/coming-soon.tsx'

export interface ShieldRoutes {
	base: Route
}

const shieldRoutes: ShieldRoutes = {
	base: {
		path: '/shield',
		element: <ShieldComingSoon />,
		// element: <Navigate to="/shield/protected-resources" />,
	},
}

export default shieldRoutes