import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import AppBar from './navigation/app-bar.tsx'
import Header from './header.tsx'
import NavBar from './navigation/nav-bar.tsx'

import { ClerkLoaded, ClerkProvider } from '@clerk/clerk-react'
import { dark } from '@clerk/themes'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'

import ConsoleLoader from './console-loader.tsx'
import { Toaster } from 'aptranet-ui/components/ui/sonner.tsx'

const Layout = () => {
	const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

	if (!PUBLISHABLE_KEY) {
		throw new Error('Missing Publishable Key')
	}

	const { actualTheme } = useTheme()
	const { pathname } = useLocation()

	const navigate = useNavigate()

	// @ts-ignore
	return <ClerkProvider publishableKey={PUBLISHABLE_KEY}
												routerPush={(to: string) => navigate(to)}
												signInUrl="/auth/login"
												signUpUrl="/auth/register"
												supportEmail="support@aptranet.com"
												telemetry={false}
												appearance={{
													baseTheme: actualTheme === 'dark' ? dark : undefined,
													layout: {
														logoImageUrl: 'https://assets.aptranetedge.com/logos/' + (actualTheme === 'dark' ? 'logo-white.svg' : 'logo.svg'),
														socialButtonsPlacement: 'bottom',
														termsPageUrl: 'https://aptranet.com/terms',
														privacyPageUrl: 'https://aptranet.com/privacy',
													},
													elements: {
														logoImage: 'h-7',
													},
												}}>
		<ConsoleLoader />
		<ClerkLoaded>
			{!pathname.startsWith('/auth/login') && !pathname.startsWith('/auth/register') && pathname !== '/projects' && pathname !== '/redirect/projects' ?
				<div className="grid h-full w-full md:pl-[56px]">
					<Header />

					{localStorage.getItem('selected_project_id') ? (
						<>
							<AppBar />
							<div className="flex md:gap-5">
								<NavBar />
								<Outlet />
							</div>
						</>
					) : <Navigate to="/projects" />}
				</div> : <Outlet />}
			<Toaster richColors />
		</ClerkLoaded>
	</ClerkProvider>
}

export default Layout