import requestInterceptor from '../interceptor.tsx'
import { type CDNDistribution, type CreateDistribution, DistributionAnalyticsDatapoint } from '../types/cdn.tsx'

export const listDistributions = (limit?: number): Promise<CDNDistribution[]> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/distributions', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					limit: limit,
				},
			})
			.then((res) => (res.status === 204 ? resolve([]) : resolve(res.data)))
			.catch((err) => reject(err.response.data))
	)

export const createDistribution = (distribution: CreateDistribution): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.post('/cdn/distributions', distribution, {
				params: {
					project: localStorage.getItem('selected_project_id'),
				},
			})
			.then((res) => resolve(res.status === 201))
			.catch((err) => reject(err.response.data))
	)

export const deleteDistribution = (distributionID: number): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.delete('/cdn/distributions', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID
				},
			})
			.then((res) => resolve(res.status === 201))
			.catch((err) => reject(err.response.data))
	)

export const getDistributionAnalytics = (distributionID: number): Promise<DistributionAnalyticsDatapoint[]> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/distributions/analytics', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					distribution: distributionID
				},
			})
			.then((res) => resolve(res.status === 204 ? [] : res.data))
			.catch((err) => reject(err.response.data))
	)
