import { Link } from 'react-router-dom'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import Head from '../../layout/head.tsx'

const light404 = () => (
	<>
		<Head title="Page Not Found" />
		<div className="flex flex-col items-center justify-center h-[100dvh] px-4 md:px-6">
			<div className="flex justify-center mb-9">
				<img src="https://assets.aptranetedge.com/logos/logo.svg" alt="Aptranet Logo" />
			</div>
			<div className="space-y-4 text-center mb-28">
				<h1 className="text-6xl font-bold tracking-tighter md:text-8xl">404</h1>
				<div className="space-y-2">
					<h2 className="text-3xl font-bold tracking-tighter md:text-4xl">Page not found</h2>
					<p className="text-gray-500 dark:text-gray-400 md:text-xl">
						The page you are looking for does not exist or has been moved.
					</p>
				</div>
				<Link
					className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
					to="/"
				>
					Go back home
				</Link>
			</div>
		</div>
	</>
)

const dark404 = () => (
	<>
		<Head title="Page Not Found" />
		<div className="flex flex-col items-center justify-center h-[100dvh] px-4 md:px-6 bg-gray-950 text-gray-50">
			<div className="flex justify-center mb-9">
				<img src="https://assets.aptranetedge.com/logos/logo-white.svg" alt="Aptranet Logo" />
			</div>
			<div className="space-y-4 text-center mb-28">
				<h1 className="text-6xl font-bold tracking-tighter md:text-8xl">404</h1>
				<div className="space-y-2">
					<h2 className="text-3xl font-bold tracking-tighter md:text-4xl">Page not found</h2>
					<p className="text-gray-400 md:text-xl">The page you are looking for does not exist or has been moved.</p>
				</div>
				<Link
					className="inline-flex h-10 items-center justify-center rounded-md bg-gray-50 px-8 text-sm font-medium text-gray-900 shadow transition-colors hover:bg-gray-200 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-300 disabled:pointer-events-none disabled:opacity-50"
					to="/"
				>
					Go back home
				</Link>
			</div>
		</div>
	</>
)

export default function Error404() {
	const { actualTheme } = useTheme()
	return actualTheme === 'dark' ? dark404() : light404()
}