import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { NetworkConfigurationForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getDistributionNetworkConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { type NetworkConfiguration } from '../../../../api/types/cdn.tsx'

const NetworkConfiguration = () => {
	const { distributionID } = useParams()
	const networkConfigurationRef = useRef<HTMLFormElement>(null)

	const [networkConfigurationData, setNetworkConfigurationData] = useState<NetworkConfiguration | null>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateNetworkConfigurationLoading, setUpdateNetworkConfigurationLoading] = useState<boolean>(false)
	const [resetForm, setResetForm] = useState<{ run: () => void }>({ run: () => undefined })

	const fetchNetworkConfiguration = () => {
		setNetworkConfigurationData(null)
		getDistributionNetworkConfiguration(Number(distributionID)).then((networkConfiguration) => setNetworkConfigurationData(networkConfiguration))
	}

	useEffect(() => {
		fetchNetworkConfiguration()
	}, [])

	return (
		<Content title="Network Configuration" subtitle="Manage your Distribution's Network Configuration.">
			{networkConfigurationData ? (
				<>
					<NetworkConfigurationForm
						ref={networkConfigurationRef}
						distributionID={Number(distributionID)}
						networkConfigurationData={networkConfigurationData!}
						setResetForm={setResetForm}
						setUnsavedChangesShown={setUnsavedChangesShown}
						setUpdateNetworkConfigurationLoading={setUpdateNetworkConfigurationLoading}
						refreshNetworkConfigurationData={fetchNetworkConfiguration}
					/>
					<UnsavedChangesPopup
						shown={unsavedChangesShown}
						saveAction={() => networkConfigurationRef.current?.requestSubmit()}
						cancelAction={() => resetForm.run()}
						cancelButtonText="Discard Changes"
						saveButtonText="Update Network Configuration"
						saveButtonLoading={updateNetworkConfigurationLoading}
					/>
				</>
			) : (
				<div className="flex flex-col items-center gap-3">
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-36" />
					<Skeleton className="w-full max-w-4xl h-36" />
				</div>
			)}
		</Content>
	)
}

export default NetworkConfiguration
