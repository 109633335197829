import { type Route } from './routes.tsx'

import Services from '../pages/billing/services.tsx'
import { Navigate } from 'react-router-dom'
import BillingInformation from '../pages/billing/information.tsx'

export interface BillingRoutes {
	base: Route,
	services: Route,
	billing_information: Route
}

const billingRoutes: BillingRoutes = {
	base: {
		path: '/billing',
		element: <Navigate to="/billing/services" />,
	},
	services: {
		path: '/billing/services',
		element: <Services />,
	},
	billing_information: {
		path: '/billing/information',
		element: <BillingInformation />
	}
}

export default billingRoutes