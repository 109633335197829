import { type RowSelectionState } from '@tanstack/react-table'
import { type Dispatch, type SetStateAction, useState } from 'react'

import {
	distributionsTableColumns,
} from 'aptranet-ui/components/management-console/cdn/distributions/distributions-table/columns.tsx'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { DataTable } from 'aptranet-ui/components/ui/data-table.tsx'

import { type CDNDistribution } from '../../../../../../api/types/cdn.tsx'
import DeleteDistributionModal
	from 'aptranet-ui/components/management-console/cdn/distributions/delete-distribution-modal.tsx'

interface DistributionsTableProps {
	distributions: CDNDistribution[]
	refreshData: () => void
	rowSelection: RowSelectionState
	setRowSelection: Dispatch<SetStateAction<object>>
}

const DistributionsTable = (props: DistributionsTableProps) => {
	const theme = useTheme()
	const [deleteDistributionModalOpen, setDeleteDistributionModalOpen] = useState<boolean>(false)
	const [deleteDistributionModalData, setDeleteDistributionModalData] = useState<CDNDistribution>({
		id: 0,
		name: '',
		status: "active",
		hostname: "",
		created_at: "",
		last_modified_at: "",
		origin_group: {
			id: 0,
			name: '',
			connected_distributions_count: 1,
			use_next_upstream: false,
			next_upstream_cases: {
				on_error: false,
				on_timeout: false,
				on_invalid_header: false,
				on_http_403: false,
				on_http_404: false,
				on_http_429: false,
				on_http_500: false,
				on_http_502: false,
				on_http_503: false,
				on_http_504: false,
			}
		},
		ssl_active: false
	})

	const deleteDistribution = (distribution: CDNDistribution) => {
		setDeleteDistributionModalOpen(true)
		setDeleteDistributionModalData(distribution)
	}

	return (
		<>
			<DeleteDistributionModal open={deleteDistributionModalOpen} setOpen={setDeleteDistributionModalOpen}
															 distribution={deleteDistributionModalData} refreshData={props.refreshData} />
			<div className="pt-2">
				<DataTable
					columns={distributionsTableColumns(theme, deleteDistribution)}
					data={props.distributions}
					searchBy="name"
					searchByTitle="Distribution Name"
					enableRowSelection={true}
					rowSelection={props.rowSelection}
					setRowSelection={props.setRowSelection}
				/>
			</div>
		</>
	)
}

export default DistributionsTable
