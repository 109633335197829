import requestInterceptor from '../interceptor.tsx'

import { type Project } from '../types/central.ts'
import { type Permissions } from './developers.tsx'

export const listProjects = (limit?: number): Promise<Project[]> => new Promise((resolve, reject) =>
	requestInterceptor
		.get('/central/projects', {
			params: {
				limit: limit,
			},
		})
		.then((res) => res.status === 204 ? resolve([]) : resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const createProject = (name: string, description: string): Promise<number> => new Promise((resolve, reject) =>
	requestInterceptor
		.post('/central/projects', {
			name: name,
			description: description,
		})
		.then((res) => resolve(res.data.project_id))
		.catch((err) => reject(err.response.data)),
)

export const updateProjectSettings = (name: string, description: string): Promise<boolean> => new Promise((resolve, reject) =>
	requestInterceptor
		.patch('/central/projects', {
			name: name,
			description: description,
		}, {
			params: {
				project: localStorage.getItem('selected_project_id'),
			},
		})
		.then((res) => resolve(res.status === 200))
		.catch((err) => reject(err.response.data)),
)

export const listMemberPermissions = (userID: string): Promise<Permissions> => new Promise((resolve, reject) =>
	requestInterceptor
		.get('/central/projects/member_permissions', {
			params: {
				project: localStorage.getItem('selected_project_id'),
				user_id: userID,
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const updateMemberPermissions = (userID: string, permissions: Permissions): Promise<boolean> => new Promise((resolve, reject) =>
	requestInterceptor
		.put('/central/projects/member_permissions', {
			user_id: userID,
			permissions: permissions,
		}, {
			params: {
				project: localStorage.getItem('selected_project_id'),
			},
		})
		.then((res) => resolve(res.status === 200))
		.catch((err) => reject(err.response.data)),
)