import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { OriginGroupForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getRouteList } from '../../../routes/routes.tsx'

const CreateOriginGroup = () => {
	const navigate = useNavigate()
	const createOriginGroupRef = useRef<HTMLFormElement>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [createOriginGroupLoading, setCreateOriginGroupLoading] = useState<boolean>(false)

	return (
		<Content title="Create Origin Group" subtitle="Create a new origin group.">
			<OriginGroupForm ref={createOriginGroupRef} setUnsavedChangesShown={setUnsavedChangesShown} setCreateOriginGroupLoading={setCreateOriginGroupLoading} />

			<UnsavedChangesPopup
				shown={unsavedChangesShown}
				saveAction={() => createOriginGroupRef.current?.requestSubmit()}
				cancelAction={() => navigate(getRouteList().cdn.origin_groups.path)}
				cancelButtonText="Cacnel"
				saveButtonText="Create Origin Group"
				saveButtonLoading={createOriginGroupLoading}
			/>
		</Content>
	)
}

export default CreateOriginGroup
