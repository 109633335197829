import { type DynamicRoute, type Route } from './routes.tsx'

import Quotas from '../pages/home/quotas.tsx'
import Settings from '../pages/home/settings.tsx'

import ProjectMembers from '../pages/home/project-members/project-members.tsx'
import ProjectMemberPermissionsEdit from '../pages/home/project-members/project-member-permissions-edit.tsx'

import Developers from '../pages/home/developers/developers.tsx'
import CreateAPIKey from '../pages/home/developers/create-api-key.tsx'
import EditApiKey from '../pages/home/developers/edit-api-key.tsx'

import Climate from '../pages/home/climate.tsx'

export interface HomeRoutes {
	quotas: Route,
	settings: Route,

	members: Route,
	members_edit_member_permissions: DynamicRoute,

	developers: Route,
	developers_create_api_key: Route,
	developers_edit_api_key_permissions: DynamicRoute,

	climate: Route,
}

const homeRoutes: HomeRoutes = {
	// Homepage and Projects page are declared as index in the App.tsx file.
	quotas: {
		path: '/quotas',
		element: <Quotas />,
	},
	settings: {
		path: '/settings',
		element: <Settings />,
	},
	members: {
		path: '/members',
		element: <ProjectMembers />,
	},
	members_edit_member_permissions: {
		path: '/members/:userID/permissions/edit',
		element: <ProjectMemberPermissionsEdit />,
		return_dynamic_path: (userID) => '/members/' + userID + '/permissions/edit',
	},
	developers: {
		path: '/developers',
		element: <Developers />,
	},
	developers_create_api_key: {
		path: '/developers/create-api-key',
		element: <CreateAPIKey />,
	},
	developers_edit_api_key_permissions: {
		path: '/developers/api-key/:apiKeyID/edit',
		element: <EditApiKey />,
		return_dynamic_path: (apiKeyID) => '/developers/api-key/' + apiKeyID + '/edit',
	},
	climate: {
		path: '/climate',
		element: <Climate />,
	},
}

export default homeRoutes