import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import * as React from 'react'

import { aptranetUIMerge } from 'aptranet-ui/lib/utils'

import { Circle } from 'lucide-react'

const RadioGroup = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>>(
	({ className, ...props }, ref) => {
		return <RadioGroupPrimitive.Root className={aptranetUIMerge('grid gap-2', className)} {...props} ref={ref} />
	}
)
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>>(
	({ className, ...props }, ref) => {
		return (
			<RadioGroupPrimitive.Item
				ref={ref}
				className={aptranetUIMerge(
					'aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
					className
				)}
				{...props}
			>
				<RadioGroupPrimitive.Indicator className="flex items-center justify-center">
					<Circle className="h-2.5 w-2.5 fill-current text-current" />
				</RadioGroupPrimitive.Indicator>
			</RadioGroupPrimitive.Item>
		)
	}
)
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
