import { type ReactNode } from 'react'
import homeRoutes, { type HomeRoutes } from './home.tsx'
import cdnRoutes, { type CDNRoutes } from './cdn.tsx'
import billingRoutes, { type BillingRoutes } from './billing.tsx'
import shieldRoutes, { type ShieldRoutes } from './shield.tsx'

export interface Route {
	path: string,
	element: ReactNode,
}

export interface DynamicRoute {
	path: string,
	element: ReactNode,
	return_dynamic_path: (variable: string | number) => string
}

type Routes = { home: HomeRoutes, cdn: CDNRoutes, shield: ShieldRoutes, billing: BillingRoutes }

//This is a function to prevent cannot access before initialization error.
export const getRouteList = (): Routes => ({
	home: homeRoutes,
	cdn: cdnRoutes,
	shield: shieldRoutes,
	billing: billingRoutes
})