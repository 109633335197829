// import { Checkbox } from 'aptranet-ui/components/ui/checkbox.tsx'
import { type ColumnDef } from '@tanstack/react-table'
import { type CDNDistribution } from '../../../../../../api/types/cdn.tsx'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon, Loader2, MoreHorizontalIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { getRouteList } from '../../../../../../routes/routes.tsx'
import { type ThemeProviderState } from 'aptranet-ui/components/theme/theme-provider.tsx'
import {
	DropdownMenu,
	DropdownMenuContent, DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from 'aptranet-ui/components/ui/dropdown-menu.tsx'
import { Tooltip, TooltipProvider, TooltipTrigger } from 'aptranet-ui/components/ui/tooltip.tsx'

export const distributionsTableColumns = (theme: ThemeProviderState, deleteDistribution: (distribution: CDNDistribution) => void): ColumnDef<CDNDistribution>[] => {
	return [
		// {
		// 	id: 'select',
		// 	header: ({ table }) => (
		// 		<Checkbox
		// 			checked={
		// 				table.getIsAllPageRowsSelected() ||
		// 				(table.getIsSomePageRowsSelected() && 'indeterminate')
		// 			}
		// 			onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
		// 			aria-label="Select all"
		// 		/>
		// 	),
		// 	cell: ({ row }) => (
		// 		<Checkbox
		// 			checked={row.getIsSelected()}
		// 			onCheckedChange={(value) => row.toggleSelected(!!value)}
		// 			aria-label="Select row"
		// 			disabled={!row.getCanSelect()}
		// 		/>
		// 	),
		// 	enableSorting: false,
		// 	enableHiding: false,
		// },
		{
			accessorKey: 'id',
			header: () => <p className="ms-2">ID</p>,
			// header: 'ID',
			cell: ({ row }) => (<div className="ms-2">{row.original.id}</div>)
		},
		{
			accessorKey: 'name',
			header: ({ column }) => {
				return (
					<Button
						size="sm"
						variant="link"
						className="ps-0 text-muted-foreground"
						onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
					>
						Name
						{column.getIsSorted() === 'asc' ?
							<ArrowUpIcon className="ml-2 h-4 w-4" /> : column.getIsSorted() === 'desc' ?
								<ArrowDownIcon className="ml-2 h-4 w-4" /> : <ArrowUpDown className="ml-2 h-4 w-4" />}
					</Button>
				)
			},
			cell: ({ row }) => row.original.status === 'active' ? <Button size="sm" variant="link"
																																		className={theme.actualTheme === 'light' ? 'p-0 text-blue-900 hover:text-blue-500 underline' : 'p-0 text-blue-600 hover:text-blue-400 underline'}
																																		asChild><Link
				to={getRouteList().cdn.distribution_analytics.return_dynamic_path(row.original.id)}>{row.getValue('name')}</Link></Button> : row.getValue('name'),
		},
		{
			accessorKey: 'hostname',
			header: 'Hostname',
			cell: ({ row }) => (
				<Button size="sm" variant="link" className="p-0" asChild><a
					href={(row.original.ssl_active ? 'https://' : 'http://') + row.getValue('hostname')}
					target="_blank">{row.getValue('hostname')}</a></Button>),
		},
		{
			accessorKey: 'origin_group',
			header: 'Origin Group',
			cell: ({ row }) => <Button size="sm" variant="link"
																 className={theme.actualTheme === 'light' ? 'p-0 text-blue-900 hover:text-blue-500 underline' : 'p-0 text-blue-600 hover:text-blue-400 underline'}
																 asChild><Link
				to={getRouteList().cdn.origin_group_edit.return_dynamic_path(row.original.origin_group.id)}>{row.original.origin_group.name}</Link></Button>,
		},
		{
			accessorKey: 'created_at',
			header: 'Created At',
			cell: ({ row }) => ((new Date(row.getValue('created_at')).toLocaleDateString('en-GB', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			}))),
		},
		{
			accessorKey: 'last_modified_at',
			header: 'Modified At',
			cell: ({ row }) => ((new Date(row.getValue('last_modified_at')).toLocaleDateString('en-GB', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			}))),
		},
		{
			accessorKey: 'status',
			header: 'Status',
			cell: ({ row }) => (row.getValue('status') === 'active' ?
				<Badge variant="success">Active</Badge> : row.getValue('status') === 'inactive' ?
					<Badge variant="warning">Inactive</Badge> : row.getValue('status') === 'provisioning' ?
						<Badge variant="primary"><Loader2
							className="me-0.5 h-4 w-4 animate-spin" /> Provisioning</Badge> : row.getValue('status') === 'suspended' ?
							<Badge variant="destructive">Suspended</Badge> : <Badge variant="secondary">Deleted</Badge>),
		},
		{
			accessorKey: 'ssl_active',
			header: 'SSL',
			cell: ({ row }) => (row.getValue('ssl_active') === true && <Badge variant="success">Secured</Badge>),
		},
		{
			id: 'actions',
			enableHiding: false,
			cell: ({ row }) => {
				const distribution = row.original
				// const navigate = useNavigate()

				return (
					<DropdownMenu>
						<DropdownMenuTrigger asChild disabled={row.original.status !== "active"}>
							<Button variant="ghost" className="h-8 w-8 p-0">
								<span className="sr-only">Open menu</span>
								<MoreHorizontalIcon className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
						{/*	<DropdownMenuLabel>Actions</DropdownMenuLabel>*/}
						{/*	<DropdownMenuItem*/}
						{/*		onClick={() => navigate(getRouteList().cdn.origin_group_edit.return_dynamic_path(distribution.id))}*/}
						{/*		className="pe-5 cursor-pointer"*/}
						{/*	>*/}
						{/*		Purge*/}
						{/*	</DropdownMenuItem>*/}
						{/*	<DropdownMenuItem*/}
						{/*		onClick={() => navigate(getRouteList().cdn.origin_group_edit.return_dynamic_path(distribution.id))}*/}
						{/*		className="pe-5 cursor-pointer"*/}
						{/*	>*/}
						{/*		Prefetch*/}
						{/*	</DropdownMenuItem>*/}

						{/*	<DropdownMenuSeparator/>*/}
							<DropdownMenuLabel>Danger Zone</DropdownMenuLabel>

							{/*<DropdownMenuItem*/}
							{/*	onClick={() => navigate(getRouteList().cdn.origin_group_edit.return_dynamic_path(distribution.id))}*/}
							{/*	className="pe-5 cursor-pointer"*/}
							{/*>*/}
							{/*	Suspend*/}
							{/*</DropdownMenuItem>*/}

							<TooltipProvider>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<div>
											{/* We have this div here cause when the button is disabled the Tooltip does not work. */}
											<DropdownMenuItem
												onClick={() => deleteDistribution(distribution)}
												className="bg-destructive text-destructive-foreground mt-1 cursor-pointer"
												// disabled={distribution.connected_distributions_count > 0}
											>
												Delete
											</DropdownMenuItem>
										</div>
									</TooltipTrigger>
									{/*{originGroup.connected_distributions_count > 0 && <TooltipContent>There are connected Distributions to this Origin Group.</TooltipContent>}*/}
								</Tooltip>
							</TooltipProvider>
						</DropdownMenuContent>
					</DropdownMenu>
				)
			},
		},
	]
}