import { AppPortal } from 'svix-react'
import { useEffect, useState } from 'react'
import { createWebhooksPortalAccess, type CreateWebhooksPortalAccessResult } from '../../../api/central/developers.tsx'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { Card } from 'aptranet-ui/components/ui/card.tsx'

const Webhooks = () => {
	const [appPortal, setAppPortal] = useState<CreateWebhooksPortalAccessResult | null>(null)
	const theme = useTheme()

	useEffect(() => {
		createWebhooksPortalAccess().then(portal => setAppPortal(portal))
	}, [])

	return appPortal ? <Card><AppPortal url={appPortal.portal_url} fullSize darkMode={theme.actualTheme === 'dark'} /></Card> :
		<Skeleton className="h-40" />
}

export default Webhooks