import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { CreateAPIKeyForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getRouteList } from '../../../routes/routes.tsx'

const CreateAPIKey = () => {
	const navigate = useNavigate()
	const createAPIKeyRef = useRef<HTMLFormElement>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [createAPIKeyLoading, setCreateAPIKeyLoading] = useState(false)

	return (
		<Content title="Create API Key" subtitle="Create a new API Key for this project.">
			<CreateAPIKeyForm ref={createAPIKeyRef} setUnsavedChangesShown={setUnsavedChangesShown} setCreateAPIKeyLoading={setCreateAPIKeyLoading} />
			<UnsavedChangesPopup
				shown={unsavedChangesShown}
				saveAction={() => createAPIKeyRef.current?.requestSubmit()}
				cancelAction={() => navigate(getRouteList().home.developers.path)}
				cancelButtonText="Cacnel"
				saveButtonText="Create API Key"
				saveButtonLoading={createAPIKeyLoading}
			/>
		</Content>
	)
}

export default CreateAPIKey
