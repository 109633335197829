import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { z } from 'zod'

import { type Dispatch, type ReactNode, type SetStateAction, forwardRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'aptranet-ui/components/ui/form'
import { Input } from 'aptranet-ui/components/ui/input'
import { Textarea } from 'aptranet-ui/components/ui/textarea.tsx'

import { createProject } from '../../../api/central/projects.tsx'

interface createProjectProps {
	setCreateProjectLoading: Dispatch<SetStateAction<boolean>>
}

const formSchema = z.object({
	name: z.string().min(4).max(64),
	description: z.string().min(0).max(200),
})

const CreateProjectForm = forwardRef<HTMLFormElement, createProjectProps>((props, ref): ReactNode => {
	const navigate = useNavigate()
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: '',
			description: '',
		},
	})

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setCreateProjectLoading(true)
		createProject(values.name, values.description)
			.then((projectID) => {
				localStorage.setItem('selected_project_id', projectID.toString())
				localStorage.setItem('selected_project_name', values.name)
				localStorage.setItem('selected_project_description', values.description)
				props.setCreateProjectLoading(false)
				toast.success('Project created successfully.')
				navigate('/')
			})
			.catch(() => props.setCreateProjectLoading(false))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<FormField
					control={form.control}
					name="name"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Project Name</FormLabel>
							<FormControl>
								<Input placeholder="My Awesome Project" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="description"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Project Description</FormLabel>
							<FormControl>
								<Textarea placeholder="Just a cool project." {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</form>
		</Form>
	)
})

export default CreateProjectForm
