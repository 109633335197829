import { ColumnDef } from '@tanstack/react-table'

import { Button } from 'aptranet-ui/components/ui/button.tsx'
// import { Progress } from 'aptranet-ui/components/ui/progress.tsx'
import { Checkbox } from 'aptranet-ui/components/ui/checkbox'
// import { calcPercentage } from 'aptranet-ui/lib/utils.ts'

import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon } from 'lucide-react'


export interface Quota {
	id: string,
	name: string,
	value: number | boolean,
	default_value: number | boolean,
	unit?: string,
	adjustable: boolean
	utilization?: number
}

export const aptranetQuotasTableColumns: ColumnDef<Quota>[] = [
	{
		id: 'select',
		header: ({ table }) => (
			<Checkbox
				checked={
					table.getIsAllPageRowsSelected() ||
					(table.getIsSomePageRowsSelected() && 'indeterminate')
				}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
				disabled={!row.getCanSelect()}
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'name',
		header: ({ column }) => {
			return (
				<Button
					size="sm"
					variant="link"
					className="ps-0 text-muted-foreground"
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Name
					{column.getIsSorted() === 'asc' ? <ArrowUpIcon className="ml-2 h-4 w-4" /> : column.getIsSorted() === 'desc' ?
						<ArrowDownIcon className="ml-2 h-4 w-4" /> : <ArrowUpDown className="ml-2 h-4 w-4" />}
				</Button>
			)
		},
	},
	{
		accessorKey: 'value',
		header: 'Value',
		cell: ({ row }) => row.getValue('value') === -1 ? 'No Limit' : new Intl.NumberFormat('en-US').format(parseFloat(row.getValue('value'))) + ' ' + (row.getValue('unit') ?? ''),
	},
	{
		accessorKey: 'default_value',
		header: 'Default Value',
		cell: ({ row }) => row.getValue('default_value') === -1 ? 'No Limit' : new Intl.NumberFormat('en-US').format(parseFloat(row.getValue('default_value'))) + ' ' + (row.getValue('unit') ?? ''),
	},
	{
		accessorKey: 'unit',
		header: 'Unit',
		enableHiding: false, //To hide the option from the columns menu
	},
	{
		accessorKey: 'adjustable',
		header: 'Adjustable',
		cell: ({ row }) => (row.getValue('adjustable') ? 'Yes' : 'No'),
	},
	// {
	// 	accessorKey: 'utilization',
	// 	header: 'Utilization',
	// 	cell: ({ row }) => (row.getValue('utilization') ?
	// 		<Progress value={calcPercentage(row.getValue('utilization'), row.getValue('value'))}
	// 							className="w-[60%]" /> : 'Unavailable'),
	// },
]
