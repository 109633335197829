import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Avatar, AvatarImage } from 'aptranet-ui/components/ui/avatar.tsx'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'
import { Card, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { EditProjectMemberPermissionsForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { listMemberPermissions } from '../../../api/central/projects.tsx'
import { getRouteList } from '../../../routes/routes.tsx'

const ProjectMemberPermissionsEdit = () => {
	const { userID } = useParams()
	if (!userID) {
		return <p>Invalid User ID</p>
	}

	const { state } = useLocation()
	const navigate = useNavigate()

	const updateMemberPermissionsRef = useRef<HTMLFormElement>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updatePermissionsLoading, setUpdatePermissionsLoading] = useState(false)

	const [permissions, setPermissions] = useState<{ [key: string]: { [key: string]: boolean } } | null>(null)

	useEffect(() => {
		listMemberPermissions(userID).then((permissions) => setPermissions(permissions))
	}, [])

	return (
		<Content title="Member Permissions" subtitle="Edit the member's permissions for this project.">
			<Card className="mb-2">
				<CardHeader>
					<div className="flex items-center">
						<Avatar>
							<AvatarImage src={state.member_image_url} />
						</Avatar>
						<CardTitle className="mx-2">{state.member_name}</CardTitle>
						{
							{
								'org:admin': <Badge className="mt-1">Admin</Badge>,
								'org:member': (
									<Badge variant="secondary" className="mt-1">
										Member
									</Badge>
								),
								'org:billing_manager': (
									<Badge variant="secondary" className="mt-1">
										Billing Manager
									</Badge>
								),
								'org:member_plus_billing_manager': (
									<Badge variant="secondary" className="mt-1">
										Member + Billing Manager
									</Badge>
								),
							}[state.member_role as string]
						}
					</div>
				</CardHeader>
			</Card>
			<EditProjectMemberPermissionsForm
				ref={updateMemberPermissionsRef}
				userID={userID}
				setUnsavedChangesShown={setUnsavedChangesShown}
				setUpdatePermissionsLoading={setUpdatePermissionsLoading}
				permissions={permissions}
			/>
			<UnsavedChangesPopup
				shown={unsavedChangesShown}
				saveAction={() => updateMemberPermissionsRef.current?.requestSubmit()}
				cancelAction={() => navigate(getRouteList().home.members.path)}
				cancelButtonText="Cacnel"
				saveButtonText="Update Permissions"
				saveButtonLoading={updatePermissionsLoading}
			/>
		</Content>
	)
}

export default ProjectMemberPermissionsEdit
