import { OrganizationSwitcher, UserButton, useAuth } from '@clerk/clerk-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ProjectSelector } from 'aptranet-ui/components/management-console/generic/project-selector.tsx'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Separator } from 'aptranet-ui/components/ui/separator.tsx'

interface HeaderProps {
	hideProjectSelector?: boolean
	hideProductName?: boolean
	showAptranetLogo?: boolean
}

const Header = (props: HeaderProps) => {
	const auth = useAuth()
	const navigate = useNavigate()

	const { actualTheme } = useTheme()
	const { pathname } = useLocation()

	const [productText, setProductText] = useState('Console Home')

	useEffect(() => {
		if (!auth.isSignedIn) {
			navigate('/auth/login', { state: { route_to: pathname } })
		}
	}, [auth.isSignedIn])

	useEffect(() => {
		switch (pathname.split('/')[1]) {
			case 'cdn':
				setProductText('CDN')
				break
			case 'storage':
				setProductText('Storage')
				break
			case 'static-sites':
				setProductText('Static Sites')
				break
			case 'api-gateway':
				setProductText('API Gateway')
				break
			case 'compute':
				setProductText('Compute')
				break
			case 'intelliedge':
				setProductText('IntelliEdge')
				break
			case 'dns':
				setProductText('DNS')
				break
			case 'streaming':
				setProductText('Streaming')
				break
			case 'shield':
				setProductText('Shield')
				break
			case 'tls-manager':
				setProductText('TLS Manager')
				break
			case 'logging':
				setProductText('Logging')
				break
			case 'billing':
				setProductText('Billing')
				break
			default:
				setProductText('Console Home')
				break
		}
	}, [pathname])

	return (
		<header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background px-4">
			{props.showAptranetLogo && (
				<div className="flex me-2">
					<img src={'https://assets.aptranetedge.com/logos/' + (actualTheme === 'dark' ? 'logo-white.svg' : 'logo.svg')} alt="Aptranet Logo" className="w-32" />
				</div>
			)}
			{!props.hideProjectSelector && (
				<>
					<div className="me-2.5">
						<p className="text-xs">Aptranet</p>
						<h3 className="text-md font-semibold">{productText}</h3>
					</div>
				</>
			)}
			<Separator orientation="vertical" />
			<div className="ms-2.5">
				<OrganizationSwitcher
					appearance={{
						elements: {
							avatarImage: 'h-9 w-9',
							avatarBox: 'h-9 w-9',
							userPreviewAvatarContainer: 'w-5',
						},
					}}
					afterSelectOrganizationUrl="/redirect/projects"
					afterSelectPersonalUrl="/redirect/projects"
				/>
			</div>
			{!props.hideProjectSelector && (
				<>
					<Separator orientation="vertical" />
					<div className="ms-2.5">
						<ProjectSelector />
					</div>
				</>
			)}
			<div className="ml-auto flex flex-column justify-center">
				<UserButton
					showName
					appearance={{
						elements: {
							userButtonBox: 'flex-row-reverse gap-1.5 whitespace-nowrap rounded-md border border-input py-1 px-2',
							userButtonOuterIdentifier: 'ps-0',
						},
					}}
					signInUrl={import.meta.env.VITE_SIGN_IN_URL}
					afterMultiSessionSingleSignOutUrl={window.location.origin}
					afterSwitchSessionUrl={window.location.origin}
					afterSignOutUrl={window.location.origin + '/auth/login'}
				/>
			</div>
		</header>
	)
}

export default Header
