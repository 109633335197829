import requestInterceptor from '../interceptor.tsx'
import { type ActiveServices, type AptranetService, type BillingPortal, type BillingSession } from '../types/central.ts'

export const getActiveServices = () => new Promise<ActiveServices>((resolve, reject) =>
	requestInterceptor
		.get('/central/billing/active_services')
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const createSession = (service: AptranetService) => new Promise<BillingSession>((resolve, reject) =>
	requestInterceptor
		.post('/central/billing/session', {
			service: service
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const createPortal = () => new Promise<BillingPortal>((resolve, reject) =>
	requestInterceptor
		.post('/central/billing/portal')
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)