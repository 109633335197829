import { Drawer, DrawerContent } from 'aptranet-ui/components/ui/drawer'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'

import { useAuth } from '@clerk/clerk-react'
import { useEffect, useState } from 'react'

import Lottie from 'react-lottie'
import loadingBarData from '../assets/animations/loading-bar.json'

const ConsoleLoader = () => {
	const auth = useAuth()
	const { actualTheme } = useTheme()

	const [open, setOpen] = useState(true)

	useEffect(() => {
		if (auth.isLoaded) {
			setTimeout(() => setOpen(false), 1000)
		}
	}, [auth.isLoaded])

	return <Drawer open={open} dismissible={false}>
		<DrawerContent className="h-[96%]" hideGrabBar>
			<div className="h-full flex justify-center items-center">
				<div className="w-64 flex flex-col justify-center mb-14">
					<img
						src={'https://assets.aptranetedge.com/logos/' + (actualTheme === 'dark' ? 'logo-white.svg' : 'logo.svg')}
						alt="Aptranet Logo" className="px-2" />
					<p className="text-center font-semibold mt-4 mb-2">Management Console is loading...</p>
					<div className="pointer-events-none">
						<Lottie options={{
							loop: true,
							autoplay: true,
							animationData: loadingBarData,
						}} />
					</div>
				</div>
			</div>
		</DrawerContent>
	</Drawer>

}

export default ConsoleLoader