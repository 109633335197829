import RequestsChart from 'aptranet-ui/components/management-console/cdn/charts/distributions/requests-chart.tsx'
import TrafficChart from 'aptranet-ui/components/management-console/cdn/charts/distributions/traffic-chart.tsx'
import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { useEffect, useState } from 'react'
import { getDistributionAnalytics } from '../../../api/cdn/distributions.tsx'
import { useParams } from 'react-router-dom'
import { DistributionAnalyticsDatapoint } from '../../../api/types/cdn.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import ContentUnavailable from 'aptranet-ui/components/management-console/generic/content-unavailable.tsx'

const DistributionAnalytics = () => {
	const { distributionID } = useParams()
	const [analyticsData, setAnalyticsData] = useState<DistributionAnalyticsDatapoint[] | null>(null)

	useEffect(() => {
		getDistributionAnalytics(Number(distributionID)).then(analyticsData => setAnalyticsData(analyticsData))
	}, [])

	return (
		<Content title="Distribution Analytics" subtitle="View analytics for your Distribution." isBeta>
			{analyticsData ? analyticsData.length > 0 ? (
						<div className="grid lg:grid-cols-2 xl:grid-cols-2 gap-2">
							<TrafficChart data={analyticsData} />
							<RequestsChart data={analyticsData} />
						</div>
					) :
					<div className="flex justify-center">
						<div className="w-1/2">
							<ContentUnavailable
								title="Insights will be available shortly"
								description="There's no data to show just yet, but don’t worry, your CDN Distribution is all set up, and analytics will start rolling in soon!"
								imageURL="https://assets.aptranetedge.com/management-console/images/graphics/analytics-data.svg"
								imageAlt="Not enough data image"
							/>
						</div>
					</div> :
				<div className="grid lg:grid-cols-2 xl:grid-cols-2 gap-2">
					<Skeleton className="h-80" />
					<Skeleton className="h-80" />
				</div>
			}
		</Content>
	)
}

export default DistributionAnalytics
