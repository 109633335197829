import { type CDNDistribution } from '../../../../../api/types/cdn.tsx'
import { type Dispatch, type SetStateAction, useState } from 'react'
import { ResponsiveDialog } from 'aptranet-ui/components/ui/responsive-dialog.tsx'
import { LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { deleteDistribution } from '../../../../../api/cdn/distributions.tsx'
import { toast } from 'sonner'

interface DeleteDistributionModalProps {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	distribution: CDNDistribution
	refreshData: () => void
}

const DeleteDistributionModal = (props: DeleteDistributionModalProps) => {
	const [deleteDistributionLoading, setDeleteDistributionLoading] = useState<boolean>(false)

	const doDeleteDistribution = () => {
		setDeleteDistributionLoading(true)
		deleteDistribution(props.distribution.id).then(() => {
			setDeleteDistributionLoading(false)
			toast.success("Distribution deleted successfully.")
			props.setOpen(false)
			props.refreshData()
		}).catch(() => setDeleteDistributionLoading(false))
	}

	return <ResponsiveDialog title="Delete Distribution" open={props.open} setOpen={props.setOpen} closeButtonText="Close"
													 closeButtonDisabled={deleteDistributionLoading}  footerChildren={<LoadingButton variant="destructive" text="Delete Distribution"
																																															 loading={deleteDistributionLoading}
																																															 onClick={() => doDeleteDistribution()} />}>
		<p>Are you sure that you want to delete the distribution <strong>{props.distribution.name}</strong>?</p>
	</ResponsiveDialog>
}

export default DeleteDistributionModal