import axios, {
	type AxiosError,
	type AxiosRequestHeaders,
	type AxiosResponse,
	type InternalAxiosRequestConfig,
} from 'axios'
import { toast } from 'sonner'
import cdnRoutes from '../routes/cdn.tsx'

interface AptranetAPIErrorResponse {
	error_code: string,
	error_description: string,
	trace_id: string,
}

const baseURL = import.meta.env.VITE_API_ENDPOINT

const requestInterceptor = axios.create({
	baseURL: baseURL,
	timeout: 10000,
})

const requestHandler = async (request: InternalAxiosRequestConfig) => {
	const token = await (window as any).Clerk.session.getToken()
	request.headers = { Authorization: 'Bearer ' + token } as AxiosRequestHeaders

	return request
}

const responseHandler = (response: AxiosResponse) => {
	return response
}

const errorHandler = (error: AxiosError<AptranetAPIErrorResponse>) => {
	if (error.response) {
		if (error.response.status === 401) {
			toast.error("Request could not be authenticated.")
		} else if (error.response.status === 402) {
			switch (window.location.pathname.split('/')[1]) {
				case 'cdn':
					window.location.replace(cdnRoutes.service_not_active.path)
			}
		} else {
			toast.error(error.response.data.error_description, {
				description: "Trace ID: " + error.response.data.trace_id
			})
		}
	}

	return Promise.reject(error)
}

requestInterceptor.interceptors.request.use(
	(request) => requestHandler(request),
	(error) => errorHandler(error),
)

requestInterceptor.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error),
)

export default requestInterceptor
