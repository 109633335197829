import * as React from 'react'
import { aptranetUIMerge } from 'aptranet-ui/lib/utils'

function Skeleton({
										className,
										...props
									}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={aptranetUIMerge('animate-pulse rounded-md bg-muted', className)}
			{...props}
		/>
	)
}

export { Skeleton }
