export const services = {
	project: 'Project',
	cdn: 'CDN',
	storage: 'Storage',
	static_sites: 'Static Sites',
	api_gateway: 'API Gateway',
	compute: 'Compute',
	intelliedge: 'IntelliEdge',
	dns: 'DNS',
	streaming: 'Streaming',
	shield: 'Shield',
	tls_manager: 'TLS Manager',
	logging: 'Logging',
	organizations: 'Organizations',
}