import { ResponsiveDialog } from 'aptranet-ui/components/ui/responsive-dialog.tsx'
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { type APIKey } from '../../../../../../api/types/central.ts'

import { Alert, AlertDescription, AlertTitle } from 'aptranet-ui/components/ui/alert'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { ClipboardCopyIcon, EyeIcon, EyeOffIcon, InfoIcon } from 'lucide-react'
import { toast } from 'sonner'
import { Switch } from 'aptranet-ui/components/ui/switch.tsx'
import { Label } from '@radix-ui/react-label'
import { Separator } from 'aptranet-ui/components/ui/separator.tsx'

interface APIKeyCreatedModalProps {
	open: boolean,
	setOpen: Dispatch<SetStateAction<boolean>>,
	apiKeyData: APIKey
}

const APIKeyCreatedModal = (props: APIKeyCreatedModalProps) => {
	const [secretShown, setSecretShown] = useState<boolean>(false)
	const [closeEnabled, setCloseEnabled] = useState<boolean>(false)

	useEffect(() => {
		if (!props.open) {
			setCloseEnabled(false)
		}
	}, [props.open]) // Close button was left enabled after closing, that's why this is here.

	return <ResponsiveDialog title="Your new API Key" open={props.open} setOpen={props.setOpen} closeButtonText="Close"
													 closeButtonDisabled={!closeEnabled}>
		<span className="font-normal">Name: <span className="font-semibold">{props.apiKeyData.name}</span></span>
		<span className="font-normal">Access Key: <span
			className="font-semibold">{props.apiKeyData.access_key}</span></span>

		<Separator className="mt-1" />

		<p className="font-normal">Secret: <span
			className={secretShown ? 'font-semibold' : 'blur-sm font-semibold'}> {props.apiKeyData.secret}</span>
		<Button variant="outline" size="icon" className="ms-1" onClick={() => setSecretShown(!secretShown)}>
      {secretShown ? <EyeOffIcon className="h-4 w-4" /> : <EyeIcon className="h-4 w-4" />}
    </Button>
			<Button variant="outline" size="icon" className="ms-1" onClick={() => {
				navigator.clipboard.writeText(props.apiKeyData.secret!)
				toast.info('Secret copied to clipboard.')
			}}>
      <ClipboardCopyIcon className="h-4 w-4" />
    </Button>
		</p>
		<Alert>
			<InfoIcon className="h-4 w-4" />
			<AlertTitle>Heads up!</AlertTitle>
			<AlertDescription>
				For security reasons you will not be able to see this again. In case you lose your secret, you will
				have to roll it in order to generate a new one.
				<br />
				<br />
				<span className="font-semibold">Please keep this somewhere safe in order to prevent unauthorized access.</span>
			</AlertDescription>
		</Alert>
		<div className="flex items-center space-x-2 mt-1">
			<Switch id="copied-check" onCheckedChange={(checked) => setCloseEnabled(checked)} />
			<Label htmlFor="copied-check">I have stored the secret somewhere safe.</Label>
		</div>
	</ResponsiveDialog>
}

export default APIKeyCreatedModal