import { Helmet } from 'react-helmet-async'

type HeadProps = {
	title: string
}

const Head = ({ title = '' }: HeadProps) => {
	return (
		<Helmet>
			<title>{title + (title !== '' ? ' | ' : '')} Aptranet Management Console</title>
		</Helmet>
	)
}

export default Head
