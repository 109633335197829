import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { type Project } from '../api/types/central.ts'
import Header from '../layout/header.tsx'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card'
import { Button, LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { Progress } from 'aptranet-ui/components/ui/progress.tsx'
import { ResponsiveDialog } from 'aptranet-ui/components/ui/responsive-dialog.tsx'
import { CreateProjectForm } from 'aptranet-ui/forms/aptranet-forms.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { listProjects } from '../api/central/projects.tsx'
import { calcPercentage } from 'aptranet-ui/lib/utils.ts'

const Projects = () => {
	const { actualTheme } = useTheme()
	const navigate = useNavigate()

	const [createProjectOpen, setCreateProjectOpen] = useState(false)
	const [createProjectLoading, setCreateProjectLoading] = useState(false)

	const projectFormRef = useRef<HTMLFormElement>(null)

	const [projects, setProjects] = useState<Project[] | null>(null)

	useEffect(() => {
		localStorage.removeItem("selected_project_id")
		localStorage.removeItem("selected_project_name")
		localStorage.removeItem("selected_project_description")
		listProjects().then(projects => setProjects(projects))
	}, [])

	const projectsNum = projects ? projects.length : 0

	return <>
		<Header hideProjectSelector hideProductName showAptranetLogo />

		<ResponsiveDialog title="Create Project" open={createProjectOpen} setOpen={setCreateProjectOpen} closeButtonText="Cancel" closeButtonDisabled={createProjectLoading} footerChildren={
			<LoadingButton variant="outline" text="Create Project" loading={createProjectLoading}
											onClick={() => projectFormRef.current?.requestSubmit()} />
		}>
			<CreateProjectForm ref={projectFormRef} setCreateProjectLoading={setCreateProjectLoading} />
		</ResponsiveDialog>

		<Content title="Projects" subtitle="Your Aptranet projects. Select a project to continue."
						 containerClassName="p-6 xl:px-56" actionItems={<Card>
			<CardContent className="py-4 px-12">
				<div className="grid grid-cols-2 gap-2 items-center">
					{/*TODO 👇*/}
					<p className="text-sm font-semibold">Projects: {projectsNum}/5</p>
					<Progress value={calcPercentage(projectsNum, 5)}
										className="h-1/2" />
				</div>
				<small className="text-center text-sm font-semibold text-muted-foreground">Need more projects? <Button
					variant="link" className="h-0.5 w-16 ms-0.5">Contact us</Button></small>
			</CardContent>
		</Card>}>
			<div className="h-full grid sm:grid-cols-1 xl:grid-cols-3 gap-4">
				<Card>
					<CardHeader className="pb-4">
						<CardTitle>Create New Project</CardTitle>
						<CardDescription>Ready to start a new project? Click the button bellow, and embrace the vast horizon of your
							imagination, where limitations dissolve and dreams take flight.</CardDescription>
					</CardHeader>
					<CardContent>
						<Button onClick={() => setCreateProjectOpen(true)}>Create Project</Button>
					</CardContent>
				</Card>
				{projects ? projects.map(project => (
					<Card
						className={(actualTheme === 'dark' ? 'hover:border-white' : 'hover:border-black') + ' hover:cursor-pointer transition-colors'}
						onClick={() => {
							localStorage.setItem('selected_project_id', project.id.toString())
							localStorage.setItem('selected_project_name', project.name)
							localStorage.setItem('selected_project_description', project.description || '')
							navigate('/')
						}}>
						<CardHeader className="pb-4">
							<CardTitle>{project.name}</CardTitle>
							<CardDescription>Created at {new Date(project.created_at ?? '').toDateString()}</CardDescription>
						</CardHeader>
						<CardContent>
							<p className="text-muted-foreground">{project.description ?? 'No description given.'}</p>
						</CardContent>
					</Card>
				)) : <>
					<Skeleton className="rounded-xl" />
					<Skeleton className="rounded-xl" />
				</>}
			</div>
		</Content>
	</>
}

export default Projects