import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { type OriginConfiguration } from '../../../../api/types/cdn.tsx'
import {
	getDistributionOriginConfiguration,
} from '../../../../api/cdn/distribution-configurations.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import OriginConfigurationForm from 'aptranet-ui/forms/cdn/distribution-configuration/origin.tsx'

const OriginConfiguration = () => {
	const { distributionID } = useParams()
	const originConfigurationRef = useRef<HTMLFormElement>(null)

	const [originConfigurationData, setOriginConfigurationData] = useState<OriginConfiguration | null>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateOriginConfigurationLoading, setUpdateOriginConfigurationLoading] = useState<boolean>(false)
	const [resetForm, setResetForm] = useState<{ run: () => void }>({ run: () => undefined })

	const fetchOriginConfiguration = () => {
		setOriginConfigurationData(null)
		getDistributionOriginConfiguration(Number(distributionID)).then((originConfiguration) => setOriginConfigurationData(originConfiguration))
	}

	useEffect(() => {
		fetchOriginConfiguration()
	}, [])

	return <Content title="Origin Configuration" subtitle="Manage your Distribution's Origin Configuration.">
	{originConfigurationData ? (
				<>
					<OriginConfigurationForm
						ref={originConfigurationRef}
						distributionID={Number(distributionID)}
						originConfigurationData={originConfigurationData}
						refreshOriginConfigurationData={fetchOriginConfiguration}
						setResetForm={setResetForm}
						setUnsavedChangesShown={setUnsavedChangesShown}
						setUpdateOriginConfigurationLoading={setUpdateOriginConfigurationLoading}
					/>
					<UnsavedChangesPopup
						shown={unsavedChangesShown}
						saveAction={() => originConfigurationRef.current?.requestSubmit()}
						cancelAction={() => resetForm.run()}
						cancelButtonText="Discard Changes"
						saveButtonText="Update Origin Configuration"
						saveButtonLoading={updateOriginConfigurationLoading}
					/>
				</>
			) : (
				<div className="flex flex-col items-center gap-3">
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-36" />
					<Skeleton className="w-full max-w-4xl h-36" />
				</div>
			)}
	</Content>
}

export default OriginConfiguration