import { useNavigate } from 'react-router-dom'

import { type Dispatch, forwardRef, type ReactNode, type SetStateAction, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { z } from 'zod'
import { zodToJsonSchema } from 'zod-to-json-schema'

import { permissions } from 'aptranet-ui/forms/home/developers/permissions.tsx'

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from 'aptranet-ui/components/ui/form'
import { Switch } from 'aptranet-ui/components/ui/switch'
import { Separator } from 'aptranet-ui/components/ui/separator'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'aptranet-ui/components/ui/accordion'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card'
import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'

import { getRouteList } from '../../../routes/routes.tsx'
import { services } from '../../../api/services.tsx'
import { toast } from 'sonner'
import { updateMemberPermissions } from '../../../api/central/projects.tsx'

const formSchema = z.object({ permissions })

interface editProjectMemberPermissionsProps {
	userID: string,
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>,
	setUpdatePermissionsLoading: Dispatch<SetStateAction<boolean>>,
	permissions: { [key: string]: { [key: string]: boolean } } | null
}

const EditProjectMemberPermissionsForm = forwardRef<HTMLFormElement, editProjectMemberPermissionsProps>((props, ref): ReactNode => {
	const { actualTheme } = useTheme()
	const navigate = useNavigate()

	//This is added for .properties. It exists.
	// @ts-ignore
	const permissionsObj = zodToJsonSchema(permissions, 'permissions').definitions!.permissions!.properties
	const form = useForm<z.infer<typeof formSchema>>({ resolver: zodResolver(formSchema) })

	useEffect(() => props.setUnsavedChangesShown(form.formState.isDirty), [form.formState.isDirty])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdatePermissionsLoading(true)

		const services = Object.keys(values.permissions)
		for (let i = 0; i < services.length; i++) {
			// @ts-ignore This has been type-checked previously.
			const permissions = Object.keys(values.permissions[services[i]])
			for (let j = 0; j < permissions.length; j++) {
				// @ts-ignore This has been type-checked previously.
				values.permissions[services[i]][permissions[j]] = values.permissions[services[i]][permissions[j]].enabled
			}
		}

		Object.keys(values.permissions).forEach(service =>
			// @ts-ignore
			Object.keys(values.permissions[service]).forEach(permission => {
				// @ts-ignore
				const permissionValue = values.permissions[service][permission]

				if (permissionValue !== undefined) {
					props.permissions![service][permission] = permissionValue
				}
			}))

		// @ts-ignore We have converted the permissions object above to be correct.
		updateMemberPermissions(props.userID, props.permissions).then(() => {
			props.setUpdatePermissionsLoading(false)
			props.setUnsavedChangesShown(false)
			toast.success('Member permissions updated successfully.')
			navigate(getRouteList().home.members.path)
		}).catch(() => props.setUpdatePermissionsLoading(false))
	}

	return <Form {...form}>
		<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
			{props.permissions ? <Card className={actualTheme == 'dark' ? 'bg-black' : 'bg-muted'}>
				<CardHeader className="pb-4">
					<CardTitle>Permissions</CardTitle>
					<CardDescription>API Key Permissions</CardDescription>
				</CardHeader>
				<CardContent>
						<Accordion type="single" className="mx-1" collapsible>
							{Object.keys(permissionsObj).map(service => (
								<AccordionItem value={service}>
									{/* @ts-ignore (This is correct) */}
									<AccordionTrigger>{services[service]} Permissions</AccordionTrigger>
									<AccordionContent>
										<Card>
											<CardContent>
												{Object.keys(permissionsObj[service].properties).map(permission =>
													<>
														{(permissionsObj[service]['properties'][permission].properties.add_divider && permissionsObj[service]['properties'][permission].properties.add_divider) &&
															<Separator className="mt-4" />}
														<FormField
															control={form.control}
															// @ts-ignore
															name={'permissions.' + service + '.' + permission + '.enabled'}
															render={({ field }) => (
																<FormItem
																	className="flex flex-row items-center justify-between rounded-lg p-4 pb-0 pe-12">
																	<div className="space-y-0.5">
																		<FormLabel className="text-base">
																			{permissionsObj[service]['properties'][permission].properties.permission.const.split('.')[1].split(/(?=[A-Z])/).join(' ').replace('R R Set', 'RRSet').replace('A P I', 'API')}
																		</FormLabel>
																		<FormDescription>
																			{permissionsObj[service]['properties'][permission].properties.permission.description}
																		</FormDescription>
																	</div>
																	<FormControl>
																		<Switch
																			// @ts-ignore
																			checked={field.value}
																			onCheckedChange={field.onChange}
																			defaultChecked={props.permissions![service][permission]}
																		/>
																	</FormControl>
																</FormItem>
															)}
														/>
													</>)}
											</CardContent>
										</Card>
									</AccordionContent>
								</AccordionItem>
							))}
						</Accordion>
				</CardContent>
			</Card> : <Skeleton className="h-[948px] " />}
		</form>
	</Form>
})

export default EditProjectMemberPermissionsForm