import { z } from 'zod'

export const projectPermissions = z
	.object({
		create_quota_increase_request: z.object({
			permission: z.literal('Project.CreateQuotaIncreaseRequest').describe('Create a project quota increase request.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_api_keys: z.object({
			permission: z.literal('Project.ListAPIKeys').describe('List project API keys.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_api_key: z.object({
			permission: z.literal('Project.CreateAPIKey').describe('Create a project API key.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_api_key: z.object({
			permission: z.literal('Project.ModifyAPIKey').describe('Modify a project API key.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_api_key: z.object({
			permission: z.literal('Project.DeleteAPIKey').describe('Delete a project API Key.').optional(),
			enabled: z.boolean().optional(),
		}),
		webhooks_portal_access: z.object({
			permission: z.literal('Project.WebhooksPortalAccess').describe('Access to the Webhooks Portal.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const cdnPermissions = z
	.object({
		list_distributions: z.object({
			permission: z.literal('CDN.ListDistributions').describe('List CDN Distributions.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_distribution: z.object({
			permission: z.literal('CDN.CreateDistribution').describe('Create CDN Distributions.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_distribution: z.object({
			permission: z.literal('CDN.ModifyDistribution').describe('Modify CDN Distributions.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_distribution: z.object({
			permission: z.literal('CDN.DeleteDistribution').describe('Delete CDN Distributions.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_origin_groups: z.object({
			permission: z.literal('CDN.ListOriginGroups').describe('List CDN Origin Groups.').optional(),
			enabled: z.boolean().optional(),
			add_divider: z.literal(true).optional(),
		}),
		create_origin_group: z.object({
			permission: z.literal('CDN.CreateOriginGroup').describe('Create CDN Origin Groups.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_origin_group: z.object({
			permission: z.literal('CDN.ModifyOriginGroup').describe('Modify CDN Origin Groups.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_origin_group: z.object({
			permission: z.literal('CDN.DeleteOriginGroup').describe('Delete CDN Origin Groups.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const storagePermissions = z
	.object({
		list_buckets: z.object({
			permission: z.literal('Storage.ListBuckets').describe('List Storage Buckets.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_bucket: z.object({
			permission: z.literal('Storage.CreateBucket').describe('Create Storage Buckets.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_bucket: z.object({
			permission: z.literal('Storage.ModifyBucket').describe('Modify Storage Buckets.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_bucket: z.object({
			permission: z.literal('Storage.DeleteBucket').describe('Delete Storage Buckets.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_objects: z.object({
			permission: z.literal('Storage.ListObjects').describe('List Bucket Objects.').optional(),
			enabled: z.boolean().optional(),
			add_divider: z.literal(true).optional(),
		}),
		read_object: z.object({
			permission: z.literal('Storage.ReadObject').describe('Read Bucket Objects.').optional(),
			enabled: z.boolean().optional(),
		}),
		write_object: z.object({
			permission: z.literal('Storage.WriteObject').describe('Write Bucket Objects.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_object: z.object({
			permission: z.literal('Storage.DeleteObject').describe('Delete Bucket Objects.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const staticSitesPermissions = z
	.object({
		list_sites: z.object({
			permission: z.literal('StaticSites.ListSites').describe('List Static Sites.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_site: z.object({
			permission: z.literal('StaticSites.CreateSite').describe('Create Static Sites.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_site: z.object({
			permission: z.literal('StaticSites.ModifySite').describe('Modify Static Sites.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_site: z.object({
			permission: z.literal('StaticSites.DeleteSite').describe('Delete Static Sites.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const apiGatewayPermissions = z
	.object({
		list_gateways: z.object({
			permission: z.literal('APIGateway.ListGateways').describe('List API Gateways.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_gateway: z.object({
			permission: z.literal('APIGateway.CreateGateway').describe('Create API Gateways.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_gateway: z.object({
			permission: z.literal('APIGateway.ModifyGateway').describe('Modify API Gateways.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_gateway: z.object({
			permission: z.literal('APIGateway.DeleteGateway').describe('Delete API Gateways.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_routes: z.object({
			permission: z.literal('APIGateway.ListRoutes').describe('List API Gateway Routes.').optional(),
			enabled: z.boolean().optional(),
			add_divider: z.literal(true).optional(),
		}),
		add_route: z.object({
			permission: z.literal('APIGateway.AddRoute').describe('Add API Gateway Routes.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_route: z.object({
			permission: z.literal('APIGateway.ModifyRoute').describe('Modify API Gateway Routes.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_route: z.object({
			permission: z.literal('APIGateway.DeleteRoute').describe('Delete API Gateway Routes.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const computePermissions = z
	.object({
		list_services: z.object({
			permission: z.literal('Compute.ListServices').describe('List Compute Services.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_service: z.object({
			permission: z.literal('Compute.CreateService').describe('Create Compute Services.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_service: z.object({
			permission: z.literal('Compute.ModifyService').describe('Modify Compute Services.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_service: z.object({
			permission: z.literal('Compute.DeleteService').describe('Delete Compute Services.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_functions: z.object({
			permission: z.literal('Compute.ListFunctions').describe('List Compute Functions.').optional(),
			enabled: z.boolean().optional(),
			add_divider: z.literal(true).optional(),
		}),
		create_function: z.object({
			permission: z.literal('Compute.CreateFunction').describe('Create Compute Functions.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_function: z.object({
			permission: z.literal('Compute.ModifyFunction').describe('Modify Compute Functions.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_function: z.object({
			permission: z.literal('Compute.DeleteFunction').describe('Delete Compute Functions.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const intelliedgePermissions = z
	.object({
		list_workers: z.object({
			permission: z.literal('IntelliEdge.ListWorkers').describe('List IntelliEdge Workers.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_worker: z.object({
			permission: z.literal('IntelliEdge.CreateWorker').describe('Create IntelliEdge Workers.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_worker: z.object({
			permission: z.literal('IntelliEdge.ModifyWorker').describe('Modify IntelliEdge Workers.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_worker: z.object({
			permission: z.literal('IntelliEdge.DeleteWorker').describe('Delete IntelliEdge Workers.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const dnsPermissions = z
	.object({
		list_zones: z.object({
			permission: z.literal('DNS.ListZones').describe('List DNS Zones.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_zone: z.object({
			permission: z.literal('DNS.CreateZone').describe('Create DNS Zones.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_zone: z.object({
			permission: z.literal('DNS.ModifyZone').describe('Modify DNS Zones.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_zone: z.object({
			permission: z.literal('DNS.DeleteZone').describe('Delete DNS Zones.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_rrsets: z.object({
			permission: z.literal('DNS.ListRRSets').describe('List Zone RRSets.').optional(),
			enabled: z.boolean().optional(),
			add_divider: z.literal(true).optional(),
		}),
		create_rrset: z.object({
			permission: z.literal('DNS.CreateRRSet').describe('Create Zone RRSets.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_rrset: z.object({
			permission: z.literal('DNS.ModifyRRSet').describe('Modify Zone RRSets.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_rrset: z.object({
			permission: z.literal('DNS.DeleteRRSet').describe('Delete Zone RRSets.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const streamingPermissions = z
	.object({
		list_video_clouds: z.object({
			permission: z.literal('Streaming.ListVideoClouds').describe('List Streaming Video Clouds.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_video_cloud: z.object({
			permission: z.literal('Streaming.CreateVideoCloud').describe('Create Streaming Video Clouds.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_video_cloud: z.object({
			permission: z.literal('Streaming.ModifyVideoCloud').describe('Modify Streaming Video Clouds.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_video_cloud: z.object({
			permission: z.literal('Streaming.DeleteVideoCloud').describe('Delete Streaming Video Clouds.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_videos: z.object({
			permission: z.literal('Streaming.ListVideos').describe('List Video Cloud Videos.').optional(),
			enabled: z.boolean().optional(),
			add_divider: z.literal(true).optional(),
		}),
		create_video: z.object({
			permission: z.literal('Streaming.CreateVideo').describe('Create Video Cloud Videos.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_video: z.object({
			permission: z.literal('Streaming.ModifyVideo').describe('Modify Video Cloud Videos.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_video: z.object({
			permission: z.literal('Streaming.DeleteVideo').describe('Delete Video Cloud Videos.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const shieldPermissions = z
	.object({
		list_protected_resources: z.object({
			permission: z.literal('Shield.ListProtectedResources').describe('List Shield Protected Resources.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_protected_resource: z.object({
			permission: z.literal('Shield.CreateProtectedResource').describe('Create Shield Protected Resources.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_protected_resource: z.object({
			permission: z.literal('Shield.ModifyProtectedResource').describe('Modify Shield Protected Resources.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_protected_resource: z.object({
			permission: z.literal('Shield.DeleteProtectedResource').describe('Delete Shield Protected Resources.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const tlsManagerPermissions = z
	.object({
		list_certificates: z.object({
			permission: z.literal('TLSManager.ListCertificates').describe('List TLS Manager Certificates.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_certificate: z.object({
			permission: z.literal('TLSManager.CreateCertificate').describe('Create TLS Manager Certificates.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_certificate: z.object({
			permission: z.literal('TLSManager.ModifyCertificate').describe('Modify TLS Manager Certificates.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_certificate: z.object({
			permission: z.literal('TLSManager.DeleteCertificate').describe('Delete TLS Manager Certificates.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const loggingPermissions = z
	.object({
		list_aptranet_sources: z.object({
			permission: z.literal('Logging.ListAptranetSources').describe('List Aptranet-Managed Logging Sources.').optional(),
			enabled: z.boolean().optional(),
		}),
		list_custom_sources: z.object({
			permission: z.literal('Logging.ListCustomSources').describe('List Custom Logging Sources.').optional(),
			enabled: z.boolean().optional(),
		}),
		create_custom_source: z.object({
			permission: z.literal('Logging.CreateCustomSource').describe('Create Custom Logging Sources.').optional(),
			enabled: z.boolean().optional(),
		}),
		modify_custom_source: z.object({
			permission: z.literal('Logging.ModifyCustomSource').describe('Modify Custom Logging Sources.').optional(),
			enabled: z.boolean().optional(),
		}),
		delete_custom_source: z.object({
			permission: z.literal('Logging.DeleteCustomSource').describe('Delete Custom Logging Sources.').optional(),
			enabled: z.boolean().optional(),
		}),
	})
	.optional()

export const permissions = z.object({
	project: projectPermissions,
	cdn: cdnPermissions,
	// storage: storagePermissions,
	// static_sites: staticSitesPermissions,
	// api_gateway: apiGatewayPermissions,
	// compute: computePermissions,
	// intelliedge: intelliedgePermissions,
	// dns: dnsPermissions,
	// streaming: streamingPermissions,
	shield: shieldPermissions,
	// tls_manager: tlsManagerPermissions,
	// logging: loggingPermissions,
})
