import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { type HeadersConfiguration } from '../../../../api/types/cdn.tsx'
import {
	getDistributionHeadersConfiguration,
} from '../../../../api/cdn/distribution-configurations.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import HeadersConfigurationForm from 'aptranet-ui/forms/cdn/distribution-configuration/headers.tsx'

const HeadersConfiguration = () => {
	const { distributionID } = useParams()
	const headersConfigurationRef = useRef<HTMLFormElement>(null)

	const [headersConfigurationData, setHeadersConfigurationData] = useState<HeadersConfiguration | null>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateHeadersConfigurationLoading, setUpdateHeadersConfigurationLoading] = useState<boolean>(false)
	const [resetForm, setResetForm] = useState<{ run: () => void }>({ run: () => undefined })

	const fetchHeadersConfiguration = () => {
		setHeadersConfigurationData(null)
		getDistributionHeadersConfiguration(Number(distributionID)).then((headersConfiguration) => setHeadersConfigurationData(headersConfiguration))
	}

	useEffect(() => {
		fetchHeadersConfiguration()
	}, [])

	return <Content title="Headers Configuration" subtitle="Manage your Distribution's Headers Configuration.">
		{headersConfigurationData ? (
			<>
				<HeadersConfigurationForm
					ref={headersConfigurationRef}
					distributionID={Number(distributionID)}
					headersConfigurationData={headersConfigurationData}
					refreshHeadersConfigurationData={fetchHeadersConfiguration}
					setResetForm={setResetForm}
					setUnsavedChangesShown={setUnsavedChangesShown}
					setUpdateHeadersConfigurationLoading={setUpdateHeadersConfigurationLoading}
				/>
				<UnsavedChangesPopup
					shown={unsavedChangesShown}
					saveAction={() => headersConfigurationRef.current?.requestSubmit()}
					cancelAction={() => resetForm.run()}
					cancelButtonText="Discard Changes"
					saveButtonText="Update Headers Configuration"
					saveButtonLoading={updateHeadersConfigurationLoading}
				/>
			</>
		) : (
			<div className="flex flex-col items-center gap-3">
				<Skeleton className="w-full max-w-4xl h-28" />
				<Skeleton className="w-full max-w-4xl h-28" />
				<Skeleton className="w-full max-w-4xl h-36" />
				<Skeleton className="w-full max-w-4xl h-36" />
			</div>
		)}
	</Content>
}

export default HeadersConfiguration