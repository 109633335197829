import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { useState } from 'react'
import { createPortal } from '../../api/central/billing.tsx'

const BillingInformation = () => {
	const [openPortalLoading, setOpenPortalLoading] = useState<boolean>(false)

	const openPortal = () => {
		setOpenPortalLoading(true)

		createPortal().then(portal => {
			// @ts-ignore
			const cbInstance = window.Chargebee.getInstance()
			setOpenPortalLoading(false)

			cbInstance.setPortalSession( () => new Promise((resolve) => {
				resolve({
					id: portal.id,
					token: portal.token,
					access_url: portal.access_url,
					type: 'portal_session',
				})
			}));

			cbInstance.createChargebeePortal().open({
				close: () => {
					setOpenPortalLoading(false)
				},
			})
		}).catch(() => setOpenPortalLoading(false))
	}

	return <Content title="Billing Information" subtitle="Mange your account's billing information.">
		<Card>
			<CardHeader className="flex flex-col items-center pt-0 pb-4">
				<img src="https://assets.aptranetedge.com/management-console/images/graphics/wallet.svg"
						 alt="Payment Method Image"
						 className="w-64 h-60" />
					<CardTitle>Aptranet Payments Portal</CardTitle>
					<CardDescription className="w-[960px] text-center">
						Meet the Aptranet Payments Portal. This secure platform offers a centralized hub for managing all your Aptranet billing needs.  Conveniently access and review invoices, update payment methods, view and manage subscriptions, and explore a variety of other features – all within a streamlined and user-friendly interface. Our dedicated customer support team is here to assist you with any questions you may have.
					</CardDescription>
			</CardHeader>
			<CardContent className="flex justify-center">
				<LoadingButton text="Open Portal"
											 loading={openPortalLoading}
											 onClick={() => openPortal()}></LoadingButton>
			</CardContent>
		</Card>

		<h2 className="text-center text-2xl font-semibold mt-12 mb-4">Things you can do in the Aptranet Payments portal:</h2>
		<div className="grid xl:grid-cols-3 gap-3 mt-3 mb-12">
			<Card>
				<CardHeader className="flex flex-col items-center pt-0">
					<img src="https://assets.aptranetedge.com/management-console/images/graphics/address.svg" alt="Address Image"
							 className="w-64 h-60" />
					<CardTitle>Account Addresses</CardTitle>
					<CardDescription>
						Manage your account's billing address.
					</CardDescription>
				</CardHeader>
			</Card>
			<Card>
				<CardHeader className="flex flex-col items-center pt-0">
					<img src="https://assets.aptranetedge.com/management-console/images/graphics/credit-card.svg"
							 alt="Payment Method Image"
							 className="w-64 h-60" />
					<CardTitle>Payment Methods</CardTitle>
					<CardDescription>
						Manage your account's payment methods.
					</CardDescription>
				</CardHeader>
			</Card>
			<Card>
				<CardHeader className="flex flex-col items-center pt-0">
					<img src="https://assets.aptranetedge.com/management-console/images/graphics/invoice.svg" alt="Invoice Image"
							 className="w-64 h-60" />
					<CardTitle>Billing History</CardTitle>
					<CardDescription>
						View and download your account's billing history.
					</CardDescription>
				</CardHeader>
			</Card>
		</div>
	</Content>
}

export default BillingInformation