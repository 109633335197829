import { toast } from 'sonner'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import OriginGroupsTable from 'aptranet-ui/components/management-console/cdn/origin-groups/origin-groups-table/table.tsx'
import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { ActionButton, LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { ResponsiveDialog } from 'aptranet-ui/components/ui/responsive-dialog.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'

import { PlusIcon } from 'lucide-react'

import { deleteOriginGroup, listOriginGroups } from '../../../api/cdn/origin-groups.tsx'
import { type OriginGroupInfo } from '../../../api/types/cdn.tsx'
import cdnRoutes from '../../../routes/cdn.tsx'

const OriginGroups = () => {
	const navigate = useNavigate()

	const [rowSelection, setRowSelection] = useState({})
	const [originGroups, setOriginGroups] = useState<OriginGroupInfo[] | null>(null)

	const [deleteOriginGroupModalOpen, setDeleteOriginGroupModalOpen] = useState<boolean>(false)
	const [deleteOriginGroupModalData, setDeleteOriginGroupModalData] = useState<OriginGroupInfo | null>(null)
	const [deleteOriginGroupLoading, setDeleteOriginGroupLoading] = useState<boolean>(false)

	const fetchData = () => {
		setOriginGroups(null)
		listOriginGroups().then((originGroups) => setOriginGroups(originGroups))
	}

	useEffect(() => {
		fetchData()
	}, [])

	const openDeleteOriginGroupModal = (originGroup: OriginGroupInfo) => {
		setDeleteOriginGroupModalData(originGroup)
		setDeleteOriginGroupModalOpen(true)
	}

	return (
		<>
			<ResponsiveDialog
				title="Delete Origin Group"
				open={deleteOriginGroupModalOpen}
				setOpen={setDeleteOriginGroupModalOpen}
				closeButtonText="Cancel"
				closeButtonDisabled={deleteOriginGroupLoading}
				footerChildren={
					<LoadingButton
						variant="destructive"
						text="Delete Origin Group"
						loading={deleteOriginGroupLoading}
						onClick={() => {
							setDeleteOriginGroupLoading(true)
							deleteOriginGroup(deleteOriginGroupModalData!.id)
								.then(() => {
									setDeleteOriginGroupLoading(false)
									toast.success('Origin Group Deleted Successfully.')
									setDeleteOriginGroupModalOpen(false)
									setDeleteOriginGroupModalData(null)
									fetchData()
								})
								.catch(() => setDeleteOriginGroupLoading(false))
						}}
					/>
				}
			>
				<p>
					Are you sure that you'd like to delete <strong>{deleteOriginGroupModalData?.name}</strong>?
				</p>
			</ResponsiveDialog>

			<Content
				title="Origin Groups"
				subtitle="View and manage your Origin Groups."
				actionItems={<ActionButton icon={PlusIcon} text="Create Origin Group" onClick={() => navigate(cdnRoutes.origin_group_create.path)} />}
			>
				{originGroups ? (
					<OriginGroupsTable
						originGroups={originGroups}
						openDeleteOriginGroupModal={openDeleteOriginGroupModal}
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
					/>
				) : (
					<>
						<div className="flex justify-between mb-4">
							<Skeleton className="w-[370px] h-[40px]" />
							<Skeleton className="w-[112px] h-[40px]" />
						</div>

						<Skeleton className="h-72" />
					</>
				)}
			</Content>
		</>
	)
}

export default OriginGroups
