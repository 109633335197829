import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { OriginGroupForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getOriginGroup } from '../../../api/cdn/origin-groups.tsx'
import { type OriginGroup } from '../../../api/types/cdn.tsx'
import { getRouteList } from '../../../routes/routes.tsx'

const EditOriginGroup = () => {
	const { originGroupID } = useParams()

	const navigate = useNavigate()
	const [originGroup, setOriginGroup] = useState<OriginGroup | null>(null)
	const editOriginGroupRef = useRef<HTMLFormElement>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateOriginGroupLoading, setUpdateOriginGroupLoading] = useState<boolean>(false)

	useEffect(() => {
		getOriginGroup(Number(originGroupID)).then((originGroup) => setOriginGroup(originGroup))
	}, [])

	return (
		<Content title="Edit Origin Group" subtitle="Edit Origin Group details, auth credentials and origins.">
			{originGroup ? (
				<OriginGroupForm
					originGroup={originGroup}
					ref={editOriginGroupRef}
					setUnsavedChangesShown={setUnsavedChangesShown}
					setCreateOriginGroupLoading={setUpdateOriginGroupLoading}
				/>
			) : (
				<Skeleton className="w-full h-96" />
			)}

			<UnsavedChangesPopup
				shown={unsavedChangesShown}
				saveAction={() => editOriginGroupRef.current?.requestSubmit()}
				cancelAction={() => navigate(getRouteList().cdn.origin_groups.path)}
				cancelButtonText="Cacnel"
				saveButtonText="Update Origin Group"
				saveButtonLoading={updateOriginGroupLoading}
			/>
		</Content>
	)
}

export default EditOriginGroup
