import { useEffect, useState } from 'react'

import { useTheme } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Button, LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { Card, CardContent } from 'aptranet-ui/components/ui/card.tsx'

import { CircleAlertIcon } from 'lucide-react'

interface UnsavedChangesPopupProps {
	shown: boolean
	saveAction: () => void
	cancelButtonText: string
	cancelButtonDisabled?: boolean
	cancelAction: () => void
	saveButtonText: string
	saveButtonLoading: boolean
}

const UnsavedChangesPopup = (props: UnsavedChangesPopupProps) => {
	const [hidden, setHidden] = useState<boolean>(true)
	const [shown, setShown] = useState<boolean>(false)
	const { actualTheme } = useTheme()

	useEffect(() => {
		if (props.shown) {
			setTimeout(() => setHidden(false), 300)
			setTimeout(() => setShown(true), 800)
		} else {
			setTimeout(() => setShown(false), 300)
			setTimeout(() => setHidden(true), 800)
		}
	}, [props.shown])

	return (
		<div
			data-shown={shown}
			data-hidden={hidden}
			className="sticky bottom-0 opacity-0 transition-all duration-300 z-50 data-[shown=true]:opacity-100 data-[hidden=true]:hidden"
		>
			<div className="flex justify-center">
				<Card className={'w-full md:w-[650px] h-16 mx-4 my-5 ' + (actualTheme === 'dark' ? 'bg-gray-900' : 'bg-gray-50')}>
					<CardContent className="flex justify-between items-center mt-3">
						<div className="flex items-center">
							<CircleAlertIcon size={18} />
							<p className="text-xs font-semibold ms-1">There are unsaved changes.</p>
						</div>
						<div className="flex items-center">
							<Button
								size="sm"
								variant="outline"
								className="me-2"
								onClick={props.cancelAction}
								disabled={props.saveButtonLoading || props.cancelButtonDisabled}
							>
								{props.cancelButtonText}
							</Button>
							<LoadingButton size="sm" text={props.saveButtonText} loading={props.saveButtonLoading} onClick={() => props.saveAction()} />
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

export default UnsavedChangesPopup
