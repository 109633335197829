import AptranetBarChart from 'aptranet-ui/aptranet-charts/bar-chart.tsx'
import { ChartConfig } from 'aptranet-ui/components/ui/chart'
import { DistributionAnalyticsDatapoint } from '../../../../../../api/types/cdn.tsx'

const chartConfig = {
	requests: {
		label: 'Requests',
		color: 'hsl(var(--chart-1))',
	},
} satisfies ChartConfig

const RequestsChart = (props: { data: DistributionAnalyticsDatapoint[], chartOnly?: boolean }) => (
	<AptranetBarChart
		title="Requests"
		description="Requests in the last 30 days."
		config={chartConfig}
		data={{ requests: props.data.map(datapoint => ({ timestamp: datapoint.datetime, value: datapoint.requests.total_requests })) }}
		formatter={(value) => Intl.NumberFormat('en', { notation: 'compact' }).format(value)}
		totalCalculator={(values) => values.reduce((acc, curr) => acc + curr, 0)}
		chartOnly={props.chartOnly || false}
	/>
)

export default RequestsChart
