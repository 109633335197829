import { type Dispatch, forwardRef, type ReactNode, type SetStateAction, useEffect, useState } from 'react'
import { type OriginConfiguration, type OriginGroupInfo, OriginGroupInfoMinimal } from '../../../../api/types/cdn.tsx'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from 'aptranet-ui/components/ui/form.tsx'
import { Switch } from 'aptranet-ui/components/ui/switch.tsx'
import { Accordion, AccordionContent, AccordionItem } from 'aptranet-ui/components/ui/accordion.tsx'
import { Input } from 'aptranet-ui/components/ui/input.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Loader2, RotateCwIcon } from 'lucide-react'
import {
	OriginGroupSelector
} from 'aptranet-ui/components/management-console/cdn/distributions/origin-group-selector/origin-group-selector.tsx'
import { listOriginGroups } from '../../../../api/cdn/origin-groups.tsx'
import { updateDistributionOriginConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { toast } from 'sonner'

interface OriginConfigurationProps {
	distributionID: number
	originConfigurationData: OriginConfiguration
	refreshOriginConfigurationData: () => void
	setResetForm: Dispatch<SetStateAction<{ run: () => void }>>
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setUpdateOriginConfigurationLoading: Dispatch<SetStateAction<boolean>>
}

const formSchema = z.object({
	origin_group_id: z.number(),
	follow_origin_redirection: z.boolean().optional(),
	custom_connection_timeout: z.object({
		enabled: z.boolean().optional(),
		timeout: z.string().min(1).max(1),
	}),
	custom_read_timeout: z.object({
		enabled: z.boolean().optional(),
		timeout: z.string().min(1).max(2),
	}),
})

const OriginConfigurationForm = forwardRef<HTMLFormElement, OriginConfigurationProps>((props, ref): ReactNode => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			origin_group_id: props.originConfigurationData.origin_group.id,
			follow_origin_redirection: props.originConfigurationData.follow_origin_redirection,
			custom_connection_timeout: {
				enabled: props.originConfigurationData.connection_timeout !== 2,
				timeout: props.originConfigurationData.connection_timeout.toString(),
			},
			custom_read_timeout: {
				enabled: props.originConfigurationData.read_timeout !== 5,
				timeout: props.originConfigurationData.read_timeout.toString(),
			},
		},
	})

	const followOriginRedirectionEnabled = form.watch('follow_origin_redirection')
	const customConnectionTimeoutEnabled = form.watch('custom_connection_timeout.enabled')
	const customReadTimeoutEnabled = form.watch('custom_read_timeout.enabled')

	const [originGroups, setOriginGroups] = useState<OriginGroupInfo[] | null>(null)
	const [selectedOriginGroup, setSelectedOriginGroup] = useState<OriginGroupInfoMinimal | null>(props.originConfigurationData.origin_group)

	useEffect(() => props.setUnsavedChangesShown(form.formState.isDirty), [form.formState.isDirty])

	const fetchOriginGroups = () => {
		setOriginGroups(null)
		listOriginGroups().then((res) => setOriginGroups(res))
	}

	useEffect(() => {
		fetchOriginGroups()
	}, [])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdateOriginConfigurationLoading(true)
		updateDistributionOriginConfiguration(props.distributionID, {
			origin_group_id: values.origin_group_id,
			follow_origin_redirection: values.follow_origin_redirection!,
			connection_timeout: Number(values.custom_connection_timeout.timeout),
			read_timeout: Number(values.custom_read_timeout.timeout),
		}).then(() => {
			props.setUpdateOriginConfigurationLoading(false)
			toast.success('Origin configuration updated successfully. Please allow up to 15 minutes for the changes to fully propagate to our edge.')
			props.refreshOriginConfigurationData()
		})
		.catch(() => props.setUpdateOriginConfigurationLoading(false))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<div className="flex flex-col items-center gap-4 w-full">
					<Card className="w-full max-w-4xl">
						<CardHeader className="pb-0">
								<CardTitle>Origin Group</CardTitle>
								<CardDescription className="mt-2">Define the source location(s) for the CDN to fetch content.</CardDescription>
						</CardHeader>
						<CardContent>
							<div className="mt-4">
								<FormField
									control={form.control}
									name="origin_group_id"
									render={({ field }) => (
										<FormItem className="flex flex-col">
											<div className="flex gap-2 w-full">
												<FormControl>
													<OriginGroupSelector
														originGroups={originGroups}
														setOriginGroups={setOriginGroups}
														selectedOriginGroup={selectedOriginGroup}
														setSelectedOriginGroup={setSelectedOriginGroup}
														onValueChange={field.onChange}
													/>
												</FormControl>
												<Button type="button" variant="outline" size="icon" onClick={() => fetchOriginGroups()}>
													{!originGroups ? <Loader2 className="me-0.5 h-4 w-4 animate-spin" /> : <RotateCwIcon />}
												</Button>
											</div>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</CardContent>
					</Card>
					<Card className={'w-full max-w-4xl' + (followOriginRedirectionEnabled ? ' border-primary' : '')}>
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle>Follow Origin Redirection</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									This configuration enables the CDN to follow redirects issued by the origin server. When a redirect
									response is received, our servers retrieve
									the requested content from the new target location specified in the redirect.
									<br />
									<br />
									<strong>
										To ensure users receive the latest content after enabling this option, a cache purge is highly
										recommended. This will remove any outdated
										cached content associated with the original request.
									</strong>
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="follow_origin_redirection"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
					</Card>
					<Card className={'w-full max-w-4xl' + (customConnectionTimeoutEnabled ? ' border-primary' : '')}>
						<CardHeader className="pb-0 flex flex-row justify-between items-center">
							<div>
								<CardTitle>Custom Connection Timeout</CardTitle>
								<CardDescription className="mt-2">Defines the maximum wait time for establishing a connection with the
									origin server.</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="custom_connection_timeout.enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1"
												 value={customConnectionTimeoutEnabled ? 'customConnectionTimeout' : 'none'} collapsible>
								<AccordionItem value="customConnectionTimeout" className="border-b-0">
									<AccordionContent>
										<div className="mt-4 mx-1">
											<FormField
												control={form.control}
												name="custom_connection_timeout.timeout"
												render={({ field }) => (
													<FormItem>
														<FormLabel>Timeout</FormLabel>
														<FormControl>
															<div className="relative">
																<Input {...field} type="number" min={1} max={5} className="pe-20" />
																<div
																	className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 border-s">
																	<span>Seconds</span>
																</div>
															</div>
														</FormControl>
														<FormMessage />
														<FormDescription>
															In the event of a timeout, the edge server will attempt to connect to a designated backup
															origin, if one is specified. If no
															backup origin is available, a 504 Gateway Timeout error will be returned to the end-user.
														</FormDescription>
													</FormItem>
												)}
											/>
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
					<Card className={'w-full max-w-4xl' + (customReadTimeoutEnabled ? ' border-primary' : '')}>
						<CardHeader className="pb-0 flex flex-row justify-between items-center">
							<div>
								<CardTitle>Custom Read Timeout</CardTitle>
								<CardDescription className="mt-2">Defines the maximum wait time for a response from the origin
									server.</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="custom_read_timeout.enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1" value={customReadTimeoutEnabled ? 'customReadTimeout' : 'none'}
												 collapsible>
								<AccordionItem value="customReadTimeout" className="border-b-0">
									<AccordionContent>
										<div className="mt-4 mx-1">
											<FormField
												control={form.control}
												name="custom_read_timeout.timeout"
												render={({ field }) => (
													<FormItem>
														<FormLabel>Timeout</FormLabel>
														<FormControl>
															<div className="relative">
																<Input {...field} type="number" min={1} max={30} className="pe-20" />
																<div
																	className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 border-s">
																	<span>Seconds</span>
																</div>
															</div>
														</FormControl>
														<FormMessage />
														<FormDescription>
															If the timeout expires, the edge server will attempt to retrieve a response from a
															specified
															backup origin. If no backup origin is
															specified, a 504 Gateway Timeout error will be returned to the client.
														</FormDescription>
													</FormItem>
												)}
											/>
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
				</div>
			</form>
		</Form>
)
})

export default OriginConfigurationForm
