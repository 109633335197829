import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { VariantProps } from 'class-variance-authority'

import * as React from 'react'

import { Card, CardContent, CardFooter } from 'aptranet-ui/components/ui/card.tsx'
import { toggleVariants } from 'aptranet-ui/components/ui/toggle'
import { aptranetUIMerge } from 'aptranet-ui/lib/utils'

import { LucideIcon } from 'lucide-react'

const ToggleGroupContext = React.createContext<VariantProps<typeof toggleVariants>>({
	size: 'default',
	variant: 'default',
})

const ToggleGroup = React.forwardRef<
	React.ElementRef<typeof ToggleGroupPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, size, children, ...props }, ref) => (
	<ToggleGroupPrimitive.Root ref={ref} className={aptranetUIMerge('flex items-center justify-center gap-1', className)} {...props}>
		<ToggleGroupContext.Provider value={{ variant, size }}>{children}</ToggleGroupContext.Provider>
	</ToggleGroupPrimitive.Root>
))

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

const ToggleGroupItem = React.forwardRef<
	React.ElementRef<typeof ToggleGroupPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> & VariantProps<typeof toggleVariants>
>(({ className, children, variant, size, ...props }, ref) => {
	const context = React.useContext(ToggleGroupContext)

	return (
		<ToggleGroupPrimitive.Item
			ref={ref}
			className={aptranetUIMerge(
				toggleVariants({
					variant: context.variant || variant,
					size: context.size || size,
				}),
				className
			)}
			{...props}
		>
			{children}
		</ToggleGroupPrimitive.Item>
	)
})

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

const ToggleGroupItemCard = (props: { value: string; title: string; icon?: LucideIcon; customIcon?: string; className?: string }) => (
	<ToggleGroupItem value={props.value} className="h-max w-max p-0">
		<Card className={aptranetUIMerge('bg-transparent h-max w-max border-gray-700', props.className)}>
			<CardContent className="mt-4 pb-2 flex flex-col items-center">
				{props.icon ? <props.icon className="w-5" /> : props.customIcon ? <img src={props.customIcon} alt={props.title + ' Icon'} /> : undefined}
			</CardContent>
			<CardFooter>
				<p>{props.title}</p>
			</CardFooter>
		</Card>
	</ToggleGroupItem>
)

export { ToggleGroup, ToggleGroupItem, ToggleGroupItemCard }
