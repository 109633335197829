import { type ReactNode } from 'react'
import { Card, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'

interface ContentUnavailableProps {
	title: string,
	description: string,
	imageURL: string,
	imageAlt: string,
	children?: ReactNode
}

const ContentUnavailable = (props: ContentUnavailableProps) => (
	<div className="w-full flex my-2 lg:my-20 justify-center">
		<Card className="mx-4 h-full lg:mx-0 lg:h-full lg:w-[1200px]">
			<CardHeader className="items-center mt-4">
				<img src={props.imageURL} alt={props.imageAlt}
						 className="w-64" />
				<CardTitle className="text-center text-4xl">{props.title}</CardTitle>
				<CardDescription className="text-center w-8/12">{props.description}</CardDescription>
				<div className="flex flex-col items-center mt-4">
					{props.children}
				</div>
			</CardHeader>
		</Card>
	</div>)

export default ContentUnavailable