import { type Dispatch, type SetStateAction } from 'react'
import {
	aptranetAPIKeysTableColumns,
} from 'aptranet-ui/components/management-console/home/developers/api-keys/api-keys-table/columns.tsx'

import { type APIKey } from '../../../../../../../api/types/central.ts'
import { type RowSelectionState } from '@tanstack/react-table'
import { DataTable } from 'aptranet-ui/components/ui/data-table.tsx'

interface APIKeysTableProps {
	apiKeys: APIKey[],
	rowSelection: RowSelectionState,
	setRowSelection: Dispatch<SetStateAction<object>>,
	openRollKeyModal: (apiKey: APIKey) => void,
	openDeleteAPIKeyModal: (apiKey: APIKey) => void
}

const APIKeysTable = (props: APIKeysTableProps) => {
	return <div className="pt-2">
		<DataTable
			columns={aptranetAPIKeysTableColumns(props.openRollKeyModal, props.openDeleteAPIKeyModal)} data={props.apiKeys}
			searchBy="access_key"
			searchByTitle="Access Key"
			enableRowSelection={true}
			rowSelection={props.rowSelection}
			setRowSelection={props.setRowSelection} />
	</div>
}

export default APIKeysTable