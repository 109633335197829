import { type ColumnDef } from '@tanstack/react-table'
import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon, MoreHorizontalIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Badge } from 'aptranet-ui/components/ui/badge.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Checkbox } from 'aptranet-ui/components/ui/checkbox'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from 'aptranet-ui/components/ui/dropdown-menu.tsx'
import { Separator } from 'aptranet-ui/components/ui/separator.tsx'

import { type APIKey } from '../../../../../../../api/types/central.ts'
import { getRouteList } from '../../../../../../../routes/routes.tsx'

export const aptranetAPIKeysTableColumns = (
	openRollKeyModal: (apiKey: APIKey) => void,
	openDeleteAPIKeyModal: (apiKey: APIKey) => void
): ColumnDef<APIKey>[] => [
	{
		id: 'select',
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" disabled={!row.getCanSelect()} />
		),
		enableSorting: false,
		enableHiding: false,
	},
	{
		accessorKey: 'access_key',
		header: ({ column }) => {
			return (
				<Button size="sm" variant="link" className="ps-0 text-muted-foreground" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
					Access Key
					{column.getIsSorted() === 'asc' ? (
						<ArrowUpIcon className="ml-2 h-4 w-4" />
					) : column.getIsSorted() === 'desc' ? (
						<ArrowDownIcon className="ml-2 h-4 w-4" />
					) : (
						<ArrowUpDown className="ml-2 h-4 w-4" />
					)}
				</Button>
			)
		},
		cell: ({ row }) => row.getValue('access_key'),
	},
	{
		accessorKey: 'name',
		header: 'Name',
	},
	{
		accessorKey: 'status',
		header: 'Status',
		cell: ({ row }) =>
			row.getValue('status') === 1 ? (
				<Badge variant="success">Available</Badge>
			) : row.getValue('status') === 2 ? (
				<Badge variant="warning">Not Available</Badge>
			) : (
				<Badge variant="destructive">Suspended</Badge>
			),
	},
	{
		accessorKey: 'created_at',
		header: 'Created At',
		cell: ({ row }) =>
			new Date(row.getValue('created_at')).toLocaleDateString('en-GB', {
				day: '2-digit',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			}),
	},
	{
		id: 'actions',
		enableHiding: false,
		cell: ({ row }) => {
			const apiKey = row.original
			const navigate = useNavigate()

			return (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" className="h-8 w-8 p-0">
							<span className="sr-only">Open menu</span>
							<MoreHorizontalIcon className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						<DropdownMenuLabel>Actions</DropdownMenuLabel>
						<DropdownMenuItem
							onClick={() =>
								navigate(getRouteList().home.developers_edit_api_key_permissions.return_dynamic_path(apiKey.id), {
									state: {
										api_key_name: apiKey.name,
										api_key_status: apiKey.status,
									},
								})
							}
							className="pe-5 cursor-pointer"
						>
							Edit
						</DropdownMenuItem>
						<DropdownMenuItem onClick={() => openRollKeyModal(apiKey)} className="mt-0 pe-5 cursor-pointer">
							Roll Secret
						</DropdownMenuItem>
						<DropdownMenuItem
							onClick={() => {
								navigator.clipboard.writeText(apiKey.access_key)
								toast.info('Access Key copied to clipboard.')
							}}
							className="pe-5 cursor-pointer"
						>
							Copy Access Key
						</DropdownMenuItem>
						<Separator className="my-2" />
						<DropdownMenuItem onClick={() => openDeleteAPIKeyModal(apiKey)} className="bg-destructive text-destructive-foreground mt-2 cursor-pointer">
							Delete Key
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			)
		},
	},
]
