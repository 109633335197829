import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { type TLSConfiguration } from '../../../../api/types/cdn.tsx'
import { getDistributionTLSConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { TLSConfigurationForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

const TLSConfiguration = () => {
	const { distributionID } = useParams()
	const tlsConfigurationRef = useRef<HTMLFormElement>(null)

	const [tlsConfigurationData, setTLSConfigurationData] = useState<TLSConfiguration | null>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateTLSConfigurationLoading, setUpdateTLSConfigurationLoading] = useState<boolean>(false)
	const [resetForm, setResetForm] = useState<{ run: () => void }>({ run: () => undefined })

	const fetchTLSConfiguration = () => {
		setTLSConfigurationData(null)
		getDistributionTLSConfiguration(Number(distributionID)).then((tlsConfiguration) => setTLSConfigurationData(tlsConfiguration))
	}

	useEffect(() => {
		fetchTLSConfiguration()
	}, [])

	return (
		<Content title="TLS Configuration" subtitle="Manage your Distribution's TLS Configuration.">
			{tlsConfigurationData ? (
				<>
					<TLSConfigurationForm
						ref={tlsConfigurationRef}
						distributionID={Number(distributionID)}
						tlsConfigurationData={tlsConfigurationData!}
						setResetForm={setResetForm}
						setUnsavedChangesShown={setUnsavedChangesShown}
						setUpdateTLSConfigurationLoading={setUpdateTLSConfigurationLoading}
						refreshTLSConfigurationData={fetchTLSConfiguration}
					/>
					<UnsavedChangesPopup
						shown={unsavedChangesShown}
						saveAction={() => tlsConfigurationRef.current?.requestSubmit()}
						cancelAction={() => resetForm.run()}
						cancelButtonText="Discard Changes"
						saveButtonText="Update TLS Configuration"
						saveButtonLoading={updateTLSConfigurationLoading}
					/>
				</>
			) : (
				<div className="flex flex-col items-center gap-3">
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-28" />
					<Skeleton className="w-full max-w-4xl h-36" />
					<Skeleton className="w-full max-w-4xl h-36" />
				</div>
			)}
		</Content>
	)
}

export default TLSConfiguration
