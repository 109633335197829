import requestInterceptor from '../interceptor.tsx'
import { type Quotas } from '../types/central.ts'

export const getQuotas = (): Promise<Quotas> => new Promise((resolve, reject) =>
	requestInterceptor
		.get('/central/quotas', {
			params: {
				project: localStorage.getItem("selected_project_id")
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)