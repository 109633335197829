import { type Dispatch, forwardRef, type ReactNode, type SetStateAction } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'aptranet-ui/components/ui/form'
import { Input } from 'aptranet-ui/components/ui/input'
import { Textarea } from 'aptranet-ui/components/ui/textarea.tsx'
import { updateProjectSettings } from '../../../api/central/projects.tsx'

const formSchema = z.object({
	project_name: z.string().min(4).max(64),
	project_description: z.string().min(0).max(200),
})

interface changeProjectSettingsProps {
	setUpdateLoading: Dispatch<SetStateAction<boolean>>
}

const ProjectSettingsForm = forwardRef<HTMLFormElement, changeProjectSettingsProps>((props, ref): ReactNode => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			project_name: localStorage.getItem('selected_project_name') || '',
			project_description: localStorage.getItem('selected_project_description') || '',
		},
	})

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdateLoading(true)
		updateProjectSettings(values.project_name, values.project_description).then(() => {
			localStorage.setItem('selected_project_name', values.project_name)
			localStorage.setItem('selected_project_description', values.project_description)
			props.setUpdateLoading(false)
			window.location.reload()
		}).catch(() => props.setUpdateLoading(false))
	}

	return <Form {...form}>
		<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
			<FormField
				control={form.control}
				name="project_name"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Project Name</FormLabel>
						<FormControl>
							<Input placeholder="My Awesome Project" {...field} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="project_description"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Project Description</FormLabel>
						<FormControl>
							<Textarea placeholder="Just a cool project." {...field} />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		</form>
	</Form>
})

export default ProjectSettingsForm