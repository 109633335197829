import Content from 'aptranet-ui/components/management-console/generic/content.tsx'

import { Card, CardContent, CardFooter, CardHeader } from 'aptranet-ui/components/ui/card'

import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { ExternalLinkIcon } from 'lucide-react'

import Lottie from 'react-lottie'
import topRightGradientData from '../../assets/animations/top-right-gradient.json'
import bottomGradientData from '../../assets/animations/bottom-gradient.json'


const Climate = () => {
	return <Content title="Climate" subtitle="Aptranet commitment towards carbon removal.">
		<Card>
			<CardHeader className="flex items-end p-0">
				<div className="pointer-events-none h-[40rem]">
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: topRightGradientData,
					}} />
				</div>
			</CardHeader>
			<CardContent className="flex flex-col items-center pb-0 mt-[-420px]">
				<h1 className="font-bold text-3xl">We're accelerating carbon removal</h1>
				<h2 className="font-normal text-lg w-[40%] text-center">We work with a multidisciplinary group of scientific
					experts to find and evaluate the most promising carbon removal technologies.</h2>
				<Button className="mt-6" asChild>
					<a href="https://climate.aptranet.com/" target="_blank">Being our customer means helping our planet. Learn
						more at climate.aptranet.com <ExternalLinkIcon size="18" className="ms-1" /></a>
				</Button>
			</CardContent>
			<CardFooter className="flex justify-center pb-0">
				<div className="pointer-events-none">
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: bottomGradientData,
					}} />
				</div>
			</CardFooter>
		</Card>

	</Content>
}

export default Climate