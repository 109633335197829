import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { z } from 'zod'

import { type Dispatch, type ReactNode, type SetStateAction, forwardRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Card, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { Form, FormControl, FormField, FormItem } from 'aptranet-ui/components/ui/form.tsx'
import { Separator } from 'aptranet-ui/components/ui/separator.tsx'
import { Switch } from 'aptranet-ui/components/ui/switch.tsx'

import { updateDistributionNetworkConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { type NetworkConfiguration } from '../../../../api/types/cdn.tsx'

interface NetworkConfigurationFormProps {
	distributionID: number
	networkConfigurationData: NetworkConfiguration
	setResetForm: Dispatch<SetStateAction<{ run: () => void }>>
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setUpdateNetworkConfigurationLoading: Dispatch<SetStateAction<boolean>>
	refreshNetworkConfigurationData: () => void
}

const brotliSupportedMimeTypes = [
	'application/javascript',
	'application/json',
	'application/vnd.ms-fontobject',
	'application/wasm',
	'application/x-font-ttf',
	'application/x-javascript',
	'application/xml',
	'application/xml+rss',
	'image/svg+xml',
	'image/x-icon',
	'text/css',
	'text/html',
	'text/javascript',
	'text/plain',
	'text/xml',
]

const formSchema = z.object({
	compression_configuration: z.object({
		gzip: z.object({ enabled: z.boolean().optional() }),
		brotli: z.object({
			enabled: z.boolean().optional(),
			// @ts-ignore
			mime_types: z.array(z.enum(brotliSupportedMimeTypes)),
		}),
		pull_precompressed_content: z.object({ enabled: z.boolean().optional() }),
	}),

	websockets_support: z.boolean().optional(),
})

const NetworkConfigurationForm = forwardRef<HTMLFormElement, NetworkConfigurationFormProps>((props, ref): ReactNode => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			compression_configuration: {
				gzip: { enabled: props.networkConfigurationData.compression_configuration.gzip.enabled },
				brotli: {
					enabled: props.networkConfigurationData.compression_configuration.brotli.enabled,
					mime_types: props.networkConfigurationData.compression_configuration.brotli.mime_types,
				},
				pull_precompressed_content: { enabled: props.networkConfigurationData.compression_configuration.pull_precompressed_content.enabled },
			},
			websockets_support: props.networkConfigurationData.websockets_support,
		},
	})

	const gzipCompressionEnabled = form.watch('compression_configuration.gzip.enabled')
	const brotliCompressionEnabled = form.watch('compression_configuration.brotli.enabled')
	const pullPrecompressedContentEnabled = form.watch('compression_configuration.pull_precompressed_content.enabled')

	const websocketsSupportEnabled = form.watch('websockets_support')

	useEffect(() => props.setResetForm({ run: form.reset }), [])
	useEffect(() => props.setUnsavedChangesShown(form.formState.isDirty), [form.formState.isDirty])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdateNetworkConfigurationLoading(true)
		updateDistributionNetworkConfiguration(props.distributionID, {
			compression_configuration: {
				gzip: {
					enabled: values.compression_configuration.gzip.enabled || false,
				},
				brotli: {
					enabled: values.compression_configuration.brotli.enabled || false,
					mime_types: values.compression_configuration.brotli.mime_types,
				},
				pull_precompressed_content: {
					enabled: values.compression_configuration.pull_precompressed_content.enabled || false,
				},
			},
			bandwidth_limiter_configuration: {
				enabled: false,
				limit_type: 'static',
				limit_speed: 500,
				limit_buffer: 0,
			},
			websockets_support: values.websockets_support || false,
		})
			.then(() => {
				props.setUpdateNetworkConfigurationLoading(false)
				toast.success('Network configuration updated successfully. Please allow up to 15 minutes for the changes to fully propagate to our edge.')
				props.refreshNetworkConfigurationData()
			})
			.catch(() => props.setUpdateNetworkConfigurationLoading(false))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<div className="flex flex-col items-center gap-4 w-full">
					<h2 className="w-full max-w-4xl text-muted-foreground text-2xl text-start font-semibold">IPv6 Settings</h2>

					<Card className="w-full max-w-4xl border-primary">
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle>IPv6 Support</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									Controls support for IPv6, the next generation Internet Protocol, allowing for more efficient routing and a vastly larger address space. The
									Aptranet Edge has full IPv6 support, and all distributions now have it enabled by default.
								</CardDescription>
							</div>
							<Switch checked disabled />
						</CardHeader>
					</Card>

					<Separator className="w-full max-w-4xl my-3	" />
					<h2 className="w-full max-w-4xl text-muted-foreground text-2xl text-start font-semibold">Compression Settings</h2>

					<Card className={'w-full max-w-4xl' + (gzipCompressionEnabled ? ' border-primary' : '')}>
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle>Gzip Compression</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									To optimize delivery speed, our servers will leverage Gzip compression to reduce file sizes before transmission. This minimizes data transfer
									from the origin server, resulting in faster content loading for end users.
									<br />
									<br />
									<strong>
										We support Gzip Compression for the following data types: application/dash+xml; application/javascript; application/json;
										application/x-javascript; application/xml; application/vnd.apple.mpegurl; application/xml+rss; application/wasm; text/css; text/html;
										text/javascript; text/plain; text/vtt; text/xml; image/svg+xml.
									</strong>
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="compression_configuration.gzip.enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} disabled={pullPrecompressedContentEnabled} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
					</Card>
					{/*<Card className={'w-full max-w-4xl' + (brotliCompressionEnabled ? ' border-primary' : '')}>*/}
					{/*	<CardHeader className="pb-0 flex flex-row justify-between items-center">*/}
					{/*		<div>*/}
					{/*			<CardTitle>Brotli Compression</CardTitle>*/}
					{/*			<CardDescription className="mt-2 w-3/4">*/}
					{/*				This feature enables Brotli compression at the edge. This offloads compression from the origin server, ensuring it only delivers uncompressed*/}
					{/*				assets for optimal efficiency.*/}
					{/*				<br />*/}
					{/*				<br />*/}
					{/*				<strong>In order to enable Brotli Compression you need to have Origin Shielding active.</strong>*/}
					{/*			</CardDescription>*/}
					{/*		</div>*/}
					{/*		<FormField*/}
					{/*			control={form.control}*/}
					{/*			name="compression_configuration.brotli.enabled"*/}
					{/*			render={({ field }) => (*/}
					{/*				<FormControl>*/}
					{/*					<FormItem>*/}
					{/*						<FormControl>*/}
					{/*							<Switch checked={field.value} onCheckedChange={field.onChange} disabled={pullPrecompressedContentEnabled} />*/}
					{/*						</FormControl>*/}
					{/*					</FormItem>*/}
					{/*				</FormControl>*/}
					{/*			)}*/}
					{/*		/>*/}
					{/*	</CardHeader>*/}
					{/*	<CardContent>*/}
					{/*		<Accordion type="single" className="mx-1" value={brotliCompressionEnabled ? 'brotliCompressionEnabled' : 'none'} collapsible>*/}
					{/*			<AccordionItem value="brotliCompressionEnabled" className="border-b-0">*/}
					{/*				<AccordionContent>*/}
					{/*					<div className="mt-4 mx-1">*/}
					{/*						<p className="font-semibold mb-2">Select the Mime Types that Brotli Compression will apply to:</p>*/}
					{/*						<FormField*/}
					{/*							control={form.control}*/}
					{/*							name="compression_configuration.brotli.mime_types"*/}
					{/*							render={() => (*/}
					{/*								<div className="space-y-2">*/}
					{/*									{brotliSupportedMimeTypes.map((mimeType) => (*/}
					{/*										<FormItem className="flex flex-row items-start space-x-1.5 space-y-0 rounded-md">*/}
					{/*											<FormControl>*/}
					{/*												<Checkbox*/}
					{/*													checked={form.getValues('compression_configuration.brotli.mime_types').includes(mimeType)}*/}
					{/*													disabled={mimeType === 'text/html'}*/}
					{/*													onCheckedChange={(checked) => {*/}
					{/*														const brotliEnabledMimeTypes = form.getValues('compression_configuration.brotli.mime_types')*/}
					{/*														if (checked) {*/}
					{/*															if (!brotliEnabledMimeTypes.includes(mimeType)) {*/}
					{/*																form.setValue('compression_configuration.brotli.mime_types', [...brotliEnabledMimeTypes, mimeType])*/}
					{/*															}*/}
					{/*														} else {*/}
					{/*															form.setValue(*/}
					{/*																'compression_configuration.brotli.mime_types',*/}
					{/*																brotliEnabledMimeTypes.filter((val) => val !== mimeType)*/}
					{/*															)*/}
					{/*														}*/}
					{/*													}}*/}
					{/*												/>*/}
					{/*											</FormControl>*/}
					{/*											<FormLabel>*/}
					{/*												{mimeType} {mimeType === 'text/html' && '(required)'}*/}
					{/*											</FormLabel>*/}
					{/*										</FormItem>*/}
					{/*									))}*/}
					{/*								</div>*/}
					{/*							)}*/}
					{/*						/>*/}
					{/*					</div>*/}
					{/*				</AccordionContent>*/}
					{/*			</AccordionItem>*/}
					{/*		</Accordion>*/}
					{/*	</CardContent>*/}
					{/*</Card>*/}
					<Card className={'w-full max-w-4xl' + (pullPrecompressedContentEnabled ? ' border-primary' : '')}>
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle>Pull Pre-Compressed Content</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									If enabled, our servers will pull pre-compressed content directly from the origin server. This approach requires the origin server to be
									equipped with compression capabilities. By delivering pre-compressed content, users will benefit from reduced file sizes. This translates to
									lower bandwidth consumption between the origin server and our cache servers, ultimately resulting in faster content delivery for end users.
									Our edge no longer needs to perform on-the-fly compression, further optimizing transfer times.
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="compression_configuration.pull_precompressed_content.enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} disabled={gzipCompressionEnabled || brotliCompressionEnabled} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
					</Card>

					<Separator className="w-full max-w-4xl my-3" />
					<h2 className="w-full max-w-4xl text-muted-foreground text-2xl text-start font-semibold">Websockets Settings</h2>

					<Card className={'w-full max-w-4xl' + (websocketsSupportEnabled ? ' border-primary' : '')}>
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle>Websockets Support</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									Allow Websockets connections to an origin server through the Aptranet Edge. Data over a WebSocket connection can flow in both directions for
									full-duplex communication.
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="websockets_support"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
					</Card>
				</div>
			</form>
		</Form>
	)
})

export default NetworkConfigurationForm
