import requestInterceptor from '../interceptor.tsx'
import { type CreateOriginGroup, type OriginGroup, type OriginGroupInfo } from '../types/cdn.tsx'

export const listOriginGroups = (limit?: number): Promise<OriginGroupInfo[]> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/origin_groups', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					limit: limit,
				},
			})
			.then((res) => (res.status === 204 ? resolve([]) : resolve(res.data)))
			.catch((err) => reject(err.response.data))
	)

export const getOriginGroup = (originGroupID?: number): Promise<OriginGroup> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.get('/cdn/origin_groups/details', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					origin_group: originGroupID,
				},
			})
			.then((res) => resolve(res.data))
			.catch((err) => reject(err.response.data))
	)

export const createOriginGroup = (originGroup: CreateOriginGroup): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.post('/cdn/origin_groups', originGroup, {
				params: {
					project: localStorage.getItem('selected_project_id'),
				},
			})
			.then((res) => resolve(res.status === 201))
			.catch((err) => reject(err.response.data))
	)

export const updateOriginGroup = (originGroupID: number, originGroup: CreateOriginGroup): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.put('/cdn/origin_groups', originGroup, {
				params: {
					project: localStorage.getItem('selected_project_id'),
					origin_group: originGroupID,
				},
			})
			.then((res) => resolve(res.status === 200))
			.catch((err) => reject(err.response.data))
	)

export const deleteOriginGroup = (originGroupID: number): Promise<boolean> =>
	new Promise((resolve, reject) =>
		requestInterceptor
			.delete('/cdn/origin_groups', {
				params: {
					project: localStorage.getItem('selected_project_id'),
					origin_group: originGroupID,
				},
			})
			.then((res) => resolve(res.status === 200))
			.catch((err) => reject(err.response.data))
	)
