import requestInterceptor from '../interceptor.tsx'
import { type APIKey } from '../types/central.ts'

export interface Permissions {
	[key: string]: {
		[key: string]: boolean
	}
}

export const listAPIKeys = (limit?: number): Promise<APIKey[]> => new Promise((resolve, reject) =>
	requestInterceptor
		.get('/central/developers/api_keys', {
			params: {
				project: localStorage.getItem('selected_project_id'),
				limit: limit,
			},
		})
		.then((res) => res.status === 204 ? resolve([]) : resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const createAPIKey = (name: string, permissions: Permissions): Promise<APIKey> => new Promise((resolve, reject) =>
	requestInterceptor
		.post('/central/developers/api_keys', {
			name: name,
			permissions: permissions,
		}, {
			params: {
				project: localStorage.getItem('selected_project_id'),
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const updateAPIKey = (apiKeyID: number, name: string, status: number, permissions: Permissions): Promise<APIKey> => new Promise((resolve, reject) =>
	requestInterceptor
		.put('/central/developers/api_keys', {
			name: name,
			permissions: permissions,
			status: status,
		}, {
			params: {
				project: localStorage.getItem('selected_project_id'),
				api_key: apiKeyID,
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const listAPIKeyPermissions = (apiKeyID: number): Promise<Permissions> => new Promise((resolve, reject) =>
	requestInterceptor
		.get('/central/developers/api_keys/permissions', {
			params: {
				project: localStorage.getItem('selected_project_id'),
				api_key: apiKeyID,
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const rollAPIKeySecret = (apiKeyID: number): Promise<{ secret: string }> => new Promise((resolve, reject) =>
	requestInterceptor
		.post('/central/developers/api_keys/roll_secret', {}, {
			params: {
				project: localStorage.getItem('selected_project_id'),
				api_key: apiKeyID,
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)

export const deleteAPIKey = (apiKeyID: number): Promise<boolean> => new Promise((resolve, reject) =>
	requestInterceptor
		.delete('/central/developers/api_keys', {
			params: {
				project: localStorage.getItem('selected_project_id'),
				api_key: apiKeyID,
			},
		})
		.then((res) => resolve(res.status === 200))
		.catch((err) => reject(err.response.data)),
)

export interface CreateWebhooksPortalAccessResult { portal_url: string }
export const createWebhooksPortalAccess = (): Promise<CreateWebhooksPortalAccessResult> => new Promise((resolve, reject) =>
	requestInterceptor
		.get('/central/developers/webhooks_portal', {
			params: {
				project: localStorage.getItem('selected_project_id'),
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)