import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "aptranet-ui/components/ui/card"

const DevelopersOverviewSection = () => {
	return <>
		<div className="grid grid-cols-3 space-x-4">
			<Card>
				<CardHeader>
					<CardTitle>API Requests</CardTitle>
					<CardDescription>Requests made using the API.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Webhooks</CardTitle>
					<CardDescription>Webhooks sent to your endpoints.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Webhooks Response Times</CardTitle>
					<CardDescription>Time took for webhooks to receive a response from your endpoints.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
		</div>
		<div className="grid grid-cols-3 space-x-4 mt-2">
			<Card>
				<CardHeader>
					<CardTitle>Requests by method</CardTitle>
					<CardDescription>Requests grouped by request method.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Console Requests</CardTitle>
					<CardDescription>Requests made by the management console.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Error Statuses</CardTitle>
					<CardDescription>Errors grouped by HTTP status.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
		</div>
		<div className="grid grid-cols-3 space-x-4 mt-2">
			<Card>
				<CardHeader>
					<CardTitle>Requests by service</CardTitle>
					<CardDescription>Requests grouped by Aptranet service.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Requests by originator</CardTitle>
					<CardDescription>Requests grouped by request originator.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Requests by country</CardTitle>
					<CardDescription>Requests grouped by originator country.</CardDescription>
				</CardHeader>
				<CardContent>
					<p>Chart</p>
				</CardContent>
			</Card>
		</div>
	</>
}

export default DevelopersOverviewSection