import { Navigate } from 'react-router-dom'

/* Distributions */
import Distributions from '../pages/cdn/distributions/distributions.tsx'
import CreateDistribution from '../pages/cdn/distributions/create-distribution.tsx'
import DistributionAnalytics from '../pages/cdn/distributions/distribution-analytics.tsx'
import CacheConfiguration from '../pages/cdn/distributions/distribution-configuration/cache.tsx'
import NetworkConfiguration from '../pages/cdn/distributions/distribution-configuration/network.tsx'
import TLSConfiguration from '../pages/cdn/distributions/distribution-configuration/tls.tsx'
import OriginConfiguration from '../pages/cdn/distributions/distribution-configuration/origin.tsx'

/*Origin Groups*/
import CreateOriginGroup from '../pages/cdn/origin-groups/create-origin-group.tsx'
import EditOriginGroup from '../pages/cdn/origin-groups/edit-origin-group.tsx'
import OriginGroups from '../pages/cdn/origin-groups/origin-groups.tsx'

/* Other */
import ServiceNotActive from '../pages/cdn/service-not-active.tsx'
import { type DynamicRoute, type Route } from './routes.tsx'
import HeadersConfiguration from '../pages/cdn/distributions/distribution-configuration/headers.tsx'
import SecurityConfiguration from '../pages/cdn/distributions/distribution-configuration/security.tsx'

export interface CDNRoutes {
	base: Route
	service_not_active: Route
	distributions: Route
	distributions_create: Route
	distribution_base: Route

	distribution_analytics: DynamicRoute
	distribution_cache: DynamicRoute
	distribution_network: DynamicRoute
	distribution_tls: DynamicRoute
	distribution_origin: DynamicRoute
	distribution_headers: DynamicRoute
	distribution_security: DynamicRoute
	distribution_image_optimization: DynamicRoute

	origin_groups: Route
	origin_group_create: Route
	origin_group_edit_base: Route
	origin_group_edit: DynamicRoute

	purge_content: Route
	prefetch_content: Route
}

const cdnRoutes: CDNRoutes = {
	base: {
		path: '/cdn',
		element: <Navigate to="/cdn/distributions" />,
	},
	service_not_active: {
		path: '/cdn/service-not-active',
		element: <ServiceNotActive />,
	},
	distributions: {
		path: '/cdn/distributions',
		element: <Distributions />,
	},
	distributions_create: {
		path: '/cdn/distributions/create',
		element: <CreateDistribution />,
	},
	distribution_base: {
		path: '/cdn/distributions/:distributionID',
		element: <Navigate to="analytics" />,
	},

	distribution_analytics: {
		path: '/cdn/distributions/:distributionID/analytics',
		element: <DistributionAnalytics />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/analytics',
	},
	distribution_cache: {
		path: '/cdn/distributions/:distributionID/cache',
		element: <CacheConfiguration />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/cache',
	},
	distribution_network: {
		path: '/cdn/distributions/:distributionID/network',
		element: <NetworkConfiguration />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/network',
	},
	distribution_tls: {
		path: '/cdn/distributions/:distributionID/tls',
		element: <TLSConfiguration />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/tls',
	},
	distribution_origin: {
		path: '/cdn/distributions/:distributionID/origin',
		element: <OriginConfiguration />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/origin',
	},
	distribution_headers: {
		path: '/cdn/distributions/:distributionID/headers',
		element: <HeadersConfiguration />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/headers',
	},
	distribution_security: {
		path: '/cdn/distributions/:distributionID/security',
		element: <SecurityConfiguration />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/security',
	},
	distribution_image_optimization: {
		path: '/cdn/distributions/:distributionID/image-optimization',
		element: <DistributionAnalytics />,
		return_dynamic_path: (distributionID) => '/cdn/distributions/' + distributionID + '/image-optimization',
	},

	origin_groups: {
		path: '/cdn/origin-groups',
		element: <OriginGroups />,
	},
	origin_group_create: {
		path: '/cdn/origin-groups/create',
		element: <CreateOriginGroup />,
	},
	origin_group_edit_base: {
		path: '/cdn/origin-groups/:originGroupID',
		element: <Navigate to="edit" />,
	},
	origin_group_edit: {
		path: '/cdn/origin-groups/:originGroupID/edit',
		element: <EditOriginGroup />,
		return_dynamic_path: (originGroupID) => '/cdn/origin-groups/' + originGroupID + '/edit',
	},

	purge_content: {
		path: '/cdn/purge-content',
		element: <p>Purge Content</p>,
	},
	prefetch_content: {
		path: '/cdn/prefetch-content',
		element: <p>Prefetch Content</p>,
	},
}

export default cdnRoutes
