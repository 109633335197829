import { useState } from 'react'
import { LoadingButton } from 'aptranet-ui/components/ui/button.tsx'

import ContentUnavailable from 'aptranet-ui/components/management-console/generic/content-unavailable.tsx'
import { createSession } from '../../api/central/billing.tsx'
import { Card, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'

const ServiceNotActive = () => {
	const [activateServiceButtonLoading, setActivateServiceButtonLoading] = useState<boolean>(false)

	const activateService = () => {
		setActivateServiceButtonLoading(true)

		createSession("cdn").then(billingSession => {
			// @ts-ignore
			const cbInstance = window.Chargebee.getInstance()

			cbInstance.openCheckout({
				hostedPage: () => new Promise((resolve) => {
					resolve({
						id: billingSession.id,
						url: billingSession.url,
						type: 'checkout_new',
					})
				}),
				error: () => {
					setActivateServiceButtonLoading(false)
				},
				success: () => {
					setActivateServiceButtonLoading(false)
					// Optional
					// will be called when a successful checkout happens.
				},
				close: () => {
					setActivateServiceButtonLoading(false)
				},
			})
		}).catch(() => setActivateServiceButtonLoading(false))
	}

	return <ContentUnavailable title="Aptranet CDN" description="Empower Your Digital Edge with Next-Generation Content Delivery."
														 imageURL="https://assets.aptranetedge.com/management-console/images/graphics/cdn.svg"
														 imageAlt="CDN Image">
		<p className="font-semibold">You need to activate the CDN service to continue.</p>
		<LoadingButton className="mt-5" loading={activateServiceButtonLoading} text="Activate Service" onClick={() => activateService()} />

		<div className="mt-16">
			<h2 className="text-2xl text-center">CDN Features:</h2>
			<div className="grid xl:grid-cols-3 gap-3 mt-3 mb-12">
				<Card>
					<CardHeader className="flex flex-col items-center pt-0">
						<img src="https://assets.aptranetedge.com/management-console/images/graphics/fast-loading.svg"
								 alt="Fast Loading Image"
								 className="w-64 h-60" />
						<CardTitle>Supercharge Loading Times</CardTitle>
						<CardDescription className="text-center">
							Speed up your Websites, Content and APIs with our globally distributed network of high-end servers, and private interconnections.
						</CardDescription>
					</CardHeader>
				</Card>
				<Card>
					<CardHeader className="flex flex-col items-center pt-0">
						<img src="https://assets.aptranetedge.com/management-console/images/graphics/secure-server.svg"
								 alt="Payment Method Image"
								 className="w-64 h-60" />
						<CardTitle>Protect your infrastructure</CardTitle>
						<CardDescription className="flex flex-col items-center text-center">
							Protect your infrastructure from various threats at L3, L4 and L7 such as the OWASP top 10, DDoS attacks and more.
							<br/>
							<br/>
							<Badge variant="primary">Powered by Aptranet Shield</Badge>
						</CardDescription>
					</CardHeader>
				</Card>
				<Card>
					<CardHeader className="flex flex-col items-center pt-0">
						<img src="https://assets.aptranetedge.com/management-console/images/graphics/wallet-2.svg"
								 alt="Invoice Image"
								 className="w-64 h-60" />
						<CardTitle>Reduce Operational Costs</CardTitle>
						<CardDescription className="text-center">
							Reduce operational costs by saving on resources and egress charges from your infrastructure provider.
						</CardDescription>
					</CardHeader>
				</Card>
			</div>
		</div>
	</ContentUnavailable>
}

export default ServiceNotActive