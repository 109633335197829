import { useEffect, useState } from 'react'
import { type OrganizationMembershipResource } from '@clerk/types'

import Head from '../../../layout/head.tsx'
import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { OrganizationSwitcher, useOrganization } from '@clerk/clerk-react'

import ProjectMembersTable
	from 'aptranet-ui/components/management-console/home/project-members/project-members-table/table.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import ContentUnavailable from 'aptranet-ui/components/management-console/generic/content-unavailable.tsx'

const ProjectMembersUnavailable = () => {
	return <ContentUnavailable title="Project Members" description="The project members feature allows you and your team to collaborate and manage your Aptranet resources
				together. Project Members are a feature available only to projects inside organizations."
														 imageURL="https://assets.aptranetedge.com/management-console/images/graphics/team.svg"
														 imageAlt="Project Members"><p className="mt-4 mb-2">Select or Create an Organization:</p>
		<OrganizationSwitcher appearance={{
			elements: {
				avatarImage: 'h-9 w-9',
				avatarBox: 'h-9 w-9',
				userPreviewAvatarContainer: 'w-5',
			},
		}} afterSelectOrganizationUrl="/redirect/projects" afterSelectPersonalUrl="/redirect/projects" />
	</ContentUnavailable>

}

const ProjectMembers = () => {
	const { membership, organization } = useOrganization()
	const [organizationMembers, setOrganizationMembers] = useState<OrganizationMembershipResource[] | null>(null)

	if (!organization) {
		return <>
			<Head title="Project Members" />
			<ProjectMembersUnavailable />
		</>
	}

	useEffect(() => {
		organization.getMemberships().then(members => setOrganizationMembers(members.data))
	}, [])

	return <Content title="Project Members" subtitle="Organization members that have access to this project.">
		{membership?.role === 'org:admin' ? organizationMembers ?
			<ProjectMembersTable organizationMembers={organizationMembers} /> : <>
				<div className="flex justify-end mb-4">
					<Skeleton className="w-[112px] h-[40px]" />
				</div>
				<Skeleton className="h-72" />
			</> : <ContentUnavailable title="Oops! Seems like you don't have access here." description="This area is only accessible by organization admins."
																imageURL="https://assets.aptranetedge.com/management-console/images/graphics/forbidden.svg"
																imageAlt="Forbidden Image"/>}
	</Content>
}

export default ProjectMembers