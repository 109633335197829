import { toast } from 'sonner'

import { type Dispatch, type SetStateAction, useState } from 'react'

import SecretRolledModal from 'aptranet-ui/components/management-console/home/developers/api-keys/secret-rolled-modal.tsx'
import { Alert, AlertDescription, AlertTitle } from 'aptranet-ui/components/ui/alert.tsx'
import { LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { ResponsiveDialog } from 'aptranet-ui/components/ui/responsive-dialog.tsx'

import { InfoIcon } from 'lucide-react'

import { rollAPIKeySecret } from '../../../../../../api/central/developers.tsx'
import { type APIKey } from '../../../../../../api/types/central.ts'

interface RollSecretModalProps {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	apiKey: APIKey
}

const RollSecretModal = (props: RollSecretModalProps) => {
	const [rollSecretLoading, setRollSecretLoading] = useState<boolean>(false)
	const [secretRolledOpen, setSecretRolledOpen] = useState<boolean>(false)
	const [newSecret, setNewSecret] = useState<string>('')

	const rollSecret = () => {
		setRollSecretLoading(true)
		rollAPIKeySecret(props.apiKey.id)
			.then((apiKey) => {
				setRollSecretLoading(false)
				setNewSecret(apiKey.secret)
				toast.success('Secret rolled successfully.')
				setSecretRolledOpen(true)
				props.setOpen(false)
			})
			.catch(() => setRollSecretLoading(false))
	}

	return (
		<>
			<SecretRolledModal open={secretRolledOpen} setOpen={setSecretRolledOpen} newSecret={newSecret} />
			<ResponsiveDialog
				title="Roll Secret"
				open={props.open}
				setOpen={props.setOpen}
				closeButtonText="Cancel"
				closeButtonDisabled={rollSecretLoading}
				footerChildren={<LoadingButton variant="destructive" text="Roll Secret" loading={rollSecretLoading} onClick={() => rollSecret()} />}
			>
				<p>
					Are you sure you would like to roll the secret of <span className="font-semibold">{props.apiKey.name}</span>?
				</p>

				<Alert>
					<InfoIcon className="h-4 w-4" />
					<AlertTitle>Heads up!</AlertTitle>
					<AlertDescription>The old secret will stop being valid which can cause systems to get Forbidden errors.</AlertDescription>
				</Alert>
			</ResponsiveDialog>
		</>
	)
}

export default RollSecretModal
