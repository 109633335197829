import { type TLSConfiguration } from '../../../../api/types/cdn.tsx'
import { type Dispatch, forwardRef, type ReactNode, type SetStateAction, useEffect } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { updateDistributionTLSConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { toast } from 'sonner'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'aptranet-ui/components/ui/form.tsx'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'
import { Accordion, AccordionContent, AccordionItem } from 'aptranet-ui/components/ui/accordion.tsx'
import { ToggleGroup, ToggleGroupItemCard } from 'aptranet-ui/components/ui/toggle-group.tsx'
import { RadioGroup, RadioGroupItem } from 'aptranet-ui/components/ui/radio-group.tsx'
import { Input } from 'aptranet-ui/components/ui/input.tsx'
import { Switch } from 'aptranet-ui/components/ui/switch.tsx'

interface TLSConfigurationFormProps {
	distributionID: number
	tlsConfigurationData: TLSConfiguration
	setResetForm: Dispatch<SetStateAction<{ run: () => void }>>
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setUpdateTLSConfigurationLoading: Dispatch<SetStateAction<boolean>>
	refreshTLSConfigurationData: () => void
}

const formSchema = z.object({
	enable_https: z.boolean().optional(),
	https_redirect: z.boolean().optional(),
	custom_tls_versions_support: z.object({
		enabled: z.boolean().optional(),
		tls_versions: z.object({
			ssl3: z.boolean().optional(),
			tls1: z.boolean().optional(),
			tls1_1: z.boolean().optional(),
			tls1_2: z.boolean().optional(),
			tls1_3: z.boolean().optional(),
		}),
	}),
	custom_sni_hostname: z.object({
		enabled: z.boolean().optional(),
		configuration: z.enum(['match_host_header', 'custom']),
		value: z.string().optional(),
		// .regex(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/),
	}),
})

const TLSConfigurationForm = forwardRef<HTMLFormElement, TLSConfigurationFormProps>((props, ref): ReactNode => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			enable_https: props.tlsConfigurationData.enable_https,
			https_redirect: props.tlsConfigurationData.https_redirect,
			custom_tls_versions_support: {
				enabled: !(
					props.tlsConfigurationData.tls_versions_support.tls1_2 &&
					props.tlsConfigurationData.tls_versions_support.tls1_3 &&
					!props.tlsConfigurationData.tls_versions_support.ssl3 &&
					!props.tlsConfigurationData.tls_versions_support.tls1 &&
					!props.tlsConfigurationData.tls_versions_support.tls1_1
				),
				tls_versions: {
					ssl3: props.tlsConfigurationData.tls_versions_support.ssl3,
					tls1: props.tlsConfigurationData.tls_versions_support.tls1,
					tls1_1: props.tlsConfigurationData.tls_versions_support.tls1_1,
					tls1_2: props.tlsConfigurationData.tls_versions_support.tls1_2,
					tls1_3: props.tlsConfigurationData.tls_versions_support.tls1_3,
				},
			},
			custom_sni_hostname: {
				enabled: props.tlsConfigurationData.custom_sni_hostname.enabled,
				configuration: props.tlsConfigurationData.custom_sni_hostname.configuration,
				value: props.tlsConfigurationData.custom_sni_hostname.value,
			},
		},
	})

	const httpsEnabled = form.watch('enable_https')
	const httpsRedirectEnabled = form.watch('https_redirect')

	const customTLSVersionsSupportEnabled = form.watch('custom_tls_versions_support.enabled')
	const customTLSVersionsSupportSSLv3 = form.watch('custom_tls_versions_support.tls_versions.ssl3')
	const customTLSVersionsSupportTLSv1 = form.watch('custom_tls_versions_support.tls_versions.tls1')
	const customTLSVersionsSupportTLSv1_1 = form.watch('custom_tls_versions_support.tls_versions.tls1_1')
	const customTLSVersionsSupportTLSv1_2 = form.watch('custom_tls_versions_support.tls_versions.tls1_2')
	const customTLSVersionsSupportTLSv1_3 = form.watch('custom_tls_versions_support.tls_versions.tls1_3')

	const customSNIHostnameEnabled = form.watch('custom_sni_hostname.enabled')
	const customSNIHostnameConfiguration = form.watch('custom_sni_hostname.configuration')

	useEffect(() => props.setResetForm({ run: form.reset }), [])
	useEffect(() => props.setUnsavedChangesShown(form.formState.isDirty), [form.formState.isDirty])

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdateTLSConfigurationLoading(true)
		updateDistributionTLSConfiguration(props.distributionID, {
			enable_https: values.enable_https || false,
			https_redirect: values.https_redirect || false,
			enable_http3: false,
			tls_versions_support: {
				ssl3: values.custom_tls_versions_support.tls_versions.ssl3 || false,
				tls1: values.custom_tls_versions_support.tls_versions.tls1 || false,
				tls1_1: values.custom_tls_versions_support.tls_versions.tls1_1 || false,
				tls1_2: values.custom_tls_versions_support.tls_versions.tls1_2 || false,
				tls1_3: values.custom_tls_versions_support.tls_versions.tls1_3 || false,
			},
			custom_sni_hostname: {
				enabled: values.custom_sni_hostname.enabled || false,
				configuration: values.custom_sni_hostname.configuration,
				value: values.custom_sni_hostname.value,
			},
			origin_tls_validation: false
		})
			.then(() => {
				props.setUpdateTLSConfigurationLoading(false)
				toast.success('TLS configuration updated successfully. Please allow up to 15 minutes for the changes to fully propagate to our edge.')
				props.refreshTLSConfigurationData()
			})
			.catch(() => props.setUpdateTLSConfigurationLoading(false))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<div className="flex flex-col items-center gap-4 w-full">
					<h2 className="w-full max-w-4xl text-muted-foreground text-2xl text-start font-semibold">TLS Settings</h2>
					<Card className={'w-full max-w-4xl' + (httpsEnabled ? ' border-primary' : '')}>
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle className="flex items-center">
									Enable HTTPS{' '}
									{!httpsEnabled && (
										<Badge className="ms-2" variant="warning">
											Highly Recommended
										</Badge>
									)}
								</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									For secure and encrypted communication between your web server and users' browsers, implement HTTPS with a Transport Layer Security (TLS)
									certificate. A TLS certificate authenticates the website's identity and encrypts data transmission, protecting sensitive information.
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="enable_https"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch
													checked={field.value}
													onCheckedChange={(value) => {
														field.onChange(value)

														if (!value) {
															form.setValue('https_redirect', false)
														}
													}}
												/>
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
					</Card>
					<Card className={'w-full max-w-4xl' + (httpsRedirectEnabled ? ' border-primary' : '')}>
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle className="flex items-center">
									Redirect HTTP to HTTPS {httpsEnabled && !httpsRedirectEnabled && <Badge className="ms-2">Recommended</Badge>}
								</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									To ensure secure communication, all HTTP requests will be automatically redirected to HTTPS. If you are utilizing custom domains for your
									Distribution, please verify that the custom domains also support HTTPS connections.
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="https_redirect"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} disabled={!httpsEnabled} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
					</Card>
					<Card className={'w-full max-w-4xl' + (customTLSVersionsSupportEnabled ? ' border-primary' : '')}>
						<CardHeader className="pb-0 flex flex-row justify-between items-center">
							<div>
								<CardTitle>Customize TLS Versions Supported</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									This option allows you to modify the supported TLS protocol versions for HTTPS connections. It is recommended to disable insecure protocols
									like SSL 3.0 and TLS 1.0 and 1.1 to improve the security of your communications.
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="custom_tls_versions_support.enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1" value={customTLSVersionsSupportEnabled ? 'customTLSVersionsSupport' : 'none'} collapsible>
								<AccordionItem value="customTLSVersionsSupport" className="border-b-0">
									<AccordionContent>
										<div className="mt-4 mx-1">
											<FormField
												control={form.control}
												name="custom_tls_versions_support.enabled"
												render={() => (
													<FormControl>
														<FormItem>
															<FormLabel>Supported Versions</FormLabel>
															<FormControl>
																<ToggleGroup
																	type="multiple"
																	value={[
																		customTLSVersionsSupportSSLv3 ? 'ssl3' : '',
																		customTLSVersionsSupportTLSv1 ? 'tls1' : '',
																		customTLSVersionsSupportTLSv1_1 ? 'tls1_1' : '',
																		customTLSVersionsSupportTLSv1_2 ? 'tls1_2' : '',
																		customTLSVersionsSupportTLSv1_3 ? 'tls1_3' : '',
																	]}
																	onValueChange={(e) => {
																		form.setValue('custom_tls_versions_support.tls_versions.ssl3', e.includes('ssl3'))
																		form.setValue('custom_tls_versions_support.tls_versions.tls1', e.includes('tls1'))
																		form.setValue('custom_tls_versions_support.tls_versions.tls1_1', e.includes('tls1_1'))
																		form.setValue('custom_tls_versions_support.tls_versions.tls1_2', e.includes('tls1_2'))
																		form.setValue('custom_tls_versions_support.tls_versions.tls1_3', e.includes('tls1_3'))
																	}}
																	className="flex justify-start gap-2"
																>
																	<ToggleGroupItemCard value="ssl3" title="SSLv3" className={customTLSVersionsSupportSSLv3 ? 'border-amber-700' : ''} />
																	<ToggleGroupItemCard value="tls1" title="TLSv1" className={customTLSVersionsSupportTLSv1 ? 'border-amber-700' : ''} />
																	<ToggleGroupItemCard value="tls1_1" title="TLSv1.1" className={customTLSVersionsSupportTLSv1_1 ? 'border-amber-700' : ''} />
																	<ToggleGroupItemCard value="tls1_2" title="TLSv1.2" className={customTLSVersionsSupportTLSv1_2 ? 'border-green-700' : ''} />
																	<ToggleGroupItemCard value="tls1_3" title="TLSv1.3" className={customTLSVersionsSupportTLSv1_3 ? 'border-green-700' : ''} />
																</ToggleGroup>
															</FormControl>
														</FormItem>
													</FormControl>
												)}
											/>
											{(customTLSVersionsSupportSSLv3 || customTLSVersionsSupportTLSv1 || customTLSVersionsSupportTLSv1_1) && (
												<p className="text-amber-700 mt-2 w-3/4">
													Please note: The Internet Engineering Task Force (IETF) officially deprecated SSL 3.0 in June 2015, and TLS versions 1 & 1.1 in March
													2018. We highly recommend that you keep them disabled for security reasons.
												</p>
											)}
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
					<Card className={'w-full max-w-4xl' + (customSNIHostnameEnabled ? ' border-primary' : '')}>
						<CardHeader className="pb-0 flex flex-row justify-between items-center">
							<div>
								<CardTitle>Customize SNI Hostname</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									Server Name Indication (SNI) becomes particularly relevant when your origin utilizes shared hosting or lacks a dedicated IP address. In
									scenarios where the origin server presents multiple certificates, SNI empowers it to identify the appropriate certificate for the established
									connection.
									<br />
									<br />
									<strong>SNI works only when CDN servers leverage HTTPS to fetch content from the origin server.</strong>
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="custom_sni_hostname.enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1" value={customSNIHostnameEnabled ? 'customSNIHostnameEnabled' : 'none'} collapsible>
								<AccordionItem value="customSNIHostnameEnabled" className="border-b-0">
									<AccordionContent>
										<div className="mt-4 mx-1">
											<FormField
												control={form.control}
												name="custom_sni_hostname.configuration"
												render={({ field }) => (
													<FormItem>
														<FormControl>
															<RadioGroup onValueChange={field.onChange} defaultValue={field.value} className="flex space-x-2">
																<FormItem className="flex items-center space-x-1 space-y-0">
																	<FormControl>
																		<RadioGroupItem value="match_host_header" />
																	</FormControl>
																	<FormLabel className="font-normal">Match Host Header</FormLabel>
																</FormItem>
																<FormItem className="flex items-center space-x-1 space-y-0">
																	<FormControl>
																		<RadioGroupItem value="custom" />
																	</FormControl>
																	<FormLabel className="font-normal">Custom</FormLabel>
																</FormItem>
															</RadioGroup>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>

											{customSNIHostnameConfiguration === 'custom' && (
												<div className="mt-4">
													<FormField
														control={form.control}
														name="custom_sni_hostname.value"
														render={({ field }) => (
															<FormControl>
																<FormItem>
																	<FormLabel>Hostname</FormLabel>
																	<FormControl>
																		<Input {...field} placeholder="example.com" />
																	</FormControl>
																	<FormMessage />
																</FormItem>
															</FormControl>
														)}
													/>
												</div>
											)}
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
				</div>
			</form>
		</Form>
	)
})

export default TLSConfigurationForm