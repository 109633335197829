import { Link, useNavigate } from 'react-router-dom'

import { type ThemeProviderState } from 'aptranet-ui/components/theme/theme-provider.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Checkbox } from 'aptranet-ui/components/ui/checkbox.tsx'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from 'aptranet-ui/components/ui/dropdown-menu.tsx'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'aptranet-ui/components/ui/tooltip.tsx'

import { type ColumnDef } from '@tanstack/react-table'

import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon, MoreHorizontalIcon } from 'lucide-react'

import { type OriginGroupInfo } from '../../../../../../api/types/cdn.tsx'
import { getRouteList } from '../../../../../../routes/routes.tsx'

export const originGroupsTableColumns = (
	theme: ThemeProviderState,
	openDeleteOriginGroupModal: (originGroup: OriginGroupInfo) => void
): ColumnDef<OriginGroupInfo>[] => {
	return [
		{
			id: 'select',
			header: ({ table }) => (
				<Checkbox
					checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					disabled={!row.getCanSelect()}
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'id',
			header: 'ID',
		},
		{
			accessorKey: 'name',
			header: ({ column }) => {
				return (
					<Button size="sm" variant="link" className="ps-0 text-muted-foreground" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
						Name
						{column.getIsSorted() === 'asc' ? (
							<ArrowUpIcon className="ml-2 h-4 w-4" />
						) : column.getIsSorted() === 'desc' ? (
							<ArrowDownIcon className="ml-2 h-4 w-4" />
						) : (
							<ArrowUpDown className="ml-2 h-4 w-4" />
						)}
					</Button>
				)
			},
			cell: ({ row }) => (
				<Button
					size="sm"
					variant="link"
					className={theme.actualTheme === 'light' ? 'p-0 text-blue-900 hover:text-blue-500 underline' : 'p-0 text-blue-600 hover:text-blue-400 underline'}
					asChild
				>
					<Link to={getRouteList().cdn.origin_group_edit.return_dynamic_path(row.original.id)}>{row.getValue('name')}</Link>
				</Button>
			),
		},
		{
			accessorKey: 'connected_distributions_count',
			header: 'Connected Distributions',
			cell: ({ row }) =>
				row.getValue('connected_distributions_count') === 0 ? (
					'None'
				) : (
					<Button
						size="sm"
						variant="link"
						className={theme.actualTheme === 'light' ? 'p-0 text-blue-900 hover:text-blue-500 underline' : 'p-0 text-blue-600 hover:text-blue-400 underline'}
						onClick={() => console.table(row.original)}
					>
						{row.getValue('connected_distributions_count')} Connected Distributions
					</Button>
				),
		},
		{
			id: 'actions',
			enableHiding: false,
			cell: ({ row }) => {
				const originGroup = row.original
				const navigate = useNavigate()

				return (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="ghost" className="h-8 w-8 p-0">
								<span className="sr-only">Open menu</span>
								<MoreHorizontalIcon className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuLabel>Actions</DropdownMenuLabel>
							<DropdownMenuItem
								onClick={() => navigate(getRouteList().cdn.origin_group_edit.return_dynamic_path(originGroup.id))}
								className="pe-5 cursor-pointer"
							>
								Edit
							</DropdownMenuItem>

							<TooltipProvider>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<div>
											{/* We have this div here cause when the button is disabled the Tooltip does not work. */}
											<DropdownMenuItem
												onClick={() => openDeleteOriginGroupModal(originGroup)}
												className="bg-destructive text-destructive-foreground mt-1 cursor-pointer"
												disabled={originGroup.connected_distributions_count > 0}
											>
												Delete
											</DropdownMenuItem>
										</div>
									</TooltipTrigger>
									{originGroup.connected_distributions_count > 0 && <TooltipContent>There are connected Distributions to this Origin Group.</TooltipContent>}
								</Tooltip>
							</TooltipProvider>
						</DropdownMenuContent>
					</DropdownMenu>
				)
			},
		},
	]
}
