import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { z } from 'zod'

import { type Dispatch, type ReactNode, type SetStateAction, forwardRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Accordion, AccordionContent, AccordionItem } from 'aptranet-ui/components/ui/accordion.tsx'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'aptranet-ui/components/ui/form.tsx'
import { RadioGroup, RadioGroupItem } from 'aptranet-ui/components/ui/radio-group.tsx'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from 'aptranet-ui/components/ui/select.tsx'
import { Separator } from 'aptranet-ui/components/ui/separator.tsx'
import { Switch } from 'aptranet-ui/components/ui/switch.tsx'
import { Textarea } from 'aptranet-ui/components/ui/textarea.tsx'

import { updateDistributionCacheConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { type CacheConfiguration } from '../../../../api/types/cdn.tsx'

interface CacheFormProps {
	distributionID: number
	cacheConfigurationData: CacheConfiguration
	refreshCacheConfigurationData: () => void
	setResetForm: Dispatch<SetStateAction<{ run: () => void }>>
	setUnsavedChangesShown: Dispatch<SetStateAction<boolean>>
	setUpdateCacheConfigurationLoading: Dispatch<SetStateAction<boolean>>
}

const formSchema = z.object({
	edge_cache_enabled: z.boolean().optional(),
	edge_cache_controlled_by: z.enum(['cdn', 'origin']),
	edge_cache_ttl: z.string(),
	edge_cache_respect_origin: z.boolean().optional(),

	browser_cache_enabled: z.boolean().optional(),
	browser_cache_controlled_by: z.enum(['cdn', 'origin']),
	browser_cache_ttl: z.string(),
	browser_cache_respect_origin: z.boolean().optional(),

	ignore_set_cookie: z.boolean().optional(),
	ignore_query_string_enabled: z.boolean().optional(),
	ignore_query_string_configuration: z.enum(['ignore_all', 'ignore_all_except', 'ignore_only']),
	ignore_query_string_params: z
		.string()
		.trim()
		.transform((value) => value.replace(/\s+/g, '')),

	always_online: z.boolean().optional(),
})

const CacheConfigurationForm = forwardRef<HTMLFormElement, CacheFormProps>((props, ref): ReactNode => {
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			edge_cache_enabled: props.cacheConfigurationData.edge_cache.enabled,
			edge_cache_controlled_by: props.cacheConfigurationData.edge_cache.controlled_by,
			edge_cache_ttl: props.cacheConfigurationData.edge_cache.ttl.toString(),

			browser_cache_enabled: props.cacheConfigurationData.browser_cache.enabled,
			browser_cache_controlled_by: props.cacheConfigurationData.browser_cache.controlled_by,
			browser_cache_ttl: props.cacheConfigurationData.browser_cache.ttl.toString(),

			ignore_set_cookie: props.cacheConfigurationData.cache_key.ignore_set_cookie,
			ignore_query_string_enabled: props.cacheConfigurationData.cache_key.ignore_query_string.enabled,
			ignore_query_string_configuration: props.cacheConfigurationData.cache_key.ignore_query_string.configuration,
			ignore_query_string_params: props.cacheConfigurationData.cache_key.ignore_query_string.params.join(', '),
		},
	})

	useEffect(() => props.setResetForm({ run: form.reset }), [])
	useEffect(() => props.setUnsavedChangesShown(form.formState.isDirty), [form.formState.isDirty])

	const edgeCacheEnabled = form.watch('edge_cache_enabled')
	const edgeCacheControlledBy = form.watch('edge_cache_controlled_by')

	const browserCacheEnabled = form.watch('browser_cache_enabled')
	const browserCacheControlledBy = form.watch('browser_cache_controlled_by')

	const ignoreSetCookieEnabled = form.watch('ignore_set_cookie')
	const ignoreQueryStringEnabled = form.watch('ignore_query_string_enabled')
	const ignoreQueryStringConfig = form.watch('ignore_query_string_configuration')

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		props.setUpdateCacheConfigurationLoading(true)
		updateDistributionCacheConfiguration(props.distributionID, {
			edge_cache: {
				enabled: values.edge_cache_enabled || false,
				controlled_by: values.edge_cache_controlled_by,
				ttl: Number(values.edge_cache_ttl),
			},
			browser_cache: {
				enabled: values.browser_cache_enabled || false,
				controlled_by: values.browser_cache_controlled_by,
				ttl: Number(values.browser_cache_ttl),
			},
			cache_key: {
				ignore_set_cookie: values.ignore_set_cookie || false,
				ignore_query_string: {
					enabled: values.ignore_query_string_enabled || false,
					configuration: values.ignore_query_string_configuration,
					params: values.ignore_query_string_params ? values.ignore_query_string_params.split(',') : [],
				},
			},
		})
			.then(() => {
				props.setUpdateCacheConfigurationLoading(false)
				toast.success('Cache configuration updated successfully. Please allow up to 15 minutes for the changes to fully propagate to our edge.')
				props.refreshCacheConfigurationData()
			})
			.catch(() => props.setUpdateCacheConfigurationLoading(false))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" ref={ref}>
				<div className="flex flex-col items-center gap-4 w-full">
					<Card className={'w-full max-w-4xl' + (edgeCacheEnabled ? ' border-primary' : '')}>
						<CardHeader className="pb-0 flex flex-row justify-between items-center">
							<div>
								<CardTitle>Edge Caching</CardTitle>
								<CardDescription className="mt-2">Cache configuration for the Aptranet Edge.</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="edge_cache_enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1" value={edgeCacheEnabled ? 'edgeCacheSettings' : 'none'} collapsible>
								<AccordionItem value="edgeCacheSettings" className="border-b-0">
									<AccordionContent>
										<div className="grid grid-cols-2 gap-4 mt-4 mx-1">
											<FormField
												control={form.control}
												name="edge_cache_controlled_by"
												render={({ field }) => (
													<FormItem>
														<FormLabel>Edge Cache Controlled By</FormLabel>
														<FormControl>
															<Select onValueChange={field.onChange} defaultValue={field.value}>
																<SelectTrigger>
																	<SelectValue placeholder="Controlled By" />
																</SelectTrigger>
																<SelectContent>
																	<SelectItem value="cdn">CDN</SelectItem>
																	<SelectItem value="origin">Origin</SelectItem>
																</SelectContent>
															</Select>
														</FormControl>
														<FormMessage />
														<FormDescription>Choose who controls the Edge Cache TTL.</FormDescription>
													</FormItem>
												)}
											/>
											<FormField
												control={form.control}
												name="edge_cache_ttl"
												render={({ field }) => (
													<FormItem>
														<FormLabel>{edgeCacheControlledBy === 'cdn' ? 'Edge Cache TTL' : 'Default Edge Cache TTL'}</FormLabel>
														<FormControl>
															<Select onValueChange={field.onChange} defaultValue={field.value}>
																<SelectTrigger>
																	<SelectValue placeholder="Browser Cache TTL" />
																</SelectTrigger>
																<SelectContent>
																	<TTLSelectionContent />
																</SelectContent>
															</Select>
														</FormControl>
														<FormMessage />
														<FormDescription>
															Configure how long content is going to be cached on our servers. If the Origin controls the Edge Cache TTL, here you set a default
															value in case the Origin doesn't contain Cache-Control headers.
														</FormDescription>
													</FormItem>
												)}
											/>
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
					<Card className={'w-full max-w-4xl' + (browserCacheEnabled ? ' border-primary' : '')}>
						<CardHeader className="pb-0 flex flex-row justify-between items-center">
							<div>
								<CardTitle>Browser Caching</CardTitle>
								<CardDescription className="mt-2">End-user browser cache configuration.</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="browser_cache_enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1" value={browserCacheEnabled ? 'browserCacheSettings' : 'none'} collapsible>
								<AccordionItem value="browserCacheSettings" className="border-b-0">
									<AccordionContent>
										<div className={'grid gap-4 mt-4 mx-1' + (browserCacheControlledBy !== 'origin' ? ' grid-cols-2' : '')}>
											<FormField
												control={form.control}
												name="browser_cache_controlled_by"
												render={({ field }) => (
													<FormItem>
														<FormLabel>Browser Cache Controlled By</FormLabel>
														<FormControl>
															<Select onValueChange={field.onChange} defaultValue={field.value}>
																<SelectTrigger>
																	<SelectValue placeholder="Controlled By" />
																</SelectTrigger>
																<SelectContent>
																	<SelectItem value="cdn">CDN</SelectItem>
																	<SelectItem value="origin">Origin</SelectItem>
																</SelectContent>
															</Select>
														</FormControl>
														<FormMessage />
														<FormDescription>Choose who controls the Browser Cache TTL.</FormDescription>
													</FormItem>
												)}
											/>
											{browserCacheControlledBy === 'cdn' && (
												<FormField
													control={form.control}
													name="browser_cache_ttl"
													render={({ field }) => (
														<FormItem>
															<FormLabel>Browser Cache TTL</FormLabel>
															<FormControl>
																<Select onValueChange={field.onChange} defaultValue={field.value}>
																	<SelectTrigger>
																		<SelectValue placeholder="Browser Cache TTL" />
																	</SelectTrigger>
																	<SelectContent>
																		<TTLSelectionContent />
																	</SelectContent>
																</Select>
															</FormControl>
															<FormMessage />
															<FormDescription>Configure how long content is going to be cached on the end-user's browser.</FormDescription>
														</FormItem>
													)}
												/>
											)}
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
					<Card className={'w-full max-w-4xl' + (ignoreSetCookieEnabled ? ' border-primary' : '')}>
						<CardHeader className="flex flex-row justify-between items-center">
							<div>
								<CardTitle>Ignore Set-Cookie</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									Configures caching behavior for objects containing the Set-Cookie header. This setting determines whether the object and its associated
									cookies are treated as a single unit or separate entities during caching. We recommend ignoring the Set-Cookie header for optimal cache hit
									rates.
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="ignore_set_cookie"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
					</Card>
					<Card className={'w-full max-w-4xl' + (ignoreQueryStringEnabled ? ' border-primary' : '')}>
						<CardHeader className="pb-0 flex flex-row justify-between items-center">
							<div>
								<CardTitle>Ignore Query String</CardTitle>
								<CardDescription className="mt-2 w-3/4">
									This configuration option dictates whether files containing query strings (e.g. <i>https://cdn.aptranet.com/userProfile.png?user_id=8</i> and{' '}
									<i>https://cdn.aptranet.com/userProfile.png?user_id=1</i>) will be cached as a single entity or as distinct objects.
								</CardDescription>
							</div>
							<FormField
								control={form.control}
								name="ignore_query_string_enabled"
								render={({ field }) => (
									<FormControl>
										<FormItem>
											<FormControl>
												<Switch checked={field.value} onCheckedChange={field.onChange} />
											</FormControl>
										</FormItem>
									</FormControl>
								)}
							/>
						</CardHeader>
						<CardContent>
							<Accordion type="single" className="mx-1" value={ignoreQueryStringEnabled ? 'ignoreQueryStringConfiguration' : 'none'} collapsible>
								<AccordionItem value="ignoreQueryStringConfiguration" className="border-b-0">
									<AccordionContent>
										<div className="mt-4 mx-1">
											<FormField
												control={form.control}
												name="ignore_query_string_configuration"
												render={({ field }) => (
													<FormItem>
														<FormControl>
															<RadioGroup onValueChange={field.onChange} defaultValue={field.value} className="flex space-x-2">
																<FormItem className="flex items-center space-x-1 space-y-0">
																	<FormControl>
																		<RadioGroupItem value="ignore_all" />
																	</FormControl>
																	<FormLabel className="font-normal">Ignore All</FormLabel>
																</FormItem>
																<FormItem className="flex items-center space-x-1 space-y-0">
																	<FormControl>
																		<RadioGroupItem value="ignore_all_except" />
																	</FormControl>
																	<FormLabel className="font-normal">Ignore All Except</FormLabel>
																</FormItem>
																<FormItem className="flex items-center space-x-1 space-y-0">
																	<FormControl>
																		<RadioGroupItem value="ignore_only" />
																	</FormControl>
																	<FormLabel className="font-normal">Ignore Only</FormLabel>
																</FormItem>
															</RadioGroup>
														</FormControl>
														<FormMessage />
														<FormDescription>
															Configure how long content is going to be cached on our servers. If the Origin controls the Edge Cache TTL, here you set a default
															value in case the Origin doesn't contain Cache-Control headers.
														</FormDescription>
													</FormItem>
												)}
											/>
											{ignoreQueryStringConfig !== 'ignore_all' && (
												<div className="mt-3">
													<FormField
														control={form.control}
														name="ignore_query_string_params"
														render={({ field }) => (
															<FormItem>
																<FormLabel>Query Params</FormLabel>
																<FormControl>
																	<Textarea placeholder="param1, param2, param3, param4" {...field} />
																</FormControl>
																<FormMessage />
																<FormDescription>Please note: Parameters should be separated by comma (,).</FormDescription>
															</FormItem>
														)}
													/>
												</div>
											)}
										</div>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
				</div>
			</form>
		</Form>
	)
})

const TTLSelectionContent = () => (
	<>
		<SelectGroup>
			<SelectLabel>Years</SelectLabel>
			<SelectItem value="31536000">1 Year</SelectItem>
			<Separator className="my-1" />
		</SelectGroup>
		<SelectGroup>
			<SelectLabel>Months</SelectLabel>
			<SelectItem value="15768000">6 Months</SelectItem>
			<SelectItem value="7884000">3 Months</SelectItem>
			<SelectItem value="2628000">1 Month</SelectItem>
			<Separator className="my-1" />
		</SelectGroup>
		<SelectGroup>
			<SelectLabel>Weeks</SelectLabel>
			<SelectItem value="1814400">3 Weeks</SelectItem>
			<SelectItem value="604800">1 Week</SelectItem>
			<Separator className="my-1" />
		</SelectGroup>
		<SelectGroup>
			<SelectLabel>Days</SelectLabel>
			<SelectItem value="259200">3 Days</SelectItem>
			<SelectItem value="86400">1 Day</SelectItem>
			<Separator className="my-1" />
		</SelectGroup>
		<SelectGroup>
			<SelectLabel>Hours</SelectLabel>
			<SelectItem value="43200">12 Hours</SelectItem>
			<SelectItem value="21600">6 Hours</SelectItem>
			<SelectItem value="10800">3 Hours</SelectItem>
			<SelectItem value="3600">1 Hour</SelectItem>
			<Separator className="my-1" />
		</SelectGroup>
		<SelectGroup>
			<SelectLabel>Minutes</SelectLabel>
			<SelectItem value="1800">30 Minutes</SelectItem>
			<SelectItem value="180">3 Minutes</SelectItem>
			<Separator className="my-1" />
		</SelectGroup>
		<SelectGroup>
			<SelectLabel>Seconds</SelectLabel>
			<SelectItem value="30">30 Seconds</SelectItem>
			<Separator className="my-1" />
		</SelectGroup>
	</>
)

export default CacheConfigurationForm
