import type { OriginGroupInfo, OriginGroupInfoMinimal } from '../../../../../../api/types/cdn.tsx'
import { Dispatch, SetStateAction, useState } from 'react'
import { useMediaQuery } from 'aptranet-ui/hooks/use-media-query.tsx'
import { Popover, PopoverContent, PopoverTrigger } from 'aptranet-ui/components/ui/popover.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { ChevronDownIcon, ServerIcon } from 'lucide-react'
import { Drawer, DrawerContent, DrawerTrigger } from 'aptranet-ui/components/ui/drawer.tsx'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from 'aptranet-ui/components/ui/command.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'

export const OriginGroupSelector = (props: {
	originGroups: OriginGroupInfo[] | null
	setOriginGroups: Dispatch<SetStateAction<OriginGroupInfo[] | null>>
	selectedOriginGroup: OriginGroupInfo | OriginGroupInfoMinimal | null
	setSelectedOriginGroup: Dispatch<SetStateAction<OriginGroupInfo | OriginGroupInfoMinimal | null>>
	onValueChange: (...event: any[]) => void
}) => {
	const [open, setOpen] = useState(false)
	const isDesktop = useMediaQuery('(min-width: 768px)')

	if (isDesktop) {
		return (
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button variant="outline" className="w-full justify-start">
						<ServerIcon className="w-4 me-1" /> {props.selectedOriginGroup ? props.selectedOriginGroup.name : 'Select Origin Group'}{' '}
						<ChevronDownIcon className="w-4 ms-1.5" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[800px] p-0" align="start">
					<OriginGroupList
						originGroups={props.originGroups}
						setOriginGroups={props.setOriginGroups}
						setSelectedOriginGroup={props.setSelectedOriginGroup}
						setOpen={setOpen}
						onValueChange={props.onValueChange}
					/>
				</PopoverContent>
			</Popover>
		)
	}

	return (
		<Drawer open={open} onOpenChange={setOpen}>
			<DrawerTrigger asChild>
				<Button variant="outline" className="w-full justify-start">
					<ServerIcon className="w-4 me-1" /> {props.selectedOriginGroup ? props.selectedOriginGroup.name : 'Select Origin Group'}
					<ChevronDownIcon className="w-4 ms-1.5" />
				</Button>
			</DrawerTrigger>
			<DrawerContent>
				<div className="mt-4 border-t">
					<OriginGroupList
						originGroups={props.originGroups}
						setOriginGroups={props.setOriginGroups}
						setSelectedOriginGroup={props.setSelectedOriginGroup}
						setOpen={setOpen}
						onValueChange={props.onValueChange}
					/>
				</div>
			</DrawerContent>
		</Drawer>
	)
}

export const OriginGroupList = (props: {
	originGroups: OriginGroupInfo[] | null
	setOriginGroups: Dispatch<SetStateAction<OriginGroupInfo[] | null>>
	setSelectedOriginGroup: Dispatch<SetStateAction<OriginGroupInfo | OriginGroupInfoMinimal | null>>
	setOpen: Dispatch<SetStateAction<boolean>>
	onValueChange: (...event: any[]) => void
}) => (
	<Command>
		<CommandInput placeholder="Filter Origin Groups..." />
		{props.originGroups ? (
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				<CommandGroup>
					{props.originGroups.map((originGroup: OriginGroupInfo) => (
						<CommandItem
							key={originGroup.id}
							value={originGroup.name}
							onSelect={(e) => {
								const originGroup = props.originGroups!.find((originGroup) => originGroup.name === e)!
								props.setSelectedOriginGroup(originGroup)
								props.onValueChange(originGroup.id)
								props.setOpen(false)
							}}
						>
							{originGroup.name}
						</CommandItem>
					))}
				</CommandGroup>
			</CommandList>
		) : (
			<Skeleton className="w-full h-24" />
		)}
	</Command>
)