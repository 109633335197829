import AptranetAreaChart from 'aptranet-ui/aptranet-charts/area-chart.tsx'
import { type ChartConfig } from 'aptranet-ui/components/ui/chart'
import { formatBytes } from 'aptranet-ui/lib/utils.ts'
import { DistributionAnalyticsDatapoint } from '../../../../../../api/types/cdn.tsx'

const chartConfig = {
	traffic: {
		label: 'Traffic',
		color: 'hsl(var(--chart-1))',
	},
} satisfies ChartConfig

const TrafficChart = (props: { data: DistributionAnalyticsDatapoint[], chartOnly?: boolean }) => (
	<AptranetAreaChart
		title="Traffic"
		description="Traffic in the last 30 days."
		config={chartConfig}
		data={{ traffic: props.data.map(datapoint => ({ timestamp: datapoint.datetime, value: datapoint.traffic.total_bytes })) }}
		formatter={(value) => formatBytes(value)}
		totalCalculator={(values) => values.reduce((acc, curr) => acc + curr, 0)}
		chartOnly={props.chartOnly || false}
	/>
)

export default TrafficChart
