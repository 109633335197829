import { useLocation, useNavigate } from 'react-router-dom'
import { SignUp, useAuth } from '@clerk/clerk-react'

import Head from '../../layout/head.tsx'

const Login = () => {
	const auth = useAuth()
	const navigate = useNavigate()
	const { state } = useLocation()

	if (auth.isSignedIn) {
		navigate(state ? state.route_to ?? '/' : '/')
	}

	return (
		<>
			<Head title="Sign Up" />
			<div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
				<div className="flex items-center justify-center py-12">
					<div className="mx-auto grid w-[350px] gap-6">
						<SignUp routing="path" path="/auth/register" />
					</div>
				</div>
				<div className="hidden bg-muted lg:block">
					<img
						src="https://assets.aptranetedge.com/management-console/login/banner.svg"
						alt="Register Image"
						className="h-full w-full object-cover"
					/>
				</div>
			</div>
		</>
	)
}

export default Login